import { Box, HTMLContainer, Mat } from "tldraw";

/**
 * Renders a background box around the given bounds.
 *
 *
 * @param param0 Options.
 *
 * @returns The component to render.
 */
export function DataframeBackground({
  bounds,
  className,
  enableBackgroundColor,
  enableBorder,
}: {
  bounds: Box;
  className?: string;
  enableBackgroundColor: boolean;
  enableBorder: boolean;
}) {
  const cssString = Mat.toCssString(Mat.Compose(Mat.Translate(bounds?.minX || 0, bounds?.minY || 0)));
  return (
    <HTMLContainer
      className={className}
      style={{
        border: enableBorder ? "1px solid black" : "transparent",
        transform: cssString,
        backgroundColor: enableBackgroundColor ? "#fff" : "transparent",
        width: bounds?.width,
        height: bounds?.height,
      }}
    ></HTMLContainer>
  );
}
