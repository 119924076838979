import { IDeprecatedShape, getDeprecatedShapeDefaultProps } from "@bigpi/tl-schema";
import { HTMLContainer, TLShapeUtilCanBindOpts } from "tldraw";
import { useTranslation } from "react-i18next";

import { BoxBaseUtil } from "BoardComponents/BaseShapes/BoxBaseUtil";

// *********************************************
// Shape Util
// *********************************************/
/**
 * Generator for Deprecated shapes.
 */
export class DeprecatedUtil extends BoxBaseUtil<IDeprecatedShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "deprecated";

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override canBind = (options: TLShapeUtilCanBindOpts<IDeprecatedShape>) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: IDeprecatedShape) => false;

  /**
   * @inheritdoc
   */
  override canResize = (shape: IDeprecatedShape) => false;

  /**
   * @inheritdoc
   */
  override canScroll = (shape: IDeprecatedShape) => false;

  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: IDeprecatedShape) => false;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): IDeprecatedShape["props"] {
    return getDeprecatedShapeDefaultProps();
  }

  override indicator(shape: IDeprecatedShape) {
    return <rect width={shape.props.w} height={shape.props.h} stroke="" />;
  }

  // This is the component that will be rendered for the shape.
  override component(shape: IDeprecatedShape) {
    const { t } = useTranslation();

    return (
      <HTMLContainer
        id={shape.id}
        style={{
          background: "rgba(255, 255, 255, 0.5)",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          display: "flex",
          pointerEvents: "all",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {t("Components.WorkspaceBoard.ShapeDeprecated")}
      </HTMLContainer>
    );
  }
}
