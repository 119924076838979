import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useValue } from "tldraw";
import { useTranslation } from "react-i18next";

import { IFileWithId, IFileStatusDetail, FilesList, FileStatus } from "./FilesList";
import { useEffect } from "react";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
interface IFilesUploadDialogProps {
  children?: React.ReactNode;
  files: Array<IFileWithId>;
  filesStatusMap: IFileStatusDetail;
  onAbort: (id: string) => void;
  onClose: () => void;
  onRemove: (id: string) => void;
  onRetry: (file: IFileWithId) => void;
  onUpload: () => void;
  progressingFiles: Record<string, number>;
  title: string;
}

// *********************************************
// Component
// *********************************************/
export function FilesUploadDialog(props: IFilesUploadDialogProps) {
  const { children, files, filesStatusMap, onAbort, onClose, onRemove, onRetry, onUpload, progressingFiles, title } = props;
  const { t } = useTranslation();

  const filesStatus = useValue(
    "filesStatus",
    () => {
      return new Set(Object.values(filesStatusMap).map((file) => file.status));
    },
    [filesStatusMap],
  );

  const isRunning = useValue(
    "isRunning",
    () => {
      return filesStatus.has(FileStatus.Uploading);
    },
    [filesStatus],
  );

  const isCompleted = useValue(
    "isCompleted",
    () => {
      return filesStatus.size > 0 && !filesStatus.has(FileStatus.Failed) && !isRunning;
    },
    [filesStatus, isRunning],
  );

  useEffect(() => {
    if (isCompleted && files.length === 1 && filesStatus.has(FileStatus.Success)) {
      onClose();
    }
  }, [isCompleted, onClose, files.length, filesStatus]);

  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>

      <DialogContent dividers={true}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <FilesList
            files={files}
            onAbort={onAbort}
            onRemove={onRemove}
            onRetry={onRetry}
            progressingFiles={progressingFiles}
            filesStatusMap={filesStatusMap}
          />
        </Box>
        <Box>{children}</Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Box sx={{ ml: "15px" }}>
          {(isCompleted || isRunning) && (
            <Typography variant="subtitle2" sx={{ color: isCompleted ? "success.main" : "primary.main" }}>
              {isCompleted && t("Components.FilesUploadDialog.UploadStatus.Complete")}
              {isRunning && t("Components.FilesUploadDialog.UploadStatus.InProgress")}
            </Typography>
          )}
        </Box>
        <Box>
          <Button
            color="primary"
            disabled={filesStatus.has(FileStatus.Uploading) || filesStatus.has(FileStatus.Success)}
            onClick={onUpload}
          >
            {t("Components.FilesUploadDialog.Labels.Upload")}
          </Button>
          <Button color="secondary" disabled={filesStatus.has(FileStatus.Uploading)} onClick={onClose}>
            {filesStatus.has(FileStatus.Success) ? t("Global.Action.Close") : t("Global.Action.Cancel")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
