import { FacetDisplaySortType } from "@bigpi/cookbook";
import { RecordProps } from "@tldraw/tlschema";
import { T } from "@tldraw/validate";

import { ITopicDiscussionInNewsArticleAnalysisShape } from "./ITopicDiscussionInNewsArticleAnalysisShape.js";
import { TopicDiscussionInNewsArticleAnalysisFieldsEnum } from "./ITopicDiscussionInNewsArticleAnalysisFacets.js";
import { dataGridPreferencesValidatable } from "../DataGrid/index.js";
import { analysisPreferencesFacetSortValidatable } from "../../Common.js";

// *********************************************
// Private constants
// *********************************************/
/**
 * The default width of the shape.
 */
const DEFAULT_WIDTH = 500;

/**
 * The default height of the shape.
 */
const DEFAULT_HEIGHT = 500;

// *********************************************
// Public constants
// *********************************************/
/**
 * Gets the default props for the shape.
 *
 * @returns The default props.
 */
export const getTopicDiscussionInNewsArticleAnalysisShapeDefaultProps =
  (): ITopicDiscussionInNewsArticleAnalysisShape["props"] => {
    return {
      boundsFacetValues: {},
      enableBackground: true,
      selectedFacetValues: {},
      h: DEFAULT_HEIGHT,
      preferences: {},
      selection: {},
      w: DEFAULT_WIDTH,
    };
  };

/**
 * The shape props schema definition.
 */
export const topicDiscussionInNewsArticleAnalysisShapeProps: RecordProps<ITopicDiscussionInNewsArticleAnalysisShape> = {
  boundsFacetValues: T.object({
    date: T.object({
      from: T.string.optional(),
      to: T.string.optional(),
    }).optional(),
    dateShortcut: T.string.optional(),
    topic: T.arrayOf(T.string).optional(),
    theme: T.arrayOf(T.string).optional(),
  }),
  enableBackground: T.boolean.optional(),
  selectedFacetValues: T.object({
    date: T.object({
      from: T.string.optional(),
      to: T.string.optional(),
    }).optional(),
    dateShortcut: T.string.optional(),
    topic: T.arrayOf(T.string).optional(),
    theme: T.arrayOf(T.string).optional(),
  }),
  w: T.nonZeroInteger.optional(),
  h: T.nonZeroInteger.optional(),
  preferences: T.object({
    analysis: T.object({
      facetSort: analysisPreferencesFacetSortValidatable,
      showDataToDisplayInToolbar: T.boolean.optional(),
      showTopics: T.boolean.optional(),
      startColor: T.string.optional(),
    }).optional(),
    dataGrid: dataGridPreferencesValidatable,
  }),
  selection: T.object({
    expandedGroups: T.arrayOf(T.string).optional(),
    selectedTimeScale: T.string.optional(),
    axisSelection: T.arrayOf(
      T.object({
        selectedXAxis: T.arrayOf(T.string).optional(),
        selectedYGroup: T.string.optional(),
        selectedYItem: T.string.optional(),
      }),
    ).optional(),
  }).optional(),
  selectedBoundsFields: T.arrayOf(
    T.setEnum(
      new Set([
        TopicDiscussionInNewsArticleAnalysisFieldsEnum.Date,
        TopicDiscussionInNewsArticleAnalysisFieldsEnum.DateShortcut,
        TopicDiscussionInNewsArticleAnalysisFieldsEnum.Theme,
        TopicDiscussionInNewsArticleAnalysisFieldsEnum.Topic,
      ]),
    ),
  ).optional(),
  toolbar: T.object({
    availableFields: T.arrayOf(
      T.setEnum(
        new Set([
          TopicDiscussionInNewsArticleAnalysisFieldsEnum.Date,
          TopicDiscussionInNewsArticleAnalysisFieldsEnum.DateShortcut,
          TopicDiscussionInNewsArticleAnalysisFieldsEnum.Theme,
          TopicDiscussionInNewsArticleAnalysisFieldsEnum.Topic,
        ]),
      ),
    ).optional(),
  }).optional(),
};
