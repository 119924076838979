/**
 * Cloned from https://github.com/tldraw/tldraw/blob/main/packages/editor/src/lib/components/default-components/DefaultShapeIndicators.tsx
 * and added `interact.idle` and `interact.pointing_shape` in `isInSelectState` logic because we want to add
 * hovered shape indicator for shapes in interact mode.
 *
 * Replaces older HoveredShapeIndicator.tsx implementation discussed here:
 * https://discord.com/channels/859816885297741824/1138822084118724678/1139860556178796584
 */
import { TLShapeId, useEditor, useEditorComponents, useValue } from "tldraw";
import { memo, useRef } from "react";

export const BigPiShapeIndicators = memo(function BigPiShapeIndicators() {
  const editor = useEditor();

  const rPreviousSelectedShapeIds = useRef<Set<TLShapeId>>(new Set());

  const idsToDisplay = useValue(
    "should display selected ids",
    () => {
      const prev = rPreviousSelectedShapeIds.current;
      const next = new Set<TLShapeId>();

      if (
        // We only show indicators when in the following states...
        editor.isInAny(
          "select.idle",
          "select.brushing",
          "select.scribble_brushing",
          "select.editing_shape",
          "select.pointing_shape",
          "select.pointing_selection",
          "select.pointing_handle",
          // Added by BigPi
          "interact.idle",
          // Added by BigPi
          "interact.pointing_shape",
        ) &&
        // ...but we hide indicators when we've just changed a style (so that the user can see the change)
        !editor.getInstanceState().isChangingStyle
      ) {
        // We always want to show indicators for the selected shapes, if any
        const selected = editor.getSelectedShapeIds();
        for (const id of selected) {
          next.add(id);
        }

        // Modified by BigPi
        // If we're idle or editing a shape, we want to also show an indicator for the hovered shape, if any
        if (editor.isInAny("select.idle", "select.editing_shape", "interact.idle", "interact.pointing_shape")) {
          const instanceState = editor.getInstanceState();
          if (instanceState.isHoveringCanvas && !instanceState.isCoarsePointer) {
            const hovered = editor.getHoveredShapeId();
            if (hovered) {
              next.add(hovered);
            }
          }
        }
      }

      // Ok, has anything changed?

      // If the number of items in the set is different, then the selection has changed. This catches most changes.
      if (prev.size !== next.size) {
        rPreviousSelectedShapeIds.current = next;
        return next;
      }

      // If any of the new ids are not in the previous set, then the selection has changed
      for (const id of next) {
        if (!prev.has(id)) {
          rPreviousSelectedShapeIds.current = next;
          return next;
        }
      }

      // If nothing has changed, then return the previous value
      return prev;
    },
    [editor],
  );

  // Show indicators only for the shapes that are currently being rendered (ie that are on screen)
  const renderingShapes = useValue("rendering shapes", () => editor.getRenderingShapes(), [editor]);

  const { ShapeIndicator } = useEditorComponents();
  if (!ShapeIndicator) {
    return null;
  }

  return renderingShapes.map(({ id }) => <ShapeIndicator key={id + "_indicator"} shapeId={id} hidden={!idsToDisplay.has(id)} />);
});
