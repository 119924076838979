import { useApolloClient } from "@apollo/client";
import { IPlugIn, PlugInManager } from "@bigpi/cookbook";
import type { Editor as TldrawEditor } from "tldraw";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { OnChatActionEventArgs } from "../ChatActionExecutor";
import { useChatActionExecutor } from "../useChatActionExecutor";
import { createChatActionPlugIns } from "./PlugIns/WorkspaceBoardChatActionPlugIns";
import { IWorkspaceBoardChatActionPlugInInputs } from "./PlugIns/IWorkspaceBoardChatActionPlugInInputs";

/**
 * Props for the WorkspaceBoardChatActionManager component.
 */
export interface IWorkspaceBoardChatActionManagerProps {
  /**
   * The Tldraw app instance to add content to
   */
  app?: TldrawEditor;

  /**
   * The active workspace board ID.
   */
  workspaceBoardId: string;
}

/**
 * Handles chatAction responses related to workspace boards.
 *
 * @param props The component props.
 *
 * @returns Component that may render children for certain chatActions.
 */
export function WorkspaceBoardChatActionManager(props: IWorkspaceBoardChatActionManagerProps) {
  const { app } = props;

  const apolloClient = useApolloClient();
  const chatActionExecutor = useChatActionExecutor();
  const { t } = useTranslation();
  const [plugInChildren, setPlugInChildren] = useState<JSX.Element | undefined>(undefined);

  // Create and initialize plug-in manager to handle WorkspaceBoard supported chatActions
  const actionPlugInManager = new PlugInManager<
    IWorkspaceBoardChatActionPlugInInputs,
    undefined | JSX.Element,
    IPlugIn<IWorkspaceBoardChatActionPlugInInputs, undefined | JSX.Element>
  >();
  actionPlugInManager.registerPlugIns(createChatActionPlugIns());

  const onChatAction = useCallback(
    async (event: OnChatActionEventArgs) => {
      const { action, data } = event;

      // Execute the plug-in that handles the chatAction response
      const result = await actionPlugInManager.executeIfRegistered(action, {
        action,
        apolloClient,
        app,
        chatEditor: chatActionExecutor.chatEditor,
        data,
        t,
      });

      setPlugInChildren(result);
    },
    [app, apolloClient],
  );

  useEffect(() => {
    chatActionExecutor.on("chatAction", onChatAction);

    return () => {
      chatActionExecutor.off("chatAction", onChatAction);
    };
  }, [chatActionExecutor, onChatAction]);

  return <>{plugInChildren}</>;
}
