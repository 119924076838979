import {
  IPagePreferences,
  ItemGridSize,
  ItemViewType,
  NonEmptyArray,
  SortKeys,
  TAGS_FILTER_UNTAGGED_OPTION_KEY,
  TagsFilterSelection,
} from "@bigpi/cookbook";
import cloneDeep from "lodash.clonedeep";

import {
  DocumentsPageUserPreferencesQuery,
  LibraryPageUserPreferencesQuery,
  WorkspaceBoardTagsQueryResult,
  WorkspacePageUserPreferencesQuery,
  WorkspacesPageUserPreferencesQuery,
  WorkspaceTagsQueryResult,
} from "GraphQL/Generated/Apollo";
import { IWorkspacePageUserPreferences } from "Pages/Workspace/WorkspacePage";

type PersistedUserPreferences =
  | DocumentsPageUserPreferencesQuery
  | LibraryPageUserPreferencesQuery
  | WorkspacePageUserPreferencesQuery
  | WorkspacesPageUserPreferencesQuery;

/**
 * Get the grid size from user preferences based on the priority order, falling back to the default value if not found.
 * Priority order:
 *  1. Persisted user preferences
 *  2. Page preferences
 *  3. Default preferences
 *
 * @param persistedPreferences The persisted user preferences
 * @param defaultPagePreferences The default page preferences from bistro preferences
 * @param defaultValue The default value to use if the grid size is not found or invalid
 *
 * @returns The grid size
 */
export const getGridSizeFromUserPreferences = (
  persistedPreferences: PersistedUserPreferences | undefined,
  defaultPagePreferences: IPagePreferences,
  defaultValue: ItemGridSize,
): ItemGridSize => {
  const isValidGridSize = (gridSize: any): boolean => {
    const allowedGridSizes = Object.values(ItemGridSize);
    if (!gridSize || !allowedGridSizes.includes(gridSize)) {
      return false;
    }

    return true;
  };

  if (isValidGridSize(persistedPreferences?.userPreference?.data?.gridSize)) {
    return persistedPreferences?.userPreference?.data?.gridSize as ItemGridSize;
  } else if (isValidGridSize(defaultPagePreferences.gridSize)) {
    return defaultPagePreferences.gridSize as ItemGridSize;
  } else {
    return defaultValue;
  }
};

/**
 * Get the view type from user preferences based on the priority order, falling back to the default value if not found.
 * Priority order:
 *  1. Persisted user preferences
 *  2. Page preferences
 *  3. Default preferences
 *
 * @param persistedPreferences The persisted user preferences
 * @param defaultPagePreferences The default page preferences from bistro preferences
 * @param defaultValue The default value to use if the view type is not found or invalid
 *
 * @returns The view type
 */
export const getViewTypeFromUserPreferences = (
  persistedPreferences: PersistedUserPreferences | undefined,
  defaultPagePreferences: IPagePreferences,
  defaultValue: ItemViewType,
): ItemViewType => {
  const isValidViewType = (viewType: any): boolean => {
    const allowedViewTypes = Object.values(ItemViewType);
    if (!viewType || !allowedViewTypes.includes(viewType)) {
      return false;
    }

    return true;
  };

  if (isValidViewType(persistedPreferences?.userPreference?.data?.viewType)) {
    return persistedPreferences?.userPreference?.data?.viewType as ItemViewType;
  } else if (isValidViewType(defaultPagePreferences.viewType)) {
    return defaultPagePreferences.viewType as ItemViewType;
  } else {
    return defaultValue;
  }
};

/**
 * Get the page size from user preferences based on the priority order, falling back to the first available sort option if not found.
 * Priority order:
 *  1. Persisted user preferences
 *  2. Page preferences
 *  3. Default preferences
 *
 * @param persistedPreferences The persisted user preferences
 * @param defaultPagePreferences The default page preferences from bistro preferences
 * @param availablePageSizeOptions The available page size options
 * @param defaultValue The default value to use if the page size is not found or invalid
 *
 * @returns The page size
 */
export const getPageSizeFromUserPreferences = (
  persistedPreferences: PersistedUserPreferences | undefined,
  defaultPagePreferences: IPagePreferences,
  availablePageSizeOptions: NonEmptyArray<number>,
  defaultValue: number,
): number => {
  const isValidPageSize = (pageSize: any): boolean => {
    if (!pageSize || !availablePageSizeOptions.includes(pageSize)) {
      return false;
    }

    return true;
  };

  if (isValidPageSize(persistedPreferences?.userPreference?.data?.pageSize)) {
    return persistedPreferences?.userPreference?.data?.pageSize as number;
  } else if (isValidPageSize(defaultPagePreferences.pageSize)) {
    return defaultPagePreferences.pageSize as number;
  } else {
    return defaultValue;
  }
};

/**
 * Get the sort option from user preferences based on the priority order, falling back to the first available sort option if not found.
 * Priority order:
 *  1. Persisted user preferences
 *  2. Page preferences
 *  3. Default preferences
 *
 * @param persistedPreferences The persisted user preferences
 * @param defaultPagePreferences The default page preferences from bistro preferences
 * @param availableSortOptions The available sort options
 *
 * @returns The sort option
 */
export const getSortOptionFromUserPreferences = (
  persistedPreferences: PersistedUserPreferences | undefined,
  defaultPagePreferences: IPagePreferences,
  availableSortOptions: NonEmptyArray<SortKeys>,
): SortKeys => {
  const isValidSortOption = (sortOption: any): boolean => {
    if (!sortOption || !availableSortOptions.includes(sortOption)) {
      return false;
    }

    return true;
  };

  if (isValidSortOption(persistedPreferences?.userPreference?.data?.sortOption)) {
    return persistedPreferences?.userPreference?.data?.sortOption as SortKeys;
  } else if (isValidSortOption(defaultPagePreferences.sortOption)) {
    return defaultPagePreferences.sortOption as SortKeys;
  } else {
    return availableSortOptions[0];
  }
};

/**
 * Get the value for the tags filter from the page preferences.
 *
 * @param pagePreferences The default page preferences.
 * @param allTags The list of all tags.
 *
 * @returns The value for the tags filter.
 */
const getTagsFilterValueFromPagePreferences = (
  pagePreferences: IPagePreferences,
  allTags?: Array<string>,
): Array<string> | null => {
  let selectedTags = pagePreferences.selectedTags;

  // If the selected tags are not an array or a valid selection, default to All
  const allowedTagsFilterSelections = Object.values(TagsFilterSelection);
  if (selectedTags && !Array.isArray(selectedTags) && !allowedTagsFilterSelections.includes(selectedTags)) {
    selectedTags = TagsFilterSelection.All;
  }

  if (selectedTags === TagsFilterSelection.All) {
    return (allTags || []).concat(TAGS_FILTER_UNTAGGED_OPTION_KEY);
  } else if (selectedTags === TagsFilterSelection.None) {
    return [];
  } else if (selectedTags === undefined) {
    return null;
  } else {
    // Filter out any tags that are not in the workspace tags list
    return selectedTags.filter((tag) => (allTags || []).concat(TAGS_FILTER_UNTAGGED_OPTION_KEY).includes(tag));
  }
};

/**
 * Get the selected workspace tags from user preferences based on the priority order.
 * Priority order:
 *  1. Persisted user preferences
 *  2. Page preferences
 *  3. Default preferences
 *
 * @param persistedPreferences The persisted user preferences
 * @param defaultPagePreferences The default page preferences from bistro preferences
 * @param workspaceTagsData The data for the workspace tags
 *
 * @returns The selected workspace tags
 */
export const getSelectedWorkspaceTagsFromUserPreferences = (
  persistedPreferences: PersistedUserPreferences | undefined,
  defaultPagePreferences: IPagePreferences,
  workspaceTagsData: WorkspaceTagsQueryResult["data"],
) => {
  const tagsFilterFromPersistedPreferences = persistedPreferences?.userPreference?.data?.selectedTags;
  if (Array.isArray(tagsFilterFromPersistedPreferences)) {
    return tagsFilterFromPersistedPreferences.filter((tag) =>
      (workspaceTagsData?.workspaceTags || []).concat(TAGS_FILTER_UNTAGGED_OPTION_KEY).includes(tag),
    );
  }

  const tagsFilterFromPagePreferences = getTagsFilterValueFromPagePreferences(
    defaultPagePreferences,
    workspaceTagsData?.workspaceTags,
  );
  if (Array.isArray(tagsFilterFromPagePreferences)) {
    return tagsFilterFromPagePreferences;
  }

  return (workspaceTagsData?.workspaceTags || []).concat(TAGS_FILTER_UNTAGGED_OPTION_KEY);
};

/**
 * Get the selected workspace board tags from user preferences based on the priority order.
 * Priority order:
 *  1. Persisted user preferences
 *  2. Page preferences
 *  3. Default preferences
 *
 * @param persistedPreferences The persisted user preferences
 * @param defaultPagePreferences The default page preferences from bistro preferences
 * @param workspaceTagsData The data for the workspace board tags
 *
 * @returns The selected workspace board tags
 */
export const getSelectedWorkspaceBoardTagsFromUserPreferences = (
  persistedPreferences: PersistedUserPreferences | undefined,
  defaultPagePreferences: IPagePreferences,
  workspaceBoardTagsData: WorkspaceBoardTagsQueryResult["data"],
  preferences: IWorkspacePageUserPreferences,
  workspaceId: string,
) => {
  const tagsFilterFromPersistedPreferences = persistedPreferences?.userPreference?.data?.selectedTags;
  if (typeof tagsFilterFromPersistedPreferences === "object" && Array.isArray(tagsFilterFromPersistedPreferences[workspaceId])) {
    const tagsFilterValue = cloneDeep(persistedPreferences?.userPreference?.data?.selectedTags);
    tagsFilterValue[workspaceId] = tagsFilterFromPersistedPreferences[workspaceId].filter((tag) =>
      (workspaceBoardTagsData?.workspaceBoardTags || []).concat(TAGS_FILTER_UNTAGGED_OPTION_KEY).includes(tag),
    );

    return tagsFilterValue;
  }

  const tagsFilterFromPagePreferences = getTagsFilterValueFromPagePreferences(
    defaultPagePreferences,
    workspaceBoardTagsData?.workspaceBoardTags,
  );
  if (Array.isArray(tagsFilterFromPagePreferences)) {
    return {
      ...preferences.selectedTags,
      [workspaceId]: tagsFilterFromPagePreferences,
    };
  }

  return {
    ...preferences.selectedTags,
    [workspaceId]: (workspaceBoardTagsData?.workspaceBoardTags || []).concat(TAGS_FILTER_UNTAGGED_OPTION_KEY),
  };
};
