import {
  AnalysisFieldTypeEnum,
  IAnalysisFieldConfig,
  IDateRange,
  IDateRangeLimits,
  ISelectInputFieldOption,
} from "@bigpi/cookbook";
import { Box, Checkbox } from "@mui/material";
import { DataGridPremium, GridRenderCellParams, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { useValue } from "tldraw";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { IAnalysisFacets } from "BoardComponents/Types";
import { AnalysisToolbarFormElements } from "Components/AnalysisToolbar/AnalysisToolbarFormElements";
import { DataDateRangePickerProps } from "Components/Charting/Elements/DataDateRangePicker";
import { getEarliestDate, getOldestDate } from "Utils/DateUtils";

/**********************************
 * Types/Interfaces
 **********************************/
export interface AnalysisPreferencesBoundsProps<TFacets> {
  // Date range shortcuts, used for date field
  dateRangeShortcuts?: DataDateRangePickerProps["shortcutItems"];
  // Distinct values to show on the select list
  distinctValues: Record<keyof TFacets, IDateRangeLimits | Array<ISelectInputFieldOption>>;
  // Bounds facet values
  selectedFacetValues: TFacets;
  // Fields configuration, helps to identify the type of field
  fieldsConfig: Array<IAnalysisFieldConfig>;
  onRowSelectionModelChange: (rowSelectionModel: Array<keyof TFacets>) => void;
  onShowInToolbarFieldsChange: (showInToolbarFields: Array<keyof TFacets>) => void;
  // On field change handler
  onFieldChange: (
    field: string,
    value: Array<string> | string | { dateRange: IDateRange; shortcut?: string },
    inputValues?: Array<ISelectInputFieldOption> | IDateRangeLimits,
  ) => void;
  rowSelectionModel: Array<keyof TFacets>;
  showInToolbarFields: Array<keyof TFacets>;
  // Toolbar facet values, used to populate the bounds facet values
  toolbarFieldFacets: TFacets;
}

/**********************************
 * Component
 **********************************/
export function AnalysisPreferencesBounds<TFacets extends IAnalysisFacets>(props: AnalysisPreferencesBoundsProps<TFacets>) {
  const {
    dateRangeShortcuts,
    distinctValues,
    selectedFacetValues,
    fieldsConfig,
    onFieldChange,
    onRowSelectionModelChange,
    onShowInToolbarFieldsChange,
    rowSelectionModel,
    showInToolbarFields,
    toolbarFieldFacets,
  } = props;

  // Refs
  const rowSelectionModelRef = useRef(rowSelectionModel);
  // Used these initial facets when toggle bounds field
  const selectedFacetValuesRef = useRef(selectedFacetValues);

  const { t } = useTranslation();

  const fieldsConfigMap = useValue(
    "fieldConfigMap",
    () => {
      const map = new Map<string, IAnalysisFieldConfig>();
      fieldsConfig.forEach((fieldConfig) => {
        map.set(fieldConfig.field, fieldConfig);
      });
      return map;
    },
    [fieldsConfig],
  );

  useEffect(() => {
    // Copy selected field facets to the boundsFacets
    if (rowSelectionModelRef.current.length !== rowSelectionModel.length) {
      const addedFields = rowSelectionModel.filter((field) => !rowSelectionModelRef.current.includes(field)) as Array<
        keyof TFacets
      >;

      const removedFields = rowSelectionModelRef.current.filter((field) => !rowSelectionModel.includes(field)) as Array<string>;

      addedFields.forEach((field) => {
        const fieldConfig = fieldsConfigMap.get(field as string);
        if (fieldConfig?.type === AnalysisFieldTypeEnum.Date) {
          const selectedFacetValue = selectedFacetValuesRef.current[field];
          const selectedFacetValueShortcut = selectedFacetValuesRef.current[
            `${field as string}Shortcut` as keyof TFacets
          ] as string;
          const toolbarFacetValue = toolbarFieldFacets[field] as IDateRange;
          const toolbarFacetValueShortcut = toolbarFieldFacets[`${field as string}Shortcut` as keyof TFacets] as string;

          let dateRangeValue;
          let dateRangeShortcutValue;

          if (
            (selectedFacetValue && ((selectedFacetValue as IDateRange).from || (selectedFacetValue as IDateRange).to)) ||
            selectedFacetValueShortcut
          ) {
            dateRangeValue = selectedFacetValue;
            dateRangeShortcutValue = selectedFacetValueShortcut;
          } else if (
            (toolbarFacetValue && ((toolbarFacetValue as IDateRange).from || (toolbarFacetValue as IDateRange).to)) ||
            toolbarFacetValueShortcut
          ) {
            dateRangeValue = toolbarFacetValue as IDateRange;
            dateRangeShortcutValue = toolbarFacetValueShortcut;
          }

          onFieldChange(field as string, {
            dateRange: dateRangeValue || { from: "", to: "" },
            shortcut: dateRangeShortcutValue || "",
          });
        } else {
          const selectedFacetValue = (selectedFacetValuesRef.current[field] || []) as Array<string>;
          // Toolbar field facet values
          const toolbarFieldFacetValue = (toolbarFieldFacets[field] || []) as Array<string>;
          onFieldChange(field as string, selectedFacetValue.length > 0 ? selectedFacetValue : toolbarFieldFacetValue);
        }
      });

      removedFields.forEach((field) => {
        const fieldConfig = fieldsConfigMap.get(field);
        if (fieldConfig?.type === AnalysisFieldTypeEnum.Date) {
          onFieldChange(field, {
            dateRange: { from: "", to: "" },
            shortcut: "",
          });
        } else {
          onFieldChange(field, []);
        }
      });
    }
    rowSelectionModelRef.current = rowSelectionModel;
  }, [rowSelectionModel, fieldsConfigMap, selectedFacetValues, toolbarFieldFacets, onFieldChange]);

  useEffect(() => {
    rowSelectionModelRef.current = rowSelectionModel;
  }, [rowSelectionModel]);

  // Callback to handle toolbar field change
  const onToggleToolbarField = useCallback(
    (fieldName: keyof IAnalysisFacets) => {
      const fieldConfig = fieldsConfigMap.get(fieldName);

      if (showInToolbarFields.includes(fieldName)) {
        onShowInToolbarFieldsChange(showInToolbarFields.filter((name) => name !== fieldName));
        // When removing the toolbar fields, copy the toolbar facet values to bounds facet values
        if (fieldConfig) {
          let toolbarFacetValue = toolbarFieldFacets[fieldName] as TFacets[typeof fieldName];
          if (
            fieldConfig.type === AnalysisFieldTypeEnum.Date &&
            toolbarFacetValue &&
            ((toolbarFacetValue as IDateRange).hasOwnProperty("from") || (toolbarFacetValue as IDateRange).hasOwnProperty("to"))
          ) {
            const from = getEarliestDate(
              (toolbarFacetValue as IDateRange).from || "",
              (selectedFacetValues[fieldName] as IDateRange)?.from || "",
            );
            const to = getOldestDate(
              (toolbarFacetValue as IDateRange).to || "",
              (selectedFacetValues[fieldName] as IDateRange)?.to || "",
            );
            onFieldChange(fieldName, { dateRange: { from, to } });

            if (!rowSelectionModel.includes(fieldName)) {
              onRowSelectionModelChange([...rowSelectionModel, fieldName]);
            }
          } else if (
            (fieldConfig.type === AnalysisFieldTypeEnum.String || fieldConfig.type === AnalysisFieldTypeEnum.ArrayOfStrings) &&
            Array.isArray(toolbarFacetValue) &&
            (toolbarFacetValue as Array<string>).length > 0
          ) {
            onFieldChange(fieldName, [...(selectedFacetValues[fieldName] || []), ...toolbarFacetValue]);
            if (!rowSelectionModel.includes(fieldName)) {
              onRowSelectionModelChange([...rowSelectionModel, fieldName]);
            }
          }
        }
      } else {
        onShowInToolbarFieldsChange([...showInToolbarFields, fieldName]);
      }
    },
    [showInToolbarFields, onShowInToolbarFieldsChange, onFieldChange, selectedFacetValues, fieldsConfigMap, toolbarFieldFacets],
  );

  // Grid columns to pass to the data grid
  const boundsGridColumns = useValue(
    "boundsGridColumns",
    () => {
      return [
        {
          field: "label",
          headerName: t("Components.Analyses.Common.PreferencesDialog.LimitData"),
          width: 200,
          filterable: false,
          groupable: false,
          valueGetter: (params: GridRenderCellParams) => t(params.row.label),
        },
        {
          field: "type",
          headerName: "",
          width: 450,
          sortable: false,
          filterable: false,
          groupable: false,
          renderCell: (params: GridRenderCellParams) => {
            if (rowSelectionModel.includes(params.row.id)) {
              return (
                <AnalysisToolbarFormElements
                  fields={[params.row.field as keyof IAnalysisFacets]}
                  fieldsConfig={fieldsConfig}
                  fieldInputValues={distinctValues}
                  fieldSelectedValues={selectedFacetValues}
                  onFieldChange={onFieldChange}
                  isSortEnabled={false}
                  dateRangeShortcuts={dateRangeShortcuts}
                  slotProps={{
                    select: {
                      hideLabel: true,
                      formControlSize: "small",
                    },
                    dataDateRangePicker: {
                      label: "",
                      formControlSize: "small",
                      dateRangePickerSlotProps: {
                        textField: { size: "small" },
                      },
                    },
                  }}
                />
              );
            }
            return null;
          },
        },
        {
          field: "showInToolbar",
          headerName: t("Components.Analyses.Common.PreferencesDialog.ShowInToolbar"),
          width: 150,
          sortable: false,
          filterable: false,
          groupable: false,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Checkbox
                onChange={onToggleToolbarField.bind(undefined, params.row.field as keyof IAnalysisFacets)}
                disabled={!(params.row.isShowInToolbarEnabled ?? true)}
                checked={params.value}
              />
            );
          },
        },
      ];
    },
    [t, onToggleToolbarField, selectedFacetValues, distinctValues, onFieldChange, rowSelectionModel, dateRangeShortcuts],
  );

  // Grid rows to pass to the data grid
  const boundsGridRows = useValue(
    "boundsGridRows",
    () => {
      return fieldsConfig.map((fieldConfig) => {
        return {
          id: fieldConfig.field,
          ...fieldConfig,
          showInToolbar: showInToolbarFields.includes(fieldConfig.field as keyof IAnalysisFacets),
        };
      });
    },
    [fieldsConfig, showInToolbarFields],
  );

  return (
    <Box>
      <p>{t("Components.Analyses.Common.PreferencesDialog.BoundsTitle")}</p>
      <DataGridPremium
        sx={{
          height: 400,
          width: "calc(100% + 1px)",
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainerContent": {
            display: "none",
          },
        }}
        columns={boundsGridColumns}
        rows={boundsGridRows}
        checkboxSelection={true}
        disableRowSelectionOnClick={true}
        disableAggregation
        disableColumnFilter
        disableColumnMenu
        disableColumnResize
        disableColumnSelector
        disableMultipleRowSelection={true}
        hideFooter
        rowSelectionModel={rowSelectionModel as GridRowSelectionModel}
        onRowSelectionModelChange={(rowSelectionModel) => {
          onRowSelectionModelChange(rowSelectionModel as Array<keyof TFacets>);
        }}
        isRowSelectable={(params) => params.row.isBoundsEnabled ?? true}
      />
    </Box>
  );
}
