import * as React from "react";
import { useContainer, useEditor } from "tldraw";

import { MinimapManager } from "./MinimapManager";

export interface IMinimapThumbnailProps {
  onRender(dataUrl: string): void;
}

export function MinimapThumbnail(props: IMinimapThumbnailProps) {
  const { onRender } = props;
  const editor = useEditor();
  const container = useContainer();

  const rCanvas = React.useRef<HTMLCanvasElement>(null!);
  const minimapRef = React.useRef<MinimapManager>();

  React.useEffect(() => {
    try {
      const minimap = new MinimapManager(editor, rCanvas.current, container, onRender);
      minimapRef.current = minimap;

      return minimapRef.current.close;
    } catch (e) {
      console.error(e);
    }
  }, [editor, container]);

  return (
    <div
      style={{
        position: "fixed",
        visibility: "hidden",
        width: "1280px",
        height: "1024px",
      }}
    >
      <canvas
        role="img"
        ref={rCanvas}
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
}
