import {
  IGroupHistogramChartShape,
  getGroupHistogramChartShapeDefaultProps,
  groupHistogramChartShapeProps,
} from "@bigpi/tl-schema";
import { HTMLContainer, TLShapeUtilCanBindOpts, useValue } from "tldraw";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { AutoResizingContainer } from "Components/AutoResizingContainer/AutoResizingContainer";
import { IAnalysisShapeData, useBoardDatastore } from "BoardComponents/BoardDatastore";
import { BoxBaseUtil } from "BoardComponents/BaseShapes/BoxBaseUtil";
import { useIsInteracting } from "BoardComponents/Tools";
import { useShapeEvents } from "BoardComponents/useShapeEvents";
import { GroupHistogramChart } from "Components/Charting/Charts/GroupHistogramChart";
import { HistogramChartLoader } from "Components/Charting/Loaders/HistogramChartLoader";
import { DataUtils } from "Utils/DataUtils";

// *********************************************
// Private constants
// *********************************************/
/**
 * Padding to add around the chart to ensure that the warning message is visible and isn't on the edge of the chart.
 */
const WARNING_PADDING = 20;

// *********************************************
// Shape Util
// *********************************************/
/**
 * Generator for generic histogram chart shapes that support grouping.
 */
export class GroupHistogramChartUtil extends BoxBaseUtil<IGroupHistogramChartShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "groupHistogramChart";

  static props = groupHistogramChartShapeProps;

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override canBind = (options: TLShapeUtilCanBindOpts<IGroupHistogramChartShape>) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: IGroupHistogramChartShape) => true;

  /**
   * @inheritdoc
   */
  override canResize = (shape: IGroupHistogramChartShape) => false;

  /**
   * @inheritdoc
   */
  override canScroll = (shape: IGroupHistogramChartShape) => false;

  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: IGroupHistogramChartShape) => false;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): IGroupHistogramChartShape["props"] {
    return getGroupHistogramChartShapeDefaultProps();
  }

  /**
   * @inheritdoc
   */
  override indicator(shape: IGroupHistogramChartShape) {
    const { id } = shape;
    const isEditing = this.editor.getCurrentPageState().editingShapeId === id;
    return <rect width={shape.props.w} height={shape.props.h} stroke={isEditing ? "transparent" : ""} />;
  }

  /**
   * @inheritdoc
   */
  component(shape: IGroupHistogramChartShape) {
    const tldrawEditor = this.editor;
    const isEditing = useValue(
      "groupHistogramChart.isEditing",
      () => tldrawEditor.getCurrentPageState().editingShapeId === shape.id,
      [tldrawEditor, shape.id],
    );
    const isInteracting = useIsInteracting(shape.id);
    const parentId = useValue("groupHistogramChart.parentId", () => tldrawEditor.getShape(shape.id)!.parentId, [shape.id]);
    const datastore = useBoardDatastore();
    const widthRef = React.useRef<number>(shape.props.w);
    const { t } = useTranslation();

    const { handleInputPointerDown } = useShapeEvents(shape.id);

    // Get the parent data from the datastore (without strong types)
    const parentData = useValue(
      "groupHistogramChart.parentData",
      () =>
        datastore.state.get()[parentId]?.get() as IAnalysisShapeData<
          Array<Record<string, any>>,
          Record<string, any>,
          Record<string, any>,
          Record<string, any>
        >,
      [datastore, parentId],
    );

    if (!parentData) {
      return (
        <HTMLContainer
          id={shape.id}
          style={{
            background: "#fff",
            display: "block",
            pointerEvents: "all",
            padding: WARNING_PADDING,
          }}
        >
          {t("Components.Charts.NoParent")}
        </HTMLContainer>
      );
    }

    // Get the filtered data from the datastore
    const multiFacetFilteredData =
      parentData?.multiFacetFilteredData?.get() || DataUtils.getImmutableEmptyArray<Record<string, any>>();

    // Get the other required data, such as facets and config from the datastore
    const selection = parentData?.selection?.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const preferences = parentData?.preferences?.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const allData = parentData?.allData.get() || DataUtils.getImmutableEmptyArray<Record<string, any>>();
    const configLoading = parentData?.configLoading?.get() || false;
    const dataLoading = parentData?.dataLoading?.get() || false;
    const config = parentData?.config.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const scales = parentData?.scales?.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const yAxisTickFormat = parentData?.yAxisTickFormat?.get();
    const plotReducers = parentData?.plotReducers?.get() || DataUtils.getImmutableEmptyObject<Record<string, any>>();
    const dataFormatters = parentData?.dataFormatters?.get();
    const onSelectionChange = parentData?.onSelectionChange?.get();

    const { groupHistogramChart = {}, analysis = {} } = config;
    const { fields = {}, facetFields = {} } = groupHistogramChart;
    const analysisPreferences = preferences.analysis;
    const startColor = analysisPreferences?.startColor;

    if (dataLoading || configLoading) {
      return (
        <HTMLContainer
          id={shape.id}
          style={{
            background: "#fff",
            display: "block",
            pointerEvents: "all",
            overflow: "hidden",
          }}
        >
          <HistogramChartLoader withLabel={false} sx={{ marginLeft: 25 }} />
        </HTMLContainer>
      );
    }

    // Dynamic values
    const dateRange = DataUtils.getDateRange(allData, fields.xField);
    const xDomainValues = [new Date(dateRange.min || ""), new Date(dateRange.max || "")];
    widthRef.current = config.width || shape.props.w;
    const axisFields: {
      xField: string;
      yGroupField: string;
      yItemField?: string;
    } = {
      xField: fields.xField,
      yGroupField: fields.yGroupField,
    };

    if (analysisPreferences && analysisPreferences.showTopics) {
      axisFields["yItemField"] = fields.yItemField;
    }

    return (
      <HTMLContainer
        id={shape.id}
        style={{
          background: "transparent",
          display: "block",
          pointerEvents: "all",
        }}
      >
        <div
          style={{
            pointerEvents: isEditing || isInteracting ? "auto" : "none",
          }}
          onPointerDown={handleInputPointerDown}
        >
          {!multiFacetFilteredData || multiFacetFilteredData.length === 0 ? (
            <div style={{ width: "100%", height: "100%", background: "#fff", padding: WARNING_PADDING }}>
              {t("Components.Charts.NoData")}
            </div>
          ) : (
            <AutoResizingContainer onResize={this._onShapeResize.bind(this, shape)}>
              <GroupHistogramChart
                {...axisFields}
                canInteract={isEditing || isInteracting}
                data={multiFacetFilteredData}
                dataFormatters={dataFormatters}
                facetFields={facetFields}
                facets={selection}
                fillReducer={plotReducers.fillReducer}
                groupReducers={plotReducers.groupReducers}
                marginLeft={analysis.approximateMarginLeft}
                onUpdateFacets={(newSelection: Record<string, unknown>) => {
                  onSelectionChange && onSelectionChange(newSelection);
                }}
                plotWidth={widthRef.current}
                scales={scales}
                xDomainValues={xDomainValues}
                yAxisTickFormat={yAxisTickFormat}
                startColor={startColor}
              />
            </AutoResizingContainer>
          )}
        </div>
      </HTMLContainer>
    );
  }

  // *********************************************
  // Private methods, event handlers
  // *********************************************/
  /**
   * Handles shape resize events.
   *
   * @param shape The shape that was resized.
   * @param width The new width.
   * @param height The new height.
   */
  private _onShapeResize(shape: IGroupHistogramChartShape, width: number, height: number) {
    const { id, type, props } = shape;
    this.editor.updateShapes([
      {
        id,
        type,
        props: {
          h: height || shape.props.h,
        },
      },
    ]);
  }

  /**
   * Handles facet updates.
   *
   * @param shape The shape.
   * @param newFacet The new facets.
   */
  private _onUpdateFacets(shape: IGroupHistogramChartShape, newFacets: Record<string, unknown>) {
    const parentId = shape.parentId;
    const parentShape = this.editor.getShape(parentId);
    if (parentShape) {
      this.editor.updateShapes([
        {
          id: parentShape.id,
          type: parentShape.type,
          props: {
            selection: newFacets,
          },
        },
      ]);
    }
  }
}
