import { ApolloClient, useApolloClient } from "@apollo/client";
import { DataGridColumnFormat, DataGridDetailsPanelFormat, ShapeDatastoreType } from "@bigpi/cookbook";
import {
  IDataGridColumnDef,
  IDataGridPreferences,
  IDataGridShape,
  ITopicDiscussionAnalysisShape,
  ITopicDiscussionAnalysisFacets,
  ITopicDiscussionSummaryAnalysisFieldFacets,
  ITopicDiscussionSummaryAnalysisShape,
} from "@bigpi/tl-schema";
import { createShapeId, Editor, TLGeoShape, TLShape, TLShapePartial, TLTextShape, useEditor } from "tldraw";
import { TFunction } from "i18next";
import { MutableRefObject, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from "uuid";

import { insertShapesAtViewportEmptyPoint } from "BoardComponents/Utils/CreateShapeUtils";
import { downloadCsv } from "BoardComponents/Utils/DataDownloadUtils";
import { ITopicDiscussionSummaryMasterDetailsRef } from "Components/TopicDiscussionSummaryMasterDetails/TopicDiscussionSummaryMasterDetails";
import { useCreateShapeDataMutation } from "GraphQL/Generated/Apollo";
import { IAnalysisConfig } from "./DataFrameConfigs/IAnalysisConfig";
import { ITopicDiscussionAnalysisConfig } from "./DataFrameConfigs";

const SHAPE_PADDING = 50;
const DATA_GRID_HEIGHT = 930;
const DATA_GRID_WIDTH = 900;

export interface ITopicDiscussionCompanionOverlayOptions {
  config: ITopicDiscussionAnalysisConfig;
  detailColumnsConfig: Array<IDataGridColumnDef>;
  detailGridPreferences: IDataGridPreferences;
  facets: ITopicDiscussionAnalysisFacets | ITopicDiscussionSummaryAnalysisFieldFacets;
  filteredData: Array<any>;
  onAddItemsToBoard: (
    editor: Editor,
    shape: TLShape,
    data: Array<Record<string, any>>,
    selectedIds: Array<string>,
    config: IAnalysisConfig,
    facets: Record<string, any>,
    t: TFunction<"translation", undefined>,
    apolloClient: ApolloClient<object>,
  ) => Promise<void>;
  shape: ITopicDiscussionAnalysisShape | ITopicDiscussionSummaryAnalysisShape;
  topicDiscussionSummaryMasterDetailsRef: MutableRefObject<ITopicDiscussionSummaryMasterDetailsRef | null>;
}

export const useTopicDiscussionCompanionOverlay = (options: ITopicDiscussionCompanionOverlayOptions) => {
  const {
    config,
    detailColumnsConfig,
    detailGridPreferences,
    facets,
    filteredData,
    onAddItemsToBoard,
    shape,
    topicDiscussionSummaryMasterDetailsRef,
  } = options;

  const tldrawEditor = useEditor();
  const { t } = useTranslation();
  const apolloClient = useApolloClient();

  const [createShapeDataMutation] = useCreateShapeDataMutation();

  const workspaceBoardId = (tldrawEditor.getInstanceState().meta.workspaceBoardId as string) || "";

  const onTopicDiscussionSummaryMasterDetailsAddToBoard = useCallback(
    async (titleText: string) => {
      try {
        const groupShapeId = createShapeId(uuidV4());
        const dataGridShapeId = createShapeId(uuidV4());
        const currentPageId = tldrawEditor.getCurrentPageId();
        const shapesToCreate: Array<TLShapePartial> = [
          {
            id: createShapeId(uuidV4()),
            type: "text",
            props: {
              text: titleText,
              textAlign: "start",
              autoSize: false,
              w: DATA_GRID_WIDTH,
              font: "sans",
              size: "l",
            },
            parentId: groupShapeId,
            x: SHAPE_PADDING,
            y: SHAPE_PADDING,
          } as TLShapePartial<TLTextShape>,
        ];

        // Only consider rows that are selected, if nothing is selected, consider all rows
        const selectedRowIds = topicDiscussionSummaryMasterDetailsRef.current?.selectedRowIds;
        const selectedRows =
          selectedRowIds && selectedRowIds.length
            ? filteredData.filter((item) => selectedRowIds.includes(item.id))
            : filteredData;

        const { data } = await createShapeDataMutation({
          variables: {
            input: {
              shapeId: dataGridShapeId,
              workspaceBoardId,
              data: JSON.stringify(selectedRows),
            },
          },
        });

        const datastoreId = data?.createShapeData?.id;
        if (datastoreId) {
          shapesToCreate.push({
            id: dataGridShapeId,
            type: "dataGrid",
            props: {
              w: DATA_GRID_WIDTH,
              h: DATA_GRID_HEIGHT,
              config: {
                addToDocument: false,
                autoResize: false,
                checkboxSelection: false,
                columns: detailColumnsConfig,
                detailsPanelFormat: DataGridDetailsPanelFormat.TopicDiscussionSummaryMasterDetails,
                documentItemTemplate: config.dataGrid.documentItemTemplate,
                fontSize: 16,
                isPaginationEnabled: true,
              },
              datastoreId,
              datastoreType: ShapeDatastoreType.ServerDatastore,
              preferences: detailGridPreferences,
              selectedIds: [],
            },
            parentId: groupShapeId,
            x: SHAPE_PADDING,
            y: 2 * SHAPE_PADDING,
          } as TLShapePartial<IDataGridShape>);

          // Add rectangle
          shapesToCreate.push({
            id: createShapeId(uuidV4()),
            rotation: 0,
            type: "geo",
            typeName: "shape",
            index: "a0",
            x: 0,
            y: 0,
            props: {
              align: "middle",
              fill: "semi",
              geo: "rectangle",
              h: SHAPE_PADDING * 3 + DATA_GRID_HEIGHT,
              w: DATA_GRID_WIDTH + SHAPE_PADDING * 2,
              verticalAlign: "middle",
              size: "m",
              color: "black",
            },
          } as TLShapePartial<TLGeoShape>);
        }

        insertShapesAtViewportEmptyPoint(tldrawEditor, shapesToCreate);
        // Group shape creating separately to avoid misplacement of child shapes
        const shapesToUpdate: Array<TLShapePartial> = [];
        shapesToCreate.forEach((shape) => {
          shapesToUpdate.push({
            ...shape,
            parentId: groupShapeId,
          });
        });
        tldrawEditor.createShape({
          id: groupShapeId,
          type: "group",
          x: 0,
          y: 0,
          parentId: currentPageId,
        });
        tldrawEditor.updateShapes(shapesToUpdate);
      } catch (e) {
        console.error(e);
      }
    },
    [config.dataGrid, createShapeDataMutation, filteredData, workspaceBoardId, topicDiscussionSummaryMasterDetailsRef],
  );

  const onTopicDiscussionSummaryMasterDetailsAddDocumentToBoard = useCallback(() => {
    // Only consider rows that are selected, if nothing is selected, consider all rows
    const selectedRowIds = topicDiscussionSummaryMasterDetailsRef.current?.selectedRowIds || [];

    // Add all (filtered) items to the board as a document
    onAddItemsToBoard(tldrawEditor, shape, filteredData, selectedRowIds, config, facets, t, apolloClient);
  }, [config, facets, filteredData, t, apolloClient, shape, topicDiscussionSummaryMasterDetailsRef]);

  const onTopicDiscussionSummaryMasterDetailsDownloadCsv = useCallback(() => {
    const includeColumns = detailColumnsConfig.map((column) => column.field);

    // Only consider rows that are selected, if nothing is selected, consider all rows
    const selectedRowIds = topicDiscussionSummaryMasterDetailsRef.current?.selectedRowIds || [];

    // Include the summary which is in the details section, not column configuration
    includeColumns.push("summary");
    detailColumnsConfig.push({
      field: "summary",
      headerName: "Summary",
      flex: 2,
      format: DataGridColumnFormat.HtmlString,
    });

    downloadCsv(
      includeColumns,
      detailColumnsConfig,
      filteredData,
      selectedRowIds,
      t("Components.Analyses.Common.DefaultFilename"),
      t,
    );
  }, [filteredData, t, topicDiscussionSummaryMasterDetailsRef]);

  const onTopicDiscussionSummaryMasterDetailsExportToExcel = useCallback(() => {
    topicDiscussionSummaryMasterDetailsRef.current?.exportDataAsExcel();
  }, [topicDiscussionSummaryMasterDetailsRef]);

  return {
    onTopicDiscussionSummaryMasterDetailsAddDocumentToBoard,
    onTopicDiscussionSummaryMasterDetailsAddToBoard,
    onTopicDiscussionSummaryMasterDetailsDownloadCsv,
    onTopicDiscussionSummaryMasterDetailsExportToExcel,
  };
};
