import { TLShapeId, useEditor, useReadonly, useValue } from "tldraw";

export const useIsChildInteracting = (shapeId: TLShapeId) => {
  const editor = useEditor();
  const isReadonly = useReadonly();

  const hoveredShapeId = editor.getCurrentPageState().hoveredShapeId;

  return useValue(
    "isChildInteracting",
    () =>
      !isReadonly &&
      !!hoveredShapeId &&
      editor.getSortedChildIdsForParent(shapeId).includes(hoveredShapeId) &&
      editor.isInAny("interact.idle", "interact.pointing_shape"),
    [editor, hoveredShapeId, isReadonly, shapeId],
  );
};
