import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { useCopyWorkspaceMutation, useCreateWorkspaceMutation } from "GraphQL/Generated/Apollo";
import { WorkspaceTemplateGrid } from "./WorkspaceTemplateGrid";
import { linkToWorkspaceBoard } from "RoutePaths";

export interface INewWorkspaceDialogProps {
  onClose: () => void;
  open: boolean;
}

export function NewWorkspaceDialog(props: INewWorkspaceDialogProps) {
  const { open } = props;
  const { t } = useTranslation();
  const [createWorkspace] = useCreateWorkspaceMutation();
  const [CopyWorkspace] = useCopyWorkspaceMutation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [name, setName] = React.useState("");
  const [nameFocused, setNameFocused] = React.useState(true);
  const nameRef = React.useRef<HTMLInputElement>(null);
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<string | null>(null);
  const [copyBoardPermissions, setCopyBoardPermissions] = React.useState<boolean>(false);
  const [copyFiles, setCopyFiles] = React.useState<boolean>(false);

  function onClose() {
    setErrorMessage("");
    setName("");
    props.onClose();
  }

  async function onCreateFromTemplate(templateId: string, workspaceName: string, copyBoardPermissions: boolean) {
    try {
      const result = await CopyWorkspace({
        variables: {
          input: {
            workspaceId: templateId,
            name: workspaceName,
            workspaceBoard: {
              name: t("Pages.WorkspaceBoard.DefaultName"),
            },
            copyBoardPermissions,
            copyFiles,
          },
        },
        refetchQueries: ["Workspaces"],
      });

      // Open the default board of the new workspace
      const defaultBoardId = result.data?.copyWorkspace?.defaultWorkspaceBoard?.id;
      if (defaultBoardId) {
        navigate(linkToWorkspaceBoard(templateId, defaultBoardId), { replace: true });
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(t("Components.NewWorkspaceDialog.Error"));
    }
  }

  async function onCreateNewWorkspace(workspaceName: string) {
    try {
      const result = await createWorkspace({
        variables: { input: { name: workspaceName, workspaceBoard: { name: t("Pages.WorkspaceBoard.DefaultName") } } },
        refetchQueries: ["Workspaces"],
      });

      // Open the default board of the new workspace
      const newWorkspaceId = result.data?.createWorkspace?.id;
      const defaultBoardId = result.data?.createWorkspace?.defaultWorkspaceBoard?.id;
      if (newWorkspaceId && defaultBoardId) {
        navigate(linkToWorkspaceBoard(newWorkspaceId, defaultBoardId), { replace: true });
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(t("Components.NewWorkspaceDialog.Error"));
    }
  }

  function onCreate() {
    if (selectedTemplateId) {
      onCreateFromTemplate(selectedTemplateId, name, copyBoardPermissions);
    } else {
      onCreateNewWorkspace(name);
    }
  }

  function onSelectTemplate(templateId: string | null) {
    if (selectedTemplateId === null) {
      setCopyBoardPermissions(false);
      setCopyFiles(false);
    }
    setSelectedTemplateId(templateId);
  }

  React.useEffect(() => {
    if (open && nameRef.current) {
      nameRef.current.focus();
    }
  }, [nameRef.current, open]);

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="md">
      <DialogTitle variant="h5">{t("Components.NewWorkspaceDialog.Title")}</DialogTitle>
      <DialogContent>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <TextField
          autoFocus={true}
          margin="dense"
          label={t("Components.NewWorkspaceDialog.NameLabel")}
          type="text"
          fullWidth
          variant="standard"
          value={name}
          onBlur={() => setNameFocused(false)}
          onChange={(e) => setName(e.target.value ?? "")}
          onFocus={() => setNameFocused(true)}
          required
          inputRef={nameRef}
          error={name === "" && !nameFocused}
        />
        <DialogContentText variant="caption">{t("Components.NewWorkspaceDialog.Tip")}</DialogContentText>
        <WorkspaceTemplateGrid selectedTemplateId={selectedTemplateId} onSelectTemplate={onSelectTemplate} blankEnabled />
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Box>
            {selectedTemplateId && (
              <FormGroup sx={{ ml: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox checked={copyBoardPermissions} onChange={(_, checked) => setCopyBoardPermissions(checked)} />
                  }
                  label={t("Components.NewWorkspaceDialog.CopyPermissionsLabel")}
                />
                <FormControlLabel
                  control={<Checkbox checked={copyFiles} onChange={(_, checked) => setCopyFiles(checked)} />}
                  label={t("Components.NewWorkspaceDialog.CopyFilesLabel")}
                />
              </FormGroup>
            )}
          </Box>
          <Box>
            <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
            <Button onClick={onCreate} disabled={name === ""}>
              {t("Components.NewWorkspaceDialog.Create")}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
