import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { EditInWorkspaceDialog } from "Components/EditInWorkspaceDialog/EditInWorkspaceDialog";
import { useEditDocumentsInWorkspaceMutation } from "GraphQL/Generated/Apollo";
import { linkToWorkspaceBoard } from "RoutePaths";

export interface IEditDocumentsInWorkspaceDialog {
  documentIds: Array<string>;
  onClose: () => void;
  open: boolean;
}

export function EditDocumentsInWorkspaceDialog(props: IEditDocumentsInWorkspaceDialog) {
  const { documentIds, open } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = React.useState("");

  const [editDocumentsInWorkspace] = useEditDocumentsInWorkspaceMutation();

  function onClose() {
    setErrorMessage("");
    props.onClose();
  }

  async function onCreate(name: string) {
    try {
      const result = await editDocumentsInWorkspace({
        variables: {
          input: {
            documentIds,
            workspaceName: name,
            workspaceBoardName: t("Pages.WorkspaceBoard.DefaultName"),
          },
        },
        refetchQueries: ["Workspaces"],
      });

      // Open the default board of the new workspace
      const newWorkspaceId = result.data?.editDocumentsInWorkspace?.id;
      const defaultBoardId = result.data?.editDocumentsInWorkspace?.defaultWorkspaceBoard?.id;
      if (newWorkspaceId && defaultBoardId) {
        navigate(linkToWorkspaceBoard(newWorkspaceId, defaultBoardId), { replace: true });
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(t("Components.EditDocumentsInWorkspaceDialog.Error"));
    }
  }

  return <EditInWorkspaceDialog errorMessage={errorMessage} open={open} onClose={onClose} onCreate={onCreate} />;
}
