import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

export function useHashParams() {
  const location = useLocation();
  const [params, setParams] = useState<URLSearchParams>(new URLSearchParams(location.hash.slice(1)));

  useEffect(() => {
    const hash = location.hash.slice(1);
    if (hash && hash !== params.toString()) {
      setParams(new URLSearchParams(hash));
    }
  }, [location.hash]);

  const setHashParams = useCallback((params: Record<string, string>) => {
    setParams((prevParams) => {
      for (const [key, value] of Object.entries(params)) {
        prevParams.set(key, value);
      }
      return new URLSearchParams(prevParams);
    });
  }, []);

  return { params, setHashParams };
}
