import { createShapePropsMigrationIds, createShapePropsMigrationSequence } from "@tldraw/tlschema";

import { ITopicDiscussionSummaryAnalysisShape } from "./ITopicDiscussionSummaryAnalysisShape.js";

export const TopicDiscussionSummaryAnalysisShapeVersions = createShapePropsMigrationIds("topicDiscussionSummaryAnalysis", {
  Initial: 0,
  AddToolbarField: 1,
  AddEnableBackgroundField: 2,
  AddPreferencesField: 3,
  RemoveDataGridPreferencesColumnVisibility: 4,
  AddPreferencesFacetSortModel: 5,
  AddStandardisedFacetsAndBounds: 6,
});

export const topicDiscussionSummaryAnalysisShapeMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: TopicDiscussionSummaryAnalysisShapeVersions.AddToolbarField,
      up(props) {
        props.toolbar = {};
      },
      down(props) {
        delete props.toolbar;
      },
    },
    {
      id: TopicDiscussionSummaryAnalysisShapeVersions.AddEnableBackgroundField,
      up(props) {
        props.enableBackground = false;
      },
      down(props) {
        delete props.enableBackground;
      },
    },
    {
      id: TopicDiscussionSummaryAnalysisShapeVersions.AddPreferencesField,
      up(props) {
        props.preferences = {};
      },
      down(props) {
        delete props.preferences;
      },
    },
    {
      id: TopicDiscussionSummaryAnalysisShapeVersions.RemoveDataGridPreferencesColumnVisibility,
      up(props) {
        if (props.preferences?.dataGrid) {
          delete props.preferences.dataGrid.columnVisibility;
        }
      },
      down(props) {
        if (props.preferences?.dataGrid) {
          props.preferences.dataGrid.columnVisibility = {};
        }
      },
    },
    {
      id: TopicDiscussionSummaryAnalysisShapeVersions.AddPreferencesFacetSortModel,
      up(props) {
        if (!props.preferences) props.preferences = {};
        if (!props.preferences.analysis) props.preferences.analysis = {};
        props.preferences.analysis.facetSort = [];
      },
      down(props) {
        if (props.preferences?.analysis) {
          delete props.preferences.analysis.facetSort;
        }
      },
    },
    {
      id: TopicDiscussionSummaryAnalysisShapeVersions.AddStandardisedFacetsAndBounds,
      up(props) {
        const { facets, toolbar, preferences } = props;

        let selectedFacetValues: ITopicDiscussionSummaryAnalysisShape["props"]["selectedFacetValues"] = {};
        let selection: ITopicDiscussionSummaryAnalysisShape["props"]["selection"] = {};
        let toolbarProps = {};
        let preferencesProps = { ...(preferences || {}) };

        // Transforms facets to latest
        if (facets) {
          if (facets.selectedCategories) {
            selectedFacetValues.category = facets.selectedCategories;
          }

          if (facets.selectedDate) {
            selectedFacetValues.eventDate = facets.selectedDate;
          }

          if (facets.selectedSections) {
            selectedFacetValues.section = facets.selectedSections;
          }

          if (facets.selectedTickers) {
            selectedFacetValues.ticker = facets.selectedTickers;
          }

          if (facets.selectedTopics) {
            selectedFacetValues.topic = facets.selectedTopics;
          }

          if (facets.expandedGroups) {
            selection.expandedGroups = facets.expandedGroups;
          }

          if (facets.selectedGroup) {
            selection.selectedGroup = facets.selectedGroup;
          }

          if (facets.selectedItem) {
            selection.selectedItem = facets.selectedItem;
          }

          if (facets.selectedXAxis) {
            selection.selectedXAxis = facets.selectedXAxis;
          }
        }

        if (facets && facets.includeItems !== undefined) {
          preferencesProps.analysis = {
            ...(preferencesProps.analysis || {}),
            showQuestions: facets.includeItems,
          };
        }

        if (toolbar && toolbar.filterFields && toolbar.filterFields.length > 0) {
          toolbarProps = { availableFields: toolbar.filterFields };
        } else {
          toolbarProps = { availableFields: ["eventDate", "category", "topic", "section"] };
        }

        props.selectedFacetValues = selectedFacetValues;
        props.boundsFacetValues = {};
        props.selection = selection;
        props.toolbar = toolbarProps;
        props.preferences = preferencesProps;

        if (props.facets) {
          delete props.facets;
        }
      },
      down(props) {
        const { preferences, selectedFacetValues, toolbar, selection } = props;

        let facets: Record<string, any> = {};
        let toolbarProps = {};

        if (selectedFacetValues) {
          if (selectedFacetValues.category) {
            facets.selectedCategories = selectedFacetValues.category;
          }

          if (selectedFacetValues.eventDate) {
            facets.selectedDate = selectedFacetValues.eventDate;
          }

          if (selectedFacetValues.section) {
            facets.selectedSections = selectedFacetValues.section;
          }

          if (selectedFacetValues.ticker) {
            facets.selectedTickers = selectedFacetValues.ticker;
          }

          if (selectedFacetValues.topic) {
            facets.selectedTopics = selectedFacetValues.topic;
          }
        }

        if (selection) {
          if (selection.expandedGroups) {
            facets.expandedGroups = selection.expandedGroups;
          }

          if (selection.selectedGroup) {
            facets.selectedGroup = selection.selectedGroup;
          }

          if (selection.selectedItem) {
            facets.selectedItem = selection.selectedItem;
          }

          if (selection.selectedXAxis) {
            facets.selectedXAxis = selection.selectedXAxis;
          }
        }

        if (preferences?.analysis?.showQuestions !== undefined) {
          facets.includeItems = preferences.analysis.showQuestions;
          delete preferences.analysis.showQuestions;
        }

        if (preferences && preferences.analysis && preferences.analysis.startColor !== undefined) {
          delete preferences.analysis.startColor;
        }

        if (preferences && preferences.analysis && preferences.analysis.showDataToDisplayInToolbar !== undefined) {
          delete preferences.analysis.showDataToDisplayInToolbar;
        }

        if (toolbar?.availableFields) {
          toolbarProps = { filterFields: toolbar.availableFields };
        }

        props.facets = facets;
        props.toolbar = toolbarProps;

        if (props.boundsFacetValues) {
          delete props.boundsFacetValues;
        }

        if (selectedFacetValues) {
          delete props.selectedFacetValues;
        }

        if (selection) {
          delete props.selection;
        }
      },
    },
  ],
});
