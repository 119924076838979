import {
  COMMAND_GENERATE_BOARD_QUESTIONNAIRE_SUGGESTIONS_TEMPLATE,
  COMMAND_STATUS_RUNNING,
  ICommandRequest,
  ICommandResponseStatus,
} from "@bigpi/cookbook";
import UpdateIcon from "@mui/icons-material/Update";
import { TabContext, TabPanel, TabPanelProps } from "@mui/lab";
import { Box, CircularProgress, Stack } from "@mui/material";
import { TLShapeId } from "tldraw";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CompanionTabActions } from "BoardComponents/Companion/CompanionActions";
import { CompanionContent, ICompanionContentProps } from "BoardComponents/Companion/CompanionContent";
import { CompanionHeader } from "BoardComponents/Companion/CompanionHeader";
import { CompanionTab } from "BoardComponents/Companion/CompanionTab";
import { CompanionTabs } from "BoardComponents/Companion/CompanionTabs";
import { ICompanionTab } from "BoardComponents/Companion/ICompanionTab";
import { CommandContext } from "CommandContext";
import { useCommandExecutor } from "Components/CommandManagers/useCommandExecutor";
import { WorkspaceBoardSuggestions } from "Components/WorkspaceBoardSuggestions/WorkspaceBoardSuggestions";
import {
  CommandStatusQuery,
  OnCommandStatusChangedSubscription,
  OnCommandStatusChangedSubscriptionVariables,
  useCommandStatusLazyQuery,
} from "GraphQL/Generated/Apollo";
import { COMMAND_STATUS_SUBSCRIPTION } from "GraphQL/CommandQueue/Subscription";

export enum QuestionnaireCompanionTab {
  Suggestions = "Suggestions",
}

export interface IQuestionnaireCompanionProps {
  scrollDocumentToBlockId: (blockId: string) => void;
  shapeId: TLShapeId;
  title: string | React.ReactNode;
  workspaceId: string;
  workspaceBoardId: string;

  slotProps?: {
    CompanionContent?: {
      sx?: ICompanionContentProps["sx"];
    };
    TabPanel?: Partial<TabPanelProps>;
  };
}

export function QuestionnaireCompanion(props: IQuestionnaireCompanionProps) {
  const { scrollDocumentToBlockId, shapeId, slotProps, workspaceId, workspaceBoardId } = props;
  const commandExecutor = useCommandExecutor();
  const { t } = useTranslation();
  const [tabs, setTabs] = useState<Array<ICompanionTab>>([]);
  const [isGenerateSuggestionsRunning, setIsGenerateSuggestionsRunning] = useState<boolean>(false);
  const [activeTabId, setActiveTabId] = useState<string | undefined>(tabs.length > 0 ? tabs[0].tabId : undefined);

  useEffect(() => {
    if (!activeTabId && tabs.length > 0) {
      setActiveTabId(tabs[0].tabId);
    }
  }, [activeTabId, tabs]);

  // Request IDs for commands
  const generateSuggestionsRequestId = `workspace-board-shape-suggest-${shapeId}`;

  const generateSuggestions = useCallback(async () => {
    setIsGenerateSuggestionsRunning(true);

    const command = {
      ...COMMAND_GENERATE_BOARD_QUESTIONNAIRE_SUGGESTIONS_TEMPLATE,
    };

    // Make sure the shape ID is in the context. It might not be when companion is still open, but shape is no longer selected
    const currentContext = CommandContext.getCommandContext();

    // Create the overrides/values that are not set automatically by CommandExecutor
    const commandRequest: Partial<ICommandRequest> = {
      commandContext: {
        ...currentContext,
        selection: {
          ...currentContext.selection,
          shapeIds: [shapeId],
        },
      },
      // Create deduplicationId
      deduplicationId: generateSuggestionsRequestId,
      requestId: generateSuggestionsRequestId,
    };

    await commandExecutor.executeCommand(command, commandRequest);
  }, [commandExecutor, generateSuggestionsRequestId, shapeId, workspaceBoardId]);

  const [
    fetchInitialCommandStatusSuggestions,
    { data: commandStatusDataSuggestions, subscribeToMore: subscribeToMoreSuggestions },
  ] = useCommandStatusLazyQuery();

  // Dynamically subscribe to command status changes for suggestions
  useEffect(() => {
    fetchInitialCommandStatusSuggestions({
      fetchPolicy: "network-only",
      variables: {
        id: generateSuggestionsRequestId,
      },
    });

    const unsubscribeGenerateSuggestions = subscribeToMoreSuggestions<
      OnCommandStatusChangedSubscription,
      OnCommandStatusChangedSubscriptionVariables
    >({
      document: COMMAND_STATUS_SUBSCRIPTION,
      variables: { id: generateSuggestionsRequestId },
      updateQuery: (prev, { subscriptionData, variables }) => {
        if (!subscriptionData.data || !variables) {
          return prev;
        }

        // If we don't have any data, it means the command has completed
        const status: ICommandResponseStatus = subscriptionData.data.onCommandStatusChanged?.status
          ? (subscriptionData.data.onCommandStatusChanged?.status as ICommandResponseStatus)
          : "success";
        const newItem: CommandStatusQuery = {
          __typename: "Query",
          commandStatus: {
            __typename: "CommandQueueStatus",
            // We're not using the commandId here, so we can leave it empty if needed
            commandId: prev.commandStatus?.commandId || "",
            requestId: variables.id,
            // Success might not be correct, but works to mark as completed
            status,
          },
        };
        return newItem;
      },
    });

    return () => {
      unsubscribeGenerateSuggestions();
    };
  }, [fetchInitialCommandStatusSuggestions, generateSuggestionsRequestId, subscribeToMoreSuggestions]);

  // Handle subscription updated for suggestions
  useEffect(() => {
    const status = commandStatusDataSuggestions?.commandStatus?.status as ICommandResponseStatus | undefined;

    setIsGenerateSuggestionsRunning(status !== undefined && COMMAND_STATUS_RUNNING.includes(status as ICommandResponseStatus));
  }, [commandStatusDataSuggestions]);

  useEffect(() => {
    setTabs([
      {
        tabId: QuestionnaireCompanionTab.Suggestions,
        title: t(`Components.HtmlDocumentCompanion.Questionnaire.Tabs.${QuestionnaireCompanionTab.Suggestions}.Title`),
        children: (
          <WorkspaceBoardSuggestions
            workspaceId={workspaceId}
            workspaceBoardId={workspaceBoardId}
            shapeId={shapeId}
            scrollDocumentToBlockId={scrollDocumentToBlockId}
          />
        ),
        actions: [
          {
            title: (
              <>
                <Box sx={{ m: 1, position: "relative" }}>
                  <Stack alignItems="center" direction="row" gap={1}>
                    <UpdateIcon sx={{ mr: 1 }} />
                    {t(
                      `Components.HtmlDocumentCompanion.Questionnaire.Tabs.${QuestionnaireCompanionTab.Suggestions}.Actions.Generate`,
                    )}
                  </Stack>
                  {isGenerateSuggestionsRunning && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </>
            ),
            onAction: generateSuggestions,
            disabled: isGenerateSuggestionsRunning,
            value: "generate-answers",
          },
        ],
        sx: {
          p: 0,
        },
      },
    ]);
  }, [generateSuggestions, isGenerateSuggestionsRunning, scrollDocumentToBlockId, shapeId, t, workspaceBoardId]);

  return (
    <>
      {tabs.length > 1 || (tabs.length === 1 && tabs[0].actions.length > 0) ? (
        <>
          <CompanionHeader>
            <CompanionTabs
              value={activeTabId ?? tabs[0].tabId}
              onChange={(event: React.SyntheticEvent, newValue: string) => {
                // Event?
                setActiveTabId(newValue);
              }}
              sx={{ flexGrow: 1, mr: 2 }}
            >
              {tabs.map((tab) => (
                <CompanionTab key={tab.tabId} label={tab.title} value={tab.tabId} />
              ))}
            </CompanionTabs>
            <CompanionTabActions tabActions={tabs.find((tab) => tab.tabId === activeTabId)?.actions ?? []} />
          </CompanionHeader>
          <CompanionContent sx={{ padding: "0px 24px" }} {...(slotProps ? slotProps.CompanionContent : {})}>
            <TabContext value={activeTabId ?? tabs[0].tabId}>
              {tabs.map((tab) => (
                <TabPanel key={tab.tabId} value={tab.tabId} sx={tab.sx} {...(slotProps ? slotProps.TabPanel : {})}>
                  {tab.children}
                </TabPanel>
              ))}
            </TabContext>
          </CompanionContent>
        </>
      ) : (
        <CompanionContent sx={{ padding: "0px 24px" }} {...(slotProps ? slotProps.CompanionContent : {})}>
          {tabs[0]?.children}
        </CompanionContent>
      )}
    </>
  );
}
