import { FileSourceType, ShapeTag, ShapeTagProps } from "@bigpi/cookbook";
import { IFilePreviewShape, filePreviewShapeMigrations, filePreviewShapeProps } from "@bigpi/tl-schema";
import { Box, Skeleton, Typography } from "@mui/material";
import { HTMLContainer, TLPropsMigrations, TLShapeUtilCanBindOpts, useEditor, useValue } from "tldraw";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { BoxBaseUtil } from "BoardComponents/BaseShapes/BoxBaseUtil";
import { useIsInteracting } from "BoardComponents/Tools";
import { useShapeEvents } from "BoardComponents/useShapeEvents";
import { useFilePreviewData } from "Components/FilePreviewDialog/Hooks/useFilePreviewData";
import { usePreviewAuth } from "Hooks/usePreviewAuth";

// *********************************************
// Private constants
// *********************************************/
const DEFAULT_WIDTH = 816;
const DEFAULT_HEIGHT = 1056;

// *********************************************
// Shape Util
// *********************************************/
export class FilePreviewUtil extends BoxBaseUtil<IFilePreviewShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "filePreview";

  static props = filePreviewShapeProps;

  static migrations: TLPropsMigrations = filePreviewShapeMigrations;

  // *********************************************
  // Protected fields
  // *********************************************/
  /**
   * The minimum height of the shape.
   */
  protected minHeight = 192;

  /**
   * The minimum width of the shape.
   */
  protected minWidth = 192;

  /**
   * The maximum height of the shape.
   */
  protected maxHeight = Infinity;

  /**
   * The maximum width of the shape.
   */
  protected maxWidth = Infinity;

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: IFilePreviewShape) => false;

  /**
   * @inheritdoc
   */
  override canScroll = (shape: IFilePreviewShape) => {
    return shape.props.canScroll;
  };

  /**
   * @inheritdoc
   */
  override canResize = (shape: IFilePreviewShape) => true;

  /**
   * @inheritdoc
   */
  override canBind = (options: TLShapeUtilCanBindOpts<IFilePreviewShape>) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: IFilePreviewShape) => true;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): IFilePreviewShape["props"] {
    return {
      w: DEFAULT_WIDTH,
      h: DEFAULT_HEIGHT,
      scale: 1,
      canScroll: false,

      fileId: "",
      fileSourceType: FileSourceType.Library,
      fileName: "",
    };
  }

  /**
   * @inheritdoc
   */
  component(shape: IFilePreviewShape) {
    const {
      id,
      props: { fileId, fileSourceType },
    } = shape;

    const { t } = useTranslation();

    const tldrawEditor = useEditor();
    const isEditing = useValue("isEditing", () => tldrawEditor.getCurrentPageState().editingShapeId === id, [tldrawEditor, id]);
    const isInteracting = useIsInteracting(id);
    const [shapeTag, setShapeTag] = useState<ShapeTag>(ShapeTag.ReadOnly);

    // Get the file preview details
    const { loading, url } = useFilePreviewData(fileId, fileSourceType);

    // Call the preview endpoint to set a cookie there
    const { isAuthenticated, authFailed } = usePreviewAuth();

    const { handleInputPointerDown } = useShapeEvents(shape.id);

    return (
      <HTMLContainer
        id={id}
        style={{
          background: "#fff",
          border: `1px solid ${isEditing ? "transparent" : ShapeTagProps[shapeTag].color}`,
          display: "flex",
          pointerEvents: "all",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: "-1px",
            top: "-25px",
            height: "25px",
            padding: "2px 10px",
            backgroundColor: ShapeTagProps[shapeTag].color,
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            color: "#333",
          }}
        >
          <Typography variant="caption">{t(`Global.ShapeTag.${shapeTag}`)}</Typography>
        </Box>

        <div
          style={{
            display: "flex",
            flex: 1,
            overflow: isEditing || isInteracting ? "hidden auto" : "hidden",
            pointerEvents: isEditing || isInteracting ? "auto" : "none",
          }}
        >
          {url && isAuthenticated ? (
            <div style={{ display: "flex", flex: 1, position: "relative", width: "100%", height: "100%" }}>
              {/* This fixes issue when mouse stops resizing over iframe */}
              <div className="draggable-iframe-cover" />
              <iframe
                src={url}
                style={{ border: "unset", flex: 1 }}
                onPointerDown={handleInputPointerDown}
                scrolling={isEditing || isInteracting ? "auto" : "no"}
              />
            </div>
          ) : authFailed ? (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "top",
                color: "error.main",
                mt: 2,
                fontSize: "16px",
              }}
            >
              {t("Components.FilePreviewShape.AuthError")}
            </Box>
          ) : isAuthenticated && !url && !loading ? (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "error.main",
                fontSize: "16px",
              }}
            >
              {t("Components.FilePreviewShape.NotAvailable")}
            </Box>
          ) : (
            <Box sx={{ flex: 1 }}>
              <Skeleton variant="rectangular" width="8.5in" height="100%" sx={{ margin: "auto" }} />
            </Box>
          )}
        </div>
      </HTMLContainer>
    );
  }
}
