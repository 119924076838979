import { DefaultStylePanel } from "tldraw";
import { useLocation } from "react-router-dom";

import { useIsChatEnabled } from "Chat/Hooks";
import { useWorkspacesPageUserPreferencesQuery } from "GraphQL/Generated/Apollo";

/**
 * Overriding StylePanel only for adding margin-right
 *
 * @param props CustomStylePanel related props
 * @returns
 */
export function CustomStylePanel(props: any) {
  const isChatEnabled = useIsChatEnabled();
  const location = useLocation();
  const { data: sidebarPreferences } = useWorkspacesPageUserPreferencesQuery({
    variables: {
      key: location.pathname,
    },
  });

  return (
    <div style={isChatEnabled && !sidebarPreferences?.userPreference?.data?.open ? { marginRight: "55px" } : {}}>
      <DefaultStylePanel {...props} />
    </div>
  );
}
