import { useAuthUser } from "@frontegg/react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ChatMessageChannelType, useBulkDeleteChatMessageMutation } from "GraphQL/Generated/Apollo";

interface IClearChatMessageDialogProps {
  channelType: ChatMessageChannelType;
  channelId: string;
  onClose: () => void;
  selectedFilter: string;
}

export function ClearChatMessageDialog(props: IClearChatMessageDialogProps) {
  const { channelId, channelType, onClose, selectedFilter } = props;
  const { t } = useTranslation();
  const user = useAuthUser();

  // Get the hook to bulk delete chat message
  const [bulkDeleteChatMessage] = useBulkDeleteChatMessageMutation();

  const onClearChatMessages = useCallback(() => {
    bulkDeleteChatMessage({
      variables: {
        input: {
          channelType,
          channelId: channelId,
          privateRecipientIds: selectedFilter ? [selectedFilter] : undefined,
          mentionTargetIds: selectedFilter ? [selectedFilter] : undefined,
          createdBy: selectedFilter === user.id ? [selectedFilter] : undefined,
        },
      },
      refetchQueries: ["ChatMessages"],
    });
    onClose();
  }, [bulkDeleteChatMessage, channelId, channelType, onClose, selectedFilter, user]);

  return (
    <Dialog open={true}>
      <DialogTitle>{t("Components.Chat.MessageFilterPanel.ClearChatTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("Components.Chat.MessageFilterPanel.ClearConfirmation")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Global.Action.Cancel")}</Button>
        <Button onClick={onClearChatMessages} color="danger">
          {t("Global.Action.Clear")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
