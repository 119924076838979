import { getSchema } from "@tiptap/core";
import { generateHTML as generateHtml, generateJSON as generateJson } from "@tiptap/html";
import { prosemirrorJSONToYDoc, yDocToProsemirrorJSON } from "y-prosemirror";
import * as Y from "yjs";

import * as DocumentConstants from "../DocumentEditor/DocumentConstants.js";
// NOTE: This needs a direct import of EditorExtensions.ts, not the index.ts file. Otherwise it may break consumers that can't process CSS files from toolbar items.
import { getCommonEditorExtensions } from "../Extensions/EditorExtensions.js";

/**
 * Creates a clone of the given Y.XmlElement or Y.XmlText.
 *
 * WARNING! This method may modify the original Y.XmlElement by converting all attribute values to strings.
 * This is necessary because Y.XmlElement does/should not support non-string attribute values, but we get them from y-prosemirror (mainly numbers).
 *
 * @param element The Y.XmlElement or Y.XmlText to clone.
 *
 * @returns A clone of the given element.
 */
export function cloneYXmlElement(element: Y.XmlElement | Y.XmlText) {
  // Recursively stringify all attributes
  // NOTE: Disabled. See below
  // stringifyYXmlElementAttributes(element);

  // Unpatched `.clone` will drop any attributes that do not have string values
  return element.clone();
}

/**
 * NOTE: DISABLED: This method CANNOT be used at this time as it can cause the client to hang (primarily with tables)
 *
 * Attempts to recursively convert all attribute values of the given Y.XmlElement or Y.XmlText to strings.
 *
 * WARNING! This method may modify the original Y.XmlElement by converting all attribute values to strings.
 * This is necessary because Y.XmlElement does/should not support non-string attribute values, but we get them from y-prosemirror (mainly numbers).
 *
 * @param element The parent element.
 */
export function stringifyYXmlElementAttributes(element: Y.XmlElement | Y.XmlText) {
  const attributes = element.getAttributes();

  for (const key in attributes) {
    const value = attributes[key];
    // NOTE: The types state that we should only have string values, but that's not the case when we get nodes from y-prosemirror
    if (value && typeof value !== "string") {
      element.setAttribute(key, value + "");
    }
  }

  // Loop any children and make sure they have string attribute values
  if (element instanceof Y.XmlElement) {
    element.forEach((child) => {
      stringifyYXmlElementAttributes(child);
    });
  }
}

export function documentHtmlToJson(html: string): Record<string, any> {
  return generateJson(html, getCommonEditorExtensions());
}

export function documentHtmlToYDoc(html: string, documentField = DocumentConstants.DOCUMENT_YDOC_FIELD_KEY): Y.Doc {
  return prosemirrorJSONToYDoc(getSchema(getCommonEditorExtensions()), documentHtmlToJson(html), documentField);
}

export function yDocToDocumentHtml(yDoc: Y.Doc): string {
  return yDocKeyToDocumentHtml(yDoc, DocumentConstants.DOCUMENT_YDOC_FIELD_KEY);
}

export function yDocKeyToDocumentHtml(yDoc: Y.Doc, key: string): string {
  return generateHtml(yDocToProsemirrorJSON(yDoc, key), getCommonEditorExtensions());
}
