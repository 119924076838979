import { Box } from "@mui/system";

import { useRightSidebarSx } from "Hooks/useAppRightSidebarComponents";
import { PropsWithChildren } from "react";

/***********************************
 * Interfaces
 ***********************************/
export interface IRightSidebarHeaderProps extends PropsWithChildren {}

/***********************************
 * Component
 ***********************************/
export function RightSidebarHeader(props: IRightSidebarHeaderProps) {
  const { children } = props;

  const rightSidebarSx = useRightSidebarSx();

  return <Box sx={{ display: "flex", flexDirection: "row", ...rightSidebarSx }}>{children}</Box>;
}
