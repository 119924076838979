import { TAGS_FILTER_UNTAGGED_OPTION_KEY } from "@bigpi/cookbook";
import { FilterListOutlined } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { TagsSelectFilterButton } from "Components/TagsSelectFilterButton/TagsSelectFilterButton";

export interface ITagsProps {
  disabled?: boolean;
  handleTagSelection: (event: SelectChangeEvent<string>) => void;
  onDeselectAllTags: () => void;
  onSelectAllTags: () => void;
  tags: Array<string>;
  tagsFilterValue: Array<string>;
}

export function Tags(props: ITagsProps) {
  const { handleTagSelection, onDeselectAllTags, onSelectAllTags, tags, tagsFilterValue, disabled } = props;
  const { t } = useTranslation();

  return (
    <>
      <FilterListOutlined />
      <FormControl sx={{ width: "180px", ml: 2 }} size="small">
        <InputLabel id="tags-label" shrink>
          {t("Components.Tags.Label")}
        </InputLabel>
        <Select
          labelId="tags-label"
          id="tags"
          multiple
          value={tagsFilterValue as any}
          onChange={handleTagSelection}
          input={<OutlinedInput label={t("Components.Tags.Label")} />}
          renderValue={(selected) => {
            const selectedItemsLength = (selected as unknown as Array<string>).length;
            const totalItemsLength = tags.length + 1;

            if (selectedItemsLength === totalItemsLength) {
              return t("Components.Tags.All");
            } else if (selectedItemsLength === 0) {
              return t("Components.Tags.None");
            }
            return t("Components.Tags.Some", {
              selectedCount: selectedItemsLength,
              totalCount: totalItemsLength,
            });
          }}
          displayEmpty
          notched
          disabled={disabled}
        >
          <div>
            {tagsFilterValue.length === tags.length + 1 ? (
              <TagsSelectFilterButton onClick={onDeselectAllTags}>{t("Components.Tags.DeselectAll")}</TagsSelectFilterButton>
            ) : (
              <TagsSelectFilterButton onClick={onSelectAllTags}>{t("Components.Tags.SelectAll")}</TagsSelectFilterButton>
            )}
          </div>
          <MenuItem key={TAGS_FILTER_UNTAGGED_OPTION_KEY} value={TAGS_FILTER_UNTAGGED_OPTION_KEY}>
            <Checkbox checked={tagsFilterValue.indexOf(TAGS_FILTER_UNTAGGED_OPTION_KEY) > -1} />
            <ListItemText primary={t("Components.Tags.Untagged")} />
          </MenuItem>
          {tags.map((tag) => (
            <MenuItem key={tag} value={tag}>
              <Checkbox checked={tagsFilterValue.indexOf(tag) > -1} />
              <ListItemText primary={tag} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
