import { ItemGridSize, ItemViewType, TAGS_FILTER_UNTAGGED_OPTION_KEY } from "@bigpi/cookbook";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Add, GridViewOutlined, SearchOutlined, ViewListOutlined } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

import { CommandContext } from "CommandContext";
import { Tags } from "Components/Tags/Tags";
import {
  useUpsertWorkspacesPageUserPreferencesMutation,
  useWorkspaceTagsQuery,
  useWorkspacesPageUserPreferencesQuery,
} from "GraphQL/Generated/Apollo";
import { WORKSPACES_PAGE_USER_PREFERENCES_KEY } from "GraphQL/UserPreference";
import { useDefaultPagePreferences } from "Hooks/useDefaultPagePreferences";
import {
  getGridSizeFromUserPreferences,
  getSelectedWorkspaceTagsFromUserPreferences,
  getViewTypeFromUserPreferences,
} from "Utils/UserPreferencesUtils";
import { NewWorkspaceDialog } from "./NewWorkspaceDialog";
import { WorkspaceView } from "./WorkspaceView";

const SEARCH_VALUE_DEBOUNCE_TIME = 500;

interface IWorkspacesPageUserPreferences {
  gridSize: ItemGridSize;
  viewType: ItemViewType;
  selectedTags: Array<string>;
}

export function WorkspacesPage() {
  const { t } = useTranslation();

  const defaultPagePreferences = useDefaultPagePreferences("workspaces");
  const { data: persistedPreferences } = useWorkspacesPageUserPreferencesQuery({
    variables: {
      key: WORKSPACES_PAGE_USER_PREFERENCES_KEY,
    },
  });
  const [upsertUserPreference] = useUpsertWorkspacesPageUserPreferencesMutation({
    // Update cache directly since the server will not return the fully cascaded data, just the user portion
    // This ensures any default and org-level preferences are not lost
    update: (cache, data) => {
      cache.modify({
        fields: {
          userPreference(existing = {}) {
            return {
              ...existing,
              data: {
                ...existing.data,
                ...data.data?.upsertUserPreference.data,
              },
            };
          },
        },
      });
    },
  });

  const { data: workspaceTagsData } = useWorkspaceTagsQuery();
  const tags = workspaceTagsData?.workspaceTags || [];

  const [preferences, setPreferences] = useState<IWorkspacesPageUserPreferences>({
    gridSize: ItemGridSize.Medium,
    viewType: ItemViewType.Grid,
    selectedTags: [TAGS_FILTER_UNTAGGED_OPTION_KEY],
  });
  const [newDialogOpen, setNewDialogOpen] = useState(false);
  const [totalWorkspaceCount, setTotalWorkspaceCount] = useState(0);
  const [currentWorkspaceCount, setCurrentWorkspaceCount] = useState(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedSearchValue] = useDebounce(searchValue, SEARCH_VALUE_DEBOUNCE_TIME);

  const tagsFilterValue = useMemo(() => preferences.selectedTags || [], [preferences.selectedTags]);

  // Set current application session context
  useEffect(() => {
    CommandContext.replaceSessionContext([]);
  }, []);

  useEffect(() => {
    const gridSize = getGridSizeFromUserPreferences(persistedPreferences, defaultPagePreferences, ItemGridSize.Medium);
    const viewType = getViewTypeFromUserPreferences(persistedPreferences, defaultPagePreferences, ItemViewType.Grid);
    const selectedTags = getSelectedWorkspaceTagsFromUserPreferences(
      persistedPreferences,
      defaultPagePreferences,
      workspaceTagsData,
    );

    // The preferences have changed, update the state
    setPreferences({
      gridSize,
      viewType,
      selectedTags,
    });
  }, [defaultPagePreferences, persistedPreferences, workspaceTagsData?.workspaceTags]);

  // Persists the user preferences. NOTE: This upserts with a patch, so we don't need to send all the preferences
  const updatePreferences = useCallback(
    (preferences: Partial<IWorkspacesPageUserPreferences>) => {
      setPreferences((current) => {
        return { ...current, ...preferences };
      });
      upsertUserPreference({
        variables: {
          input: {
            key: WORKSPACES_PAGE_USER_PREFERENCES_KEY,
            data: preferences,
          },
        },
      });
    },
    [upsertUserPreference],
  );

  const handleTagSelection = useCallback(
    (event: SelectChangeEvent<string>) => {
      const {
        target: { value },
      } = event;
      updatePreferences({ selectedTags: typeof value === "string" ? value.split(",") : value });
    },
    [updatePreferences],
  );

  return (
    <>
      <Helmet>
        <title>{t("Pages.Workspaces.Title")}</title>
      </Helmet>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 3,
          mb: 0,
          alignSelf: "center",
          width: "90%",
          maxWidth: preferences.viewType === ItemViewType.List ? "960px" : "1920px",
          minWidth: "400px",
        }}
      >
        <Typography variant="h5">{t("Pages.Workspaces.HeadingLabel")}</Typography>

        <Box>
          <Button startIcon={<Add />} variant="contained" sx={{ width: "180px" }} onClick={() => setNewDialogOpen(true)}>
            {t("Components.WorkspaceSidebar.NewWorkspace")}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          m: 3,
          alignSelf: "center",
          flexDirection: "column",
          width: "90%",
          maxWidth: preferences.viewType === ItemViewType.List ? "960px" : "1920px",
          minWidth: "400px",
          border: `1px solid #f1f1f1`,
          borderRadius: "16px",
          mb: 10,
        }}
      >
        <Box sx={{ display: "flex", p: 3, pb: 0, justifyContent: "center" }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="find-workspace-input">{t("Pages.Workspaces.FindLabel")}</InputLabel>
            <OutlinedInput
              id="find-workspace-input"
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              }
              label={t("Pages.Workspaces.FindLabel")}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", p: 3, pb: 0, justifyContent: "space-between", flexWrap: "wrap" }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2, mr: 2 }}>
            {tags && tags.length > 0 && (
              <Tags
                handleTagSelection={handleTagSelection}
                onDeselectAllTags={() => updatePreferences({ selectedTags: [] })}
                onSelectAllTags={() => updatePreferences({ selectedTags: tags.concat(TAGS_FILTER_UNTAGGED_OPTION_KEY) })}
                tags={tags}
                tagsFilterValue={tagsFilterValue}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <IconButton
              color={preferences.viewType === ItemViewType.List ? "primary" : "default"}
              size="medium"
              onClick={() => updatePreferences({ viewType: ItemViewType.List })}
            >
              <ViewListOutlined />
            </IconButton>

            <IconButton
              color={preferences.viewType === ItemViewType.Grid ? "primary" : "default"}
              size="medium"
              onClick={() => updatePreferences({ viewType: ItemViewType.Grid })}
            >
              <GridViewOutlined />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", px: 3 }}>
          <Typography variant="body2">
            {t("Pages.Workspaces.Label", { currentCount: currentWorkspaceCount, totalCount: totalWorkspaceCount })}
          </Typography>
        </Box>

        <WorkspaceView
          filterTags={tagsFilterValue.filter((tag) => tag !== TAGS_FILTER_UNTAGGED_OPTION_KEY)}
          gridSize={preferences.gridSize}
          includeUntagged={tagsFilterValue.indexOf(TAGS_FILTER_UNTAGGED_OPTION_KEY) > -1}
          onGridSizeChange={(gridSize) => updatePreferences({ gridSize })}
          setCurrentWorkspaceCount={setCurrentWorkspaceCount}
          setTotalWorkspaceCount={setTotalWorkspaceCount}
          viewType={preferences.viewType}
          searchValue={debouncedSearchValue}
        />
      </Box>

      {newDialogOpen && <NewWorkspaceDialog open={newDialogOpen} onClose={() => setNewDialogOpen(false)} />}
    </>
  );
}
