import { DocumentType } from "@bigpi/cookbook";
import { Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TLShapeId } from "tldraw";

import { QuestionnaireCompanion } from "./QuestionnaireCompanion";

export interface IHtmlDocumentShapeCompanion {
  documentType: DocumentType;
  scrollDocumentToBlockId: (blockId: string) => void;
  shapeId: TLShapeId;
  title: string | React.ReactNode;
  workspaceId: string;
  workspaceBoardId: string;
}

export function HtmlDocumentShapeCompanion(props: IHtmlDocumentShapeCompanion) {
  const { documentType, scrollDocumentToBlockId, shapeId, title, workspaceId, workspaceBoardId } = props;

  const { t } = useTranslation();

  return (
    <>
      {documentType === DocumentType.Questionnaire ? (
        <QuestionnaireCompanion
          scrollDocumentToBlockId={scrollDocumentToBlockId}
          shapeId={shapeId}
          title={title}
          workspaceId={workspaceId}
          workspaceBoardId={workspaceBoardId}
        />
      ) : (
        <Typography variant="body1" sx={{ padding: "20px 24px 0" }}>
          {t("Components.HtmlDocumentCompanion.NoCompanionContent")}
        </Typography>
      )}
    </>
  );
}
