import { DocumentType, documentTypeToShapeTag, ShapeTagProps } from "@bigpi/cookbook";
import ArticleIcon from "@mui/icons-material/Article";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { SvgIconProps } from "@mui/material";
import { CSSProperties } from "react";

export interface IShapeIconProps extends SvgIconProps {
  documentType?: DocumentType | null;
  shapeType: string;
}

export const ShapeIcon = (props: IShapeIconProps) => {
  const { documentType, shapeType, ...rest } = props;

  let iconColor: string | undefined;
  if (documentType) {
    const shapeTag = documentTypeToShapeTag(documentType as unknown as DocumentType);

    if (shapeTag) {
      const shapeTagProps = ShapeTagProps[shapeTag];
      iconColor = shapeTagProps.color;
    }
  }

  const commonStyle: CSSProperties | undefined = iconColor
    ? {
        color: iconColor,
      }
    : undefined;

  if (shapeType === "htmlDocument") {
    return <ArticleIcon fontSize="small" style={commonStyle} {...rest} />;
  } else if (shapeType === "filePreview") {
    return <ArticleOutlinedIcon fontSize="small" style={commonStyle} {...rest} />;
  }

  return <CircleOutlinedIcon fontSize="small" style={commonStyle} {...rest} />;
};
