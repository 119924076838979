import { TopicDiscussionAnalysisRecordTypes } from "@bigpi/cookbook";
import { createShapePropsMigrationIds, createShapePropsMigrationSequence } from "@tldraw/tlschema";

import { ITopicDiscussionAnalysisShape } from "./ITopicDiscussionAnalysisShape.js";

export const TopicDiscussionAnalysisShapeVersions = createShapePropsMigrationIds("topicDiscussionAnalysis", {
  Initial: 0,
  AddAnalysisTypeField: 1,
  AddToolbarField: 2,
  AddEnableBackgroundField: 3,
  AddPreferencesField: 4,
  RemoveDataGridPreferencesColumnVisibility: 5,
  AddPreferencesFacetSortModel: 6,
  AddStandardisedFacetsAndBounds: 7,
});

export const topicDiscussionAnalysisShapeMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: TopicDiscussionAnalysisShapeVersions.AddAnalysisTypeField,
      up(props) {
        props.analysisType = TopicDiscussionAnalysisRecordTypes.Transcript;
      },
      down(props) {
        delete props.analysisType;
      },
    },
    {
      id: TopicDiscussionAnalysisShapeVersions.AddToolbarField,
      up(props) {
        props.toolbar = {};
      },
      down(props) {
        delete props.toolbar;
      },
    },
    {
      id: TopicDiscussionAnalysisShapeVersions.AddEnableBackgroundField,
      up(props) {
        props.enableBackground = false;
      },
      down(props) {
        delete props.enableBackground;
      },
    },
    {
      id: TopicDiscussionAnalysisShapeVersions.AddPreferencesField,
      up(props) {
        props.preferences = {};
      },
      down(props) {
        delete props.preferences;
      },
    },
    {
      id: TopicDiscussionAnalysisShapeVersions.RemoveDataGridPreferencesColumnVisibility,
      up(props) {
        if (props.preferences?.dataGrid) {
          delete props.preferences.dataGrid.columnVisibility;
        }
      },
      down(props) {
        if (props.preferences?.dataGrid) {
          props.preferences.dataGrid.columnVisibility = {};
        }
      },
    },
    {
      id: TopicDiscussionAnalysisShapeVersions.AddPreferencesFacetSortModel,
      up(props) {
        if (!props.preferences) {
          props.preferences = {};
        }
        if (!props.preferences.analysis) {
          props.preferences.analysis = {};
        }
        props.preferences.analysis.facetSort = [];
      },
      down(props) {
        if (props.preferences?.analysis && props.preferences.analysis.facetSort) {
          delete props.preferences.analysis.facetSort;
        }
      },
    },
    {
      id: TopicDiscussionAnalysisShapeVersions.AddStandardisedFacetsAndBounds,
      up(props) {
        const { analysisType, facets, toolbar, preferences, ...rest } = props;

        let selectedFacetValues: ITopicDiscussionAnalysisShape["props"]["selectedFacetValues"] = {};
        let boundsFacetValues: ITopicDiscussionAnalysisShape["props"]["boundsFacetValues"] = {};
        let selectedBoundsFields;
        let selection: ITopicDiscussionAnalysisShape["props"]["selection"] = {};
        let toolbarProps = {};
        let preferencesProps = { ...(preferences || {}) };

        // Transforms facets to latest
        if (facets) {
          if (facets.selectedCategories) {
            selectedFacetValues.category = facets.selectedCategories;
          }

          if (facets.selectedDate) {
            selectedFacetValues.eventDate = facets.selectedDate;
          }

          if (facets.selectedSections) {
            selectedFacetValues.section = facets.selectedSections;
          }

          if (facets.selectedTickers) {
            selectedFacetValues.ticker = facets.selectedTickers;
          }

          if (facets.selectedTopics) {
            selectedFacetValues.topic = facets.selectedTopics;
          }

          if (facets.expandedGroups) {
            selection.expandedGroups = facets.expandedGroups;
          }

          if (facets.selectedGroup) {
            selection.selectedGroup = facets.selectedGroup;
          }

          if (facets.selectedItem) {
            selection.selectedItem = facets.selectedItem;
          }

          if (facets.selectedXAxis) {
            selection.selectedXAxis = facets.selectedXAxis;
          }
        }

        if (analysisType) {
          boundsFacetValues.type = [analysisType];
          selectedBoundsFields = ["type"];
        }

        if (facets && facets.includeItems !== undefined) {
          preferencesProps.analysis = {
            ...(preferencesProps.analysis || {}),
            showQuestions: facets.includeItems,
          };
        }

        if (toolbar && toolbar.filterFields && toolbar.filterFields.length > 0) {
          toolbarProps = {
            availableFields: toolbar.filterFields,
          };
        } else {
          toolbarProps = {
            availableFields: ["eventDate", "category", "ticker", "topic", "section"],
          };
        }

        props.selectedFacetValues = selectedFacetValues;
        props.boundsFacetValues = boundsFacetValues;
        props.selectedBoundsFields = selectedBoundsFields;
        props.selection = selection;
        props.toolbar = toolbarProps;
        props.preferences = preferencesProps;

        if (props.analysisType) {
          delete props.analysisType;
        }

        if (props.facets) {
          delete props.facets;
        }
      },
      down(props) {
        const { preferences, boundsFacetValues, selectedFacetValues, toolbar, selection } = props;

        let facets: Record<string, any> = {};
        let toolbarProps = {};
        let analysisType = "";
        // Reverse the latest selectedFacetValues to old facets
        if (selectedFacetValues) {
          if (selectedFacetValues.category) {
            facets.selectedCategories = selectedFacetValues.category;
          }

          if (selectedFacetValues.eventDate) {
            facets.selectedDate = selectedFacetValues.eventDate;
          }

          if (selectedFacetValues.section) {
            facets.selectedSections = selectedFacetValues.section;
          }

          if (selectedFacetValues.ticker) {
            facets.selectedTickers = selectedFacetValues.ticker;
          }

          if (selectedFacetValues.topic) {
            facets.selectedTopics = selectedFacetValues.topic;
          }
        }

        if (selection) {
          if (selection.expandedGroups) {
            facets.expandedGroups = selection.expandedGroups;
          }

          if (selection.selectedGroup) {
            facets.selectedGroup = selection.selectedGroup;
          }

          if (selection.selectedItem) {
            facets.selectedItem = selection.selectedItem;
          }

          if (selection.selectedXAxis) {
            facets.selectedXAxis = selection.selectedXAxis;
          }
        }

        if (preferences && preferences.analysis && preferences.analysis.showQuestions !== undefined) {
          facets = {
            ...facets,
            includeItems: preferences.analysis.showQuestions,
          };

          delete preferences.analysis.showQuestions;
        }

        if (preferences && preferences.analysis && preferences.analysis.startColor !== undefined) {
          delete preferences.analysis.startColor;
        }

        if (preferences && preferences.analysis && preferences.analysis.showDataToDisplayInToolbar !== undefined) {
          delete preferences.analysis.showDataToDisplayInToolbar;
        }

        if (toolbar && toolbar.availableFields) {
          toolbarProps = {
            filterFields: toolbar.availableFields,
          };
        }
        // Converts bounds facet values to type
        if (boundsFacetValues && boundsFacetValues.type) {
          analysisType = boundsFacetValues.type[0];
        }

        if (props.boundsFacetValues) {
          delete props.boundsFacetValues;
        }
        if (props.selectedBoundsFields) {
          delete props.selectedBoundsFields;
        }
        if (props.selectedFacetValues) {
          delete props.selectedFacetValues;
        }
        if (props.selection) {
          delete props.selection;
        }

        props.analysisType = analysisType;
        props.facets = facets;
        props.toolbar = toolbarProps;
        props.preferences = preferences;
      },
    },
  ],
});
