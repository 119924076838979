import { Constants } from "@bigpi/permission";
import { useAuthUser } from "@frontegg/react";
import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChatMessageChannelType } from "GraphQL/Generated/Apollo";
import { ClearChatMessageDialog } from "./ClearChatMessageDialog";

interface IChatMessageFilterPanelProps {
  channelType: ChatMessageChannelType;
  channelId: string;
  onChange: (filter: string) => void;
  selectedFilter: string;
}

enum ChatMessageFilterByUser {
  All = "All",
  Ai = "Ai",
  Mine = "Mine",
}

const filterList = [ChatMessageFilterByUser.All, ChatMessageFilterByUser.Ai, ChatMessageFilterByUser.Mine];

export function ChatMessageFilterPanel(props: IChatMessageFilterPanelProps) {
  const { channelId, channelType, onChange, selectedFilter } = props;
  const [showClearDialog, setShowClearDialog] = useState(false);
  const { t } = useTranslation();
  const user = useAuthUser();

  const onShowClearDialog = useCallback(() => {
    setShowClearDialog(true);
  }, []);

  const onCloseClearDialog = useCallback(() => {
    setShowClearDialog(false);
  }, []);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <ToggleButtonGroup exclusive value={selectedFilter || ""} onChange={(e, filter) => onChange(filter)} color="primary">
        {filterList.map((filter, index) => {
          return (
            <ToggleButton value={getValue(filter)} key={index} size="small">
              {t(`Components.Chat.MessageFilterPanel.${filter}`)}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      {selectedFilter === Constants.AI_USER_ID && (
        <Button onClick={onShowClearDialog} variant="outlined" size="small">
          {t("Global.Action.Clear")}
        </Button>
      )}
      {showClearDialog && (
        <ClearChatMessageDialog
          channelId={channelId}
          channelType={channelType}
          onClose={onCloseClearDialog}
          selectedFilter={selectedFilter}
        />
      )}
    </Stack>
  );

  /**
   * Get the UUID value for the filter.
   *
   * @param filter Filter to get value for.
   *
   * @returns UUID of the user for the filter.
   */
  function getValue(filter: string) {
    switch (filter) {
      case ChatMessageFilterByUser.Ai:
        return Constants.AI_USER_ID;
      case ChatMessageFilterByUser.Mine:
        return user.id;
      default:
        return "";
    }
  }
}
