import { DataGridColumnFormat, DataGridDescendentCountFormat, ShapeDatastoreType } from "@bigpi/cookbook";
import { createShapePropsMigrationIds, createShapePropsMigrationSequence } from "@tldraw/tlschema";

import { IDataGridPreferences } from "./IDataGridShape.js";

export const DataGridShapeVersions = createShapePropsMigrationIds("dataGrid", {
  PreferencesFilterModelFromInput: 1,
  RowGroupingModel: 2,
  MakeDataGridStandalone: 3,
  AddAutoResize: 4,
  AddWidthField: 5,
  AddPaginationMaxHeight: 6,
  AddDescendentCountFormat: 7,
  AddManageColumns: 8,
  CleanOldFeaturesAddNewFeatures: 9,
  AddIsEditEnabledField: 10,
});

export const dataGridShapeMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: DataGridShapeVersions.PreferencesFilterModelFromInput,
      up(props) {
        const preferences: IDataGridPreferences = {
          ...props.preferences,
        };
        if (props.preferences?.filterModel && props.preferences?.filterModel.items) {
          const items = props.preferences.filterModel.items?.map((item: any) => {
            return { ...item, fromInput: "" };
          });
          preferences["filterModel"] = {
            items,
          };
        }
        props.preferences = preferences;
      },
      down(props) {
        const { preferences, ...rest } = props;
        props = rest;
      },
    },
    {
      id: DataGridShapeVersions.RowGroupingModel,
      up(props) {
        props.preferences = { ...props.preferences, rowGroupingModel: undefined };
      },
      down(props) {
        const { preferences, ...rest } = props;
        props = rest;
      },
    },
    {
      id: DataGridShapeVersions.MakeDataGridStandalone,
      up(props) {
        props.config = {
          addToDocument: false,
          checkboxSelection: false,
          columns: [],
          documentItemTemplate: "",
          fontSize: 16,
        };
        props.datastoreId = undefined;
        props.datastoreType = ShapeDatastoreType.ParentDatastore;
        props.selectedIds = [];
      },
      down(props) {
        const { config, datastoreId, datastoreType, selectedIds, ...rest } = props;
        props = rest;
      },
    },
    {
      id: DataGridShapeVersions.AddAutoResize,
      up(props) {
        props.config.autoResize = true;
      },
      down(props) {
        const { autoResize, ...rest } = props.config;
        props.config = rest;
      },
    },
    {
      id: DataGridShapeVersions.AddWidthField,
      up(props) {
        props.config.columns.forEach((column: any) => {
          column.width = column.minWidth;
        });
      },
      down(props) {
        props.config.columns.forEach((column: any) => {
          delete column.width;
        });
      },
    },
    {
      id: DataGridShapeVersions.AddPaginationMaxHeight,
      up(props) {
        props.config.isPaginationEnabled = true;
      },
      down(props) {
        const { isPaginationEnabled, maxHeight, ...rest } = props.config;
        props.config = rest;
      },
    },
    {
      id: DataGridShapeVersions.AddDescendentCountFormat,
      up(props) {
        props.config.descendentCountFormat = DataGridDescendentCountFormat.RowCount;
      },
      down(props) {
        const { descendentCountFormat, ...rest } = props.config;
        props.config = rest;
      },
    },
    {
      id: DataGridShapeVersions.AddManageColumns,
      up(props) {
        props.config.isManageColumnsEnabled = false;
      },
      down(props) {
        const { isManageColumnsEnabled, ...rest } = props.config;
        props.config = rest;
      },
    },
    {
      id: DataGridShapeVersions.CleanOldFeaturesAddNewFeatures,
      up(props) {
        const { preferences } = props;
        const columnVisibility = preferences?.columnVisibility;
        props.config.columns.forEach((column: any) => {
          delete column.visibility;
          delete column.groupable;
          delete column.sortable;
          delete column.resizable;

          column.align = undefined;
          column.headerAlign = undefined;
          column.isColumnMenuEnabled = true;
          column.isColumnPinEnabled = true;
          column.isExportEnabled = true;
          column.isGroupEnabled = true;
          column.isHideEnabled = true;
          column.isReorderEnabled = true;
          column.isResizeEnabled = true;
          column.isSortEnabled = true;
          column.isVisible = columnVisibility ? ((columnVisibility[column.field] as boolean) ?? true) : true;
          delete column.maxWidth;
          column.showSortIcons = true;

          if (
            column.format === ("TOPIC_DISCUSSIONS_TOPICS" as DataGridColumnFormat) ||
            column.format === ("TOPIC_DISCUSSIONS_SUMMARY" as DataGridColumnFormat)
          ) {
            column.format = DataGridColumnFormat.BulletList;
          }
        });

        props.config.isExportCsvEnabled = false;
        props.config.isExportExcelEnabled = false;
        props.config.minHeight = props.config.maxHeight;

        delete props.preferences.columnVisibility;
      },
      down(props) {
        const { config, preferences } = props;
        const { columns, isExportCsvEnabled, isExportExcelEnabled, minHeight, ...rest } = config;
        const columnVisibility: Record<string, boolean> = {};

        columns.forEach((column: any) => {
          delete column.align;
          delete column.headerAlign;
          delete column.isColumnMenuEnabled;
          delete column.isColumnPinEnabled;
          delete column.isExportEnabled;
          delete column.isGroupEnabled;
          delete column.isHideEnabled;
          delete column.isReorderEnabled;
          delete column.isResizeEnabled;
          delete column.isSortEnabled;
          delete column.isVisible;
          delete column.maxWidth;
          delete column.showSortIcons;

          if (column.isVisible === false) {
            columnVisibility[column.field] = false;
          }

          if (column.format === DataGridColumnFormat.BulletList && column.field === "topics") {
            column.format = "TOPIC_DISCUSSIONS_TOPICS" as DataGridColumnFormat;
          }
          if (column.format === DataGridColumnFormat.BulletList && column.field === "summary") {
            column.format = "TOPIC_DISCUSSIONS_SUMMARY" as DataGridColumnFormat;
          }
        });

        const otherPreferences: { columnVisibility?: Record<string, boolean> } = {};

        if (Object.keys(otherPreferences).length > 0) {
          otherPreferences.columnVisibility = columnVisibility;
        }

        props.config = {
          ...rest,
          columns,
        };
        props.preferences = {
          ...preferences,
          ...otherPreferences,
        };
      },
    },
    {
      id: DataGridShapeVersions.AddIsEditEnabledField,
      up(props) {
        props.config.columns.forEach((column: any) => {
          column.isEditEnabled = false;
        });
      },
      down(props) {
        props.config.columns.forEach((column: any) => {
          delete column.isEditEnabled;
        });
      },
    },
  ],
});
