import { UnfoldMore, UnfoldLess } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import { SuggestionStatus } from "GraphQL/Generated/Apollo";

interface IExpandableSuggestionContentProps {
  content: string;
  status: SuggestionStatus;
}

const CONTENT_MAX_HEIGHT = 120;

export function ExpandableSuggestionContent(props: IExpandableSuggestionContentProps) {
  const { content, status } = props;
  const [isExpanded, setIsExpanded] = useState(status === SuggestionStatus.Accepted ? false : true);
  const [isExpandable, setIsExpandable] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleExpand = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    setIsExpanded(status === SuggestionStatus.Accepted ? false : true);
  }, [status]);

  useEffect(() => {
    if (contentRef.current) {
      const { scrollHeight } = contentRef.current;
      if (scrollHeight <= CONTENT_MAX_HEIGHT) {
        setIsExpandable(false);
      }
    }
  }, [content, contentRef]);

  return (
    <Box sx={{ position: "relative" }}>
      {isExpandable ? (
        <IconButton onClick={toggleExpand} sx={{ position: "absolute", right: 0, top: -10, transform: "rotate(45deg)" }}>
          {isExpanded ? <UnfoldLess /> : <UnfoldMore />}
        </IconButton>
      ) : null}
      <Box
        ref={contentRef}
        component="div"
        className="astra document"
        style={{
          maxHeight: isExpandable ? (isExpanded ? "none" : `${CONTENT_MAX_HEIGHT}px`) : "none",
          maxWidth: "8.5in",
          opacity: status === "Draft" ? 0.5 : 1,
          padding: "0 1in",
          maskImage: isExpandable ? (isExpanded ? "" : "linear-gradient(#000 60%, transparent)") : "",
        }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Box>
  );
}
