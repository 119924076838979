import { gql } from "@apollo/client";

export const CHAT_MESSAGES_ADDED_SUBSCRIPTION = gql`
  subscription OnChatMessagesAdded($channelId: ID!, $channelType: ChatMessageChannelType!, $filters: GetChatMessagesFilters!) {
    onChatMessageAdded(channelId: $channelId, channelType: $channelType, filters: $filters) {
      id

      channelId
      parentId
      messageType
      message
      privateRecipientIds

      attachments {
        mimeType
        name
        storageLocationUrl
      }
      mentions {
        targetId
        name
        mentionType
      }
      reactions {
        reaction
        userIds
      }

      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy

      childCount
    }
  }
`;

export const CHAT_MESSAGES_UPDATED_SUBSCRIPTION = gql`
  subscription OnChatMessagesUpdated($channelId: ID!, $channelType: ChatMessageChannelType!, $filters: GetChatMessagesFilters!) {
    onChatMessageUpdated(channelId: $channelId, channelType: $channelType, filters: $filters) {
      id

      channelId
      parentId
      messageType
      message
      privateRecipientIds

      attachments {
        mimeType
        name
        storageLocationUrl
      }
      mentions {
        targetId
        name
        mentionType
      }
      reactions {
        reaction
        userIds
      }

      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedAt
      deletedBy

      childCount
    }
  }
`;

export const CHAT_MESSAGES_DELETED_SUBSCRIPTION = gql`
  subscription OnChatMessagesDeleted($channelId: ID!, $channelType: ChatMessageChannelType!, $parentId: ID) {
    onChatMessageDeleted(channelId: $channelId, channelType: $channelType, parentId: $parentId) {
      id
    }
  }
`;
