import {
  COMMAND_EXTRACT_QUESTIONS_TEMPLATE,
  COMMAND_STATUS_RUNNING,
  ICommandRequest,
  ICommandResponseStatus,
} from "@bigpi/cookbook";
import UpdateIcon from "@mui/icons-material/Update";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CommandContext } from "CommandContext";
import { useCommandExecutor } from "Components/CommandManagers/useCommandExecutor";
import { WorkspaceBoardTableOfContents } from "Components/WorkspaceBoardTableOfContents/WorkspaceBoardTableOfContents";
import { COMMAND_STATUS_SUBSCRIPTION } from "GraphQL/CommandQueue";
import {
  CommandStatusQuery,
  OnCommandStatusChangedSubscription,
  OnCommandStatusChangedSubscriptionVariables,
  useCommandStatusLazyQuery,
} from "GraphQL/Generated/Apollo";

export interface IWorkspaceBoardShapeNavigationDetailsProps {
  onShapeBlockNavigation: (boardId: string, shapeId: string, blockId: string) => void;
  shapeId: string;
  workspaceBoardId: string;
  selectedBlockId?: string;
}

export const WorkspaceBoardShapeNavigationDetails = (props: IWorkspaceBoardShapeNavigationDetailsProps) => {
  const { onShapeBlockNavigation, shapeId, workspaceBoardId, selectedBlockId } = props;

  const [isGenerateTocRunning, setIsGenerateTocRunning] = useState<boolean>(false);

  const commandExecutor = useCommandExecutor();
  const { t } = useTranslation();

  const generateTocRequestId = `generate-toc-${shapeId}`;

  // Generate table of contents
  const generateToc = useCallback(async () => {
    setIsGenerateTocRunning(true);

    const command = {
      ...COMMAND_EXTRACT_QUESTIONS_TEMPLATE,
    };

    // Make sure the shape ID is in the context. It might not be when companion is still open, but shape is no longer selected
    const currentContext = CommandContext.getCommandContext();

    const commandRequest: Partial<ICommandRequest> = {
      commandContext: {
        ...currentContext,
        selection: {
          ...currentContext.selection,
          shapeIds: [shapeId],
        },
      },
      // Create deduplicationId
      deduplicationId: generateTocRequestId,
      requestId: generateTocRequestId,
    };

    await commandExecutor.executeCommand(command, commandRequest);
  }, [commandExecutor, generateTocRequestId, shapeId, workspaceBoardId]);

  // Lazy query to fetch initial command status
  const [fetchInitialCommandStatusToc, { data: commandStatusDataToc, subscribeToMore: subscribeToMoreToc }] =
    useCommandStatusLazyQuery();

  // Dynamically subscribe to command status changes for TOC
  useEffect(() => {
    fetchInitialCommandStatusToc({
      fetchPolicy: "network-only",
      variables: {
        id: generateTocRequestId,
      },
    });

    const unsubscribeGenerateToc = subscribeToMoreToc<
      OnCommandStatusChangedSubscription,
      OnCommandStatusChangedSubscriptionVariables
    >({
      document: COMMAND_STATUS_SUBSCRIPTION,
      variables: { id: generateTocRequestId },
      updateQuery: (prev, { subscriptionData, variables }) => {
        if (!subscriptionData.data || !variables) {
          return prev;
        }

        // If we don't have any data, it means the command has completed
        const status: ICommandResponseStatus = subscriptionData.data.onCommandStatusChanged?.status
          ? (subscriptionData.data.onCommandStatusChanged?.status as ICommandResponseStatus)
          : "success";
        const newItem: CommandStatusQuery = {
          __typename: "Query",
          commandStatus: {
            __typename: "CommandQueueStatus",
            // We're not using the commandId here, so we can leave it empty if needed
            commandId: prev.commandStatus?.commandId || "",
            requestId: variables.id,
            // Success might not be correct, but works to mark as completed
            status,
          },
        };
        return newItem;
      },
    });

    return () => {
      unsubscribeGenerateToc();
    };
  }, [fetchInitialCommandStatusToc, generateTocRequestId, subscribeToMoreToc]);

  // Handle subscription updated for TOC
  useEffect(() => {
    const status = commandStatusDataToc?.commandStatus?.status as ICommandResponseStatus | undefined;

    setIsGenerateTocRunning(status !== undefined && COMMAND_STATUS_RUNNING.includes(status as ICommandResponseStatus));
  }, [commandStatusDataToc]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", p: 2 }}>
        <Button variant="contained" disabled={isGenerateTocRunning} sx={{ maxHeight: "40px" }} onClick={generateToc}>
          <Box sx={{ m: 1, position: "relative" }}>
            <Stack alignItems="center" direction="row" gap={1}>
              <UpdateIcon />
              {t(`Components.WorkspaceBoardShapeNavigationDetails.Actions.Update`)}
            </Stack>
            {isGenerateTocRunning && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Button>
      </Box>

      <WorkspaceBoardTableOfContents
        workspaceBoardId={workspaceBoardId}
        shapeId={shapeId}
        scrollDocumentToBlockId={(blockId) => onShapeBlockNavigation(workspaceBoardId, shapeId, blockId)}
        selectedBlockId={selectedBlockId}
      />
    </>
  );
};
