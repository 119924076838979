import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip } from "@mui/material";
import { Box, useTheme } from "@mui/system";

import { LeftSidebarTitleComponent, useLeftSidebarSx } from "Hooks/useAppLeftSidebarComponents";

/***********************************
 * Interfaces
 ***********************************/
export interface ILeftSidebarHeaderProps {
  closeIconTooltip: string;
  onClose: () => void;
}

/***********************************
 * Component
 ***********************************/
export function LeftSidebarHeader(props: ILeftSidebarHeaderProps) {
  const { closeIconTooltip, onClose } = props;

  const theme = useTheme();
  const leftSidebarSx = useLeftSidebarSx();

  return (
    <Box
      sx={{
        height: "40px",
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: "flex",
        justifyContent: "space-between",
        ...leftSidebarSx,
      }}
    >
      <LeftSidebarTitleComponent />

      <Tooltip title={closeIconTooltip} placement="left-start">
        <IconButton onClick={onClose} disableRipple>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
