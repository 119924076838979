import { ICommand } from "@bigpi/cookbook";
import { SuggestionKeyDownProps, SuggestionProps } from "@bigpi/editor-tiptap";
import { Box, Grid, Typography, List, ListItem, useTheme } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useRef, forwardRef, useEffect, useImperativeHandle, useState, useCallback } from "react";

import { getOutputTypeIcon } from "Components/Commands/Utils";

export default forwardRef((props: SuggestionProps, ref) => {
  const { items, query } = props;
  const theme = useTheme();
  // State
  const [selectedIndex, setSelectedIndex] = useState(0);
  // Refs
  const listRef = useRef<HTMLUListElement>(null);

  const selectItem = useCallback(
    (index: number) => {
      const item = props.items[index];
      if (item) {
        if (item.name === "Search") {
          props.editor.commands.insertAiUserPrivateMention("find in workspace: ", props.range);
        } else {
          props.command({ id: item.name });
        }
      }
    },
    [props.items, props.command],
  );

  const upHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  }, [selectedIndex, props.items]);

  const downHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  }, [selectedIndex]);

  const enterHandler = useCallback(() => {
    selectItem(selectedIndex);
  }, [selectedIndex, selectItem]);

  useEffect(() => setSelectedIndex(0), [props.items]);

  useEffect(() => {
    if (listRef && listRef.current) {
      const selectedChild = listRef.current.children[selectedIndex];
      if (selectedChild) {
        selectedChild.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [selectedIndex, listRef]);

  useImperativeHandle(
    ref,
    () => ({
      onKeyDown: ({ event }: SuggestionKeyDownProps) => {
        if (event.key === "ArrowUp") {
          upHandler();
          return true;
        }

        if (event.key === "ArrowDown") {
          downHandler();
          return true;
        }

        if (event.key === "Enter") {
          enterHandler();
          return true;
        }

        return false;
      },
    }),
    [upHandler, downHandler, enterHandler],
  );

  return (
    <List
      sx={{
        backgroundColor: "#fff",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        maxHeight: "350px",
        overflowY: "scroll",
      }}
      ref={listRef}
    >
      {items.map((item: ICommand, index: number) => {
        const matches = match(item.name, query, { insideWords: true });
        const parts = parse(item.name, matches);
        return (
          <ListItem
            sx={{ backgroundColor: index === selectedIndex ? theme.palette.primary.light : "transparent", cursor: "pointer" }}
            onClick={() => selectItem(index)}
            key={index}
          >
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                {getOutputTypeIcon(item.outputTypes[0], { height: "15px" })}
              </Grid>
              <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                {parts.map((part, index) => (
                  <Box key={index} component="span" sx={{ fontWeight: part.highlight ? "bold" : "regular" }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
});
