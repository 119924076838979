import { DataGridActionCategory, DataGridActionType, IDataGridAction, IDataGridActions } from "@bigpi/cookbook";
import { IDataGridShape } from "@bigpi/tl-schema";
import { Editor, TLShapeId, useValue } from "tldraw";
import { useGridApiContext, GridPreferencePanelsValue } from "@mui/x-data-grid-premium";

import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IAnalysisShapeData, useBoardDatastore } from "BoardComponents/BoardDatastore";

/**
 * Gives data grid actions
 *
 * @param editor Tldraw editor instance
 * @param shapeId Shape id of the data grid
 * @returns
 */
export const useDataGridActions = (editor: Editor, shapeId: TLShapeId) => {
  const apiRef = useGridApiContext();
  const [actions, setActions] = useState<IDataGridActions>();
  const datastore = useBoardDatastore();
  const { t } = useTranslation();

  const onManageColumns = useCallback(() => {
    apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
  }, [apiRef]);

  const onExportCsv = useCallback(() => {
    apiRef.current.exportDataAsCsv();
  }, [apiRef]);

  const onExportExcel = useCallback(() => {
    apiRef.current.exportDataAsExcel();
  }, [apiRef]);

  const dataGridConfigActions = useValue(
    "dataGridActions.dataGridConfigActions",
    () => {
      const { props } = editor.getShape(shapeId) as IDataGridShape;
      const isManageColumnsEnabled = props?.config.isManageColumnsEnabled || false;
      const isExportCsvEnabled = props?.config.isExportCsvEnabled || false;
      const isExportExcelEnabled = props?.config.isExportExcelEnabled || false;

      return { isManageColumnsEnabled, isExportCsvEnabled, isExportExcelEnabled };
    },
    [datastore, editor, shapeId],
  );

  const parentDataGridActions: IDataGridActions = useValue(
    "dataGridActions.parentActions",
    () => {
      const { parentId } = editor.getShape(shapeId) as IDataGridShape;
      const parentShapeStore = datastore.state.get()[parentId]?.get() as IAnalysisShapeData<
        Array<Record<string, any>>,
        Record<string, any>,
        Record<string, any>,
        Record<string, any>
      >;
      return parentShapeStore?.dataGridActions?.get() || {};
    },
    [editor, shapeId, datastore],
  );

  // TODO: The built-in keys should be defined either as a type or as an enum
  const standardActions: IDataGridActions = useValue(
    "dataGridActions.standardActions",
    () => {
      const { isManageColumnsEnabled, isExportCsvEnabled, isExportExcelEnabled } = dataGridConfigActions;
      return {
        [DataGridActionCategory.Edit]: conditionalPush(
          [],
          [
            {
              key: "edit",
              title: t("Components.DataGrid.Actions.Edit"),
              onAction: () => {},
              type: DataGridActionType.Item,
            },
          ],
          [false],
        ),
        [DataGridActionCategory.Manage]: conditionalPush(
          [],
          [
            {
              key: "manageColumns",
              title: t("Components.DataGrid.Actions.ManageColumns"),
              onAction: onManageColumns,
              type: DataGridActionType.Item,
            },
          ],
          [isManageColumnsEnabled],
        ),
        [DataGridActionCategory.Export]: conditionalPush(
          [],
          [
            {
              key: "exportCsv",
              title: t("Components.DataGrid.Actions.ExportCsv"),
              onAction: onExportCsv,
              type: DataGridActionType.Item,
            },
            {
              key: "exportExcel",
              title: t("Components.DataGrid.Actions.ExportExcel"),
              onAction: onExportExcel,
              type: DataGridActionType.Item,
            },
          ],
          [isExportCsvEnabled, isExportExcelEnabled],
        ),
        [DataGridActionCategory.Custom]: [],
      };
    },
    [t, dataGridConfigActions, onManageColumns, onExportCsv, onExportExcel],
  );

  useEffect(() => {
    const actions: IDataGridActions = {};
    Object.keys(standardActions).forEach((categoryString) => {
      const category = categoryString as keyof typeof standardActions;
      const parentActions = parentDataGridActions[category] || [];
      actions[category] = [...(standardActions[category] || []), ...parentActions];
    });
    setActions(actions);
  }, [parentDataGridActions, standardActions]);

  return actions;
};

function conditionalPush(array: Array<IDataGridAction>, items: Array<IDataGridAction>, conditions: Array<boolean>) {
  items.forEach((item, index) => {
    if (conditions[index]) {
      array.push(item);
    }
  });
  return array;
}
