import { BoxModel } from "@tldraw/tlschema";

/**
 * Gets all coordinates of the shape.
 *
 * @param shape Tldraw shape.
 *
 * @returns Coordinates of the given shape.
 */
export function getShapeCoordinates(shape: { x: number; y: number; props: { h: number; w: number } }) {
  const { x, y, props } = shape;
  const { h, w } = props;
  return {
    topLeft: { x, y },
    topRight: { x: x + w, y },
    bottomRight: { x: x + w, y: y + h },
    bottomLeft: { x, y: y + h },
    center: { x: x + w / 2, y: y + h / 2 },
  };
}

/**
 * Checks if the shape is completely visible on the page.
 * The shape is considered completely visible if its top left corner is visible and its bottom right corner is visible.
 *
 *  pageTopLeft
 *       -------------------------------
 *       |  shapeTopLeft               |
 *       |      -------------          |
 *       |      |           |          |
 *       |      |           |          |
 *       |      -------------          |
 *       |           shapeBottomRight  |
 *       -------------------------------
 *                               pageBottomRight
 *
 * @param pageBounds Page bounds.
 * @param shapeBounds Shape bounds.
 *
 * @returns True if the shape is completely visible on the page, false otherwise.
 */
export function isShapeFullyWithinPageBounds(pageBounds: BoxModel, shapeBounds: BoxModel) {
  const shapeTopLeft = { x: shapeBounds.x, y: shapeBounds.y };
  const shapeBottomRight = { x: shapeBounds.x + shapeBounds.w, y: shapeBounds.y + shapeBounds.h };

  const pageTopLeft = { x: pageBounds.x, y: pageBounds.y };
  const pageBottomRight = { x: pageBounds.x + pageBounds.w, y: pageBounds.y + pageBounds.h };

  const isTopLeftVisible = pageTopLeft.x < shapeTopLeft.x && pageTopLeft.y < shapeTopLeft.y;
  const isBottomRightVisible = pageBottomRight.x > shapeBottomRight.x && pageBottomRight.y > shapeBottomRight.y;

  return isTopLeftVisible && isBottomRightVisible;
}
