import { Button, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import NavigationPanelIcon from "Assets/Icons/navigation-panel-icon.svg";
import { ResizePanel } from "Components/Resizer/ResizePanel";
import { useUpsertWorkspacesPageUserPreferencesMutation, useWorkspacesPageUserPreferencesQuery } from "GraphQL/Generated/Apollo";
import { WORKSPACE_BOARD_PAGE_USER_PREFERENCES_KEY } from "GraphQL/UserPreference";
import { LeftSidebarHeader } from "./LeftSidebarHeader";

/***********************************
 * Constants
 ***********************************/
export const APP_LEFT_SIDEBAR_ICON_HEIGHT = 25;
export const APP_LEFT_SIDEBAR_ICON_WIDTH = 25;
const DEFAULT_WIDTH = 490;
const MIN_WIDTH = 200;
const MAX_WIDTH = 720;

/***********************************
 * Types/Interface
 ***********************************/
export interface IAppLeftSidebarProps {
  children: React.ReactNode;
}

/***********************************
 * Component
 ***********************************/
export function AppLeftSidebar(props: IAppLeftSidebarProps) {
  const { children } = props;

  const theme = useTheme();
  const { t } = useTranslation();

  const { data: pagePreferences } = useWorkspacesPageUserPreferencesQuery({
    variables: {
      key: WORKSPACE_BOARD_PAGE_USER_PREFERENCES_KEY,
    },
  });
  const [upsertUserPreference] = useUpsertWorkspacesPageUserPreferencesMutation({
    // Update cache directly since the server will not return the fully cascaded data, just the user portion
    // This ensures any default and org-level preferences are not lost
    update: (cache, data) => {
      cache.modify({
        fields: {
          userPreference(existing = {}) {
            return {
              ...existing,
              data: {
                ...existing.data,
                ...data.data?.upsertUserPreference.data,
              },
            };
          },
        },
      });
    },
  });

  // Persists the user preferences. NOTE: This upserts with a patch, so we don't need to send all the preferences
  const updatePreferences = useCallback(
    (open: boolean, width: number) => {
      upsertUserPreference({
        variables: {
          input: {
            key: WORKSPACE_BOARD_PAGE_USER_PREFERENCES_KEY,
            data: {
              leftSidebarOpen: open,
              leftSidebarWidth: width,
            },
          },
        },
      });
    },
    [upsertUserPreference],
  );

  // State
  const [childrenWidth, setChildrenWidth] = useState<number>(
    pagePreferences?.userPreference?.data?.leftSidebarWidth ?? DEFAULT_WIDTH,
  );
  const [open, setOpen] = useState(pagePreferences?.userPreference?.data?.leftSidebarOpen ?? false);

  // Set top level class on the `body` element when the sidebar is collapsed
  // this can be used to adjust the layout of the main content area
  useEffect(() => {
    document.body.classList.toggle("left-sidebar-collapsed", !open);
  }, [open]);

  const debouncedSetChildrenWidth = useDebouncedCallback((width: number) => {
    setChildrenWidth(width);
    updatePreferences(open, width);
  }, 50);

  useEffect(() => {
    setOpen(pagePreferences?.userPreference?.data?.leftSidebarOpen ?? false);
    setChildrenWidth(pagePreferences?.userPreference?.data?.leftSidebarWidth ?? DEFAULT_WIDTH);
  }, [pagePreferences]);

  return (
    <Box sx={{ marginLeft: "50px" }}>
      {open && (
        <ResizePanel
          defaultWidth={DEFAULT_WIDTH}
          minWidth={MIN_WIDTH}
          maxWidth={MAX_WIDTH}
          width={childrenWidth}
          onWidthChange={debouncedSetChildrenWidth}
          anchor="left"
        >
          <Box
            sx={{ borderRight: `1px solid ${theme.palette.divider}`, display: "flex", flexDirection: "column", width: "100%" }}
            component="aside"
          >
            <LeftSidebarHeader onClose={onToggleSidebar} closeIconTooltip={t("Components.AppSidebar.Collapse")} />
            {children}
          </Box>
        </ResizePanel>
      )}
      {!open && (
        <Tooltip title={t("Components.AppSidebar.Expand")} placement="left-start">
          <Button
            onClick={onToggleSidebar}
            sx={{ position: "fixed", left: "50px", zIndex: "var(--layer-sidebar-toggle-icon)" }}
            focusRipple={false}
          >
            <img
              src={NavigationPanelIcon}
              height={APP_LEFT_SIDEBAR_ICON_HEIGHT}
              width={APP_LEFT_SIDEBAR_ICON_WIDTH}
              alt={t("Components.AppSidebar.Expand")}
              style={{ filter: "invert(0.4)" }}
            />
          </Button>
        </Tooltip>
      )}
    </Box>
  );

  function onToggleSidebar() {
    setOpen(!open);
    updatePreferences(!open, DEFAULT_WIDTH);
  }
}
