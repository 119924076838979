import { RecordProps } from "@tldraw/tlschema";
import { T } from "@tldraw/validate";

import { IHistogramChartShape } from "./IHistogramChartShape.js";

// *********************************************
// Private constants
// *********************************************/
/**
 * The default width of the shape.
 */
const DEFAULT_WIDTH = 1000;

/**
 * The default height of the shape.
 */
const DEFAULT_HEIGHT = 500;

// *********************************************
// Public constants
// *********************************************/
/**
 * Gets the default props for the shape.
 *
 * @returns The default props.
 */
export const getHistogramChartShapeDefaultProps = (): IHistogramChartShape["props"] => {
  return {
    w: DEFAULT_WIDTH,
    h: DEFAULT_HEIGHT,
    field: "",
  };
};

/**
 * The shape props schema definition.
 */
export const histogramChartShapeProps: RecordProps<IHistogramChartShape> = {
  h: T.nonZeroNumber,
  w: T.nonZeroNumber,
  field: T.string,
};
