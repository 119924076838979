import { RecordProps } from "@tldraw/tlschema";
import { T } from "@tldraw/validate";

import { IHeadlineShape } from "./IHeadlineShape.js";

// *********************************************
// Private constants
// *********************************************/
/**
 * The default width of the shape.
 */
export const DEFAULT_WIDTH = 816;

/**
 * The default height of the shape.
 */
export const DEFAULT_HEIGHT = 1056;

// *********************************************
// Public constants
// *********************************************/
/**
 * Gets the default props for the shape.
 *
 * @returns The default props.
 */
export const getHeadlineShapeDefaultProps = (): IHeadlineShape["props"] => {
  return {
    w: DEFAULT_WIDTH,
    h: DEFAULT_HEIGHT,
    canScroll: false,
    headlines: [],
    documentHtml: "",
    targetDocumentShapeId: "",
    likelihoodSentimentUnderThreshold: 0,
    sentimentThreshold: 0,
    scale: 1,
    status: "queued",
  };
};

/**
 * The shape props schema definition.
 */
export const headlineShapeProps: RecordProps<IHeadlineShape> = {
  h: T.nonZeroNumber,
  headlines: T.arrayOf(
    T.object({
      id: T.string,
      headline: T.string,
      sentiment: T.number,
      likelihood: T.number,
      bookmarkIds: T.arrayOf(T.string),
      results: T.arrayOf(
        T.object({
          id: T.string,
          headline: T.string,
          sentiment: T.number,
          likelihood: T.number,
          bookmarkIds: T.arrayOf(T.string),
        }),
      ).optional(),
    }),
  ),
  documentHtml: T.string,
  targetDocumentShapeId: T.string,
  likelihoodSentimentUnderThreshold: T.number,
  sentimentThreshold: T.number,
  scale: T.nonZeroNumber,
  status: T.setEnum(new Set(["queued", "running", "progress", "success", "failure"])),
  w: T.nonZeroNumber,

  canScroll: T.boolean,
};
