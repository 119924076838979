import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";
import { useCallback } from "react";

import { FieldType, IFieldDefinition, IOption } from "./FieldsConfig";

interface IFieldSelectionProps {
  field: IFieldDefinition;
  value: any;
  onChange: (key: string, value: any) => void;
}

export function FieldSelection(props: IFieldSelectionProps) {
  const { field, value, onChange } = props;
  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(field.key, e.target.value);
    },
    [onChange, field.key],
  );

  const handleSelectChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      onChange(field.key, e.target.value);
    },
    [onChange, field.key],
  );

  const handleDateChange = useCallback(
    (date: dayjs.Dayjs | null) => {
      onChange(field.key, date?.toISOString());
    },
    [onChange, field.key],
  );

  switch (field.type) {
    case FieldType.String:
      return <TextField value={value || ""} onChange={handleTextChange} size="small" />;
    case FieldType.ArrayOfStrings:
      return (
        <FormControl size="small">
          <Select value={value || field.defaultValue || []} onChange={handleSelectChange}>
            {(field.options as Array<IOption>).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case FieldType.Date:
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dayjs.utc(value || undefined)}
            onChange={handleDateChange}
            slotProps={{
              textField: {
                size: "small",
              },
            }}
          />
        </LocalizationProvider>
      );
    case FieldType.Number:
      return <TextField label={field.name} type="number" value={value || ""} onChange={handleTextChange} size="small" />;
    default:
      return null;
  }
}
