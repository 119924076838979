import { useApolloClient } from "@apollo/client";
import { FileUploadStatus } from "@bigpi/cookbook";
import { useCallback, useEffect, useState } from "react";

import { groupFiles, renameMetadataFiles, useUploadFilesBatch } from "Components/Upload/Hooks/useUploadFilesBatch";
import { useUploadWorkspaceFilesMutation } from "GraphQL/Generated/Apollo";
import { WORKSPACE_FILE_UPLOAD_SUBSCRIPTION } from "GraphQL/Upload/Subscription";
import { FileStatus, IFileStatusDetail, IFileWithId } from "Components/Upload/FilesList";

export function useWorkspaceFilesUploadBatch(workspaceId: string) {
  const [filesStatusMap, setFilesStatusMap] = useState<IFileStatusDetail>({});
  const [progressingFiles, setProgressingFiles] = useState<Record<string, number>>({});
  const client = useApolloClient();

  const onUpdateFilesStatus = useCallback((filesToUpdate: IFileStatusDetail) => {
    setFilesStatusMap((prev) => {
      return { ...prev, ...filesToUpdate };
    });
  }, []);

  useEffect(() => {
    // Subscribe to the uploading progress of the files
    const clientSubscribe = client
      .subscribe({ query: WORKSPACE_FILE_UPLOAD_SUBSCRIPTION, variables: { workspaceId } })
      .subscribe({
        next: (response) => {
          const addFileResponse = response.data.onWorkspaceFileAdded;
          if (addFileResponse) {
            const fileId = addFileResponse.id;
            if (addFileResponse.uploadStatus === FileUploadStatus.Running) {
              setProgressingFiles({
                [fileId]: addFileResponse.uploadedSize / addFileResponse.totalSize,
              });
            } else if (addFileResponse.uploadStatus === FileUploadStatus.Success) {
              onUpdateFilesStatus({ [fileId]: { status: FileStatus.Success } });
            } else if (addFileResponse.uploadStatus === FileUploadStatus.Failure) {
              onUpdateFilesStatus({ [fileId]: { status: FileStatus.Failed, failureReason: addFileResponse.error } });
            }
          }
        },
      });

    return () => {
      clientSubscribe.unsubscribe();
    };
  }, [workspaceId]);

  const [uploadWorkspaceFiles] = useUploadWorkspaceFilesMutation();
  const { abortUpload, uploadFilesBatch } = useUploadFilesBatch(
    onUpdateFilesStatus,
    (variables) => uploadWorkspaceFiles(variables),
    ["WorkspaceFiles", "WorkspaceFileAggregate"],
  );

  // Upload files in batch
  const uploadFiles = useCallback(
    async (files: Array<IFileWithId>) => {
      const { mainFiles, metadataFiles } = groupFiles(files);
      const processedFiles: Array<{ fileName: string; fileId: string }> = [];
      let currentSize = 0;
      const batchSize = 5;

      // Process main files
      let mainBatchFiles = mainFiles.slice(currentSize, batchSize);
      while (mainBatchFiles.length > 0) {
        const results = await uploadFilesBatch(mainBatchFiles, { workspaceId });
        currentSize += batchSize;
        mainBatchFiles = files.slice(currentSize, currentSize + batchSize);
        processedFiles.push(...results);
      }

      if (metadataFiles.length > 0) {
        // Process metadata files
        const renamedMetadataFiles = renameMetadataFiles(metadataFiles, processedFiles);
        currentSize = 0;
        let metadataBatchFiles = renamedMetadataFiles.slice(currentSize, batchSize);
        while (metadataBatchFiles.length > 0) {
          await uploadFilesBatch(renamedMetadataFiles, { workspaceId });
          currentSize += batchSize;
          metadataBatchFiles = renamedMetadataFiles.slice(currentSize, currentSize + batchSize);
        }
      }
    },
    [uploadFilesBatch, workspaceId],
  );

  return {
    abortUpload,
    filesStatusMap,
    progressingFiles,
    uploadFiles,
  };
}
