import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { IStatusCount } from "./WorkspaceBoardSuggestions";
import { WorkspaceBoardSuggestionStatusList } from "./WorkspaceBoardSuggestionStatusList";
import { SuggestionStatus } from "GraphQL/Generated/Apollo";

interface IWorkspaceBoardSuggestionsFilterPanelProps {
  currentPositionOnly: boolean;
  filteredCount: number;
  currentStatusCounts: Array<IStatusCount>;
  onCurrentPositionOnlyChanged: () => void;
  onStatusClick: (status: SuggestionStatus) => void;
  statusCounts: Array<IStatusCount>;
  totalCount: number;
}

export function WorkspaceBoardSuggestionsFilterPanel(props: IWorkspaceBoardSuggestionsFilterPanelProps) {
  const {
    statusCounts,
    filteredCount,
    currentStatusCounts,
    onStatusClick,
    onCurrentPositionOnlyChanged,
    currentPositionOnly,
    totalCount,
  } = props;
  const { t } = useTranslation();

  const [selectedStatus, setSelectedStatus] = useState<SuggestionStatus>();

  const onStatusChange = useCallback(
    (status: SuggestionStatus) => {
      onStatusClick(status);
      setSelectedStatus(status);
    },
    [onStatusClick],
  );

  if (!statusCounts) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        paddingBottom: "16px",
        paddingTop: "16px",
        position: "sticky",
        top: 0,
        width: "100%",
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          backgroundColor: "var(--astra-color-gray10)",
          borderRadius: 2,
          p: "12px",
        }}
      >
        <Stack direction="row" alignItems="center">
          <Box>
            <WorkspaceBoardSuggestionStatusList
              currentPositionOnly={currentPositionOnly}
              filteredCount={filteredCount}
              currentStatusCounts={currentStatusCounts}
              onStatusClick={onStatusChange}
              selectedStatus={selectedStatus}
              totalCount={totalCount}
            />
          </Box>
          <FormControlLabel
            value={currentPositionOnly}
            control={<Switch checked={currentPositionOnly} color="primary" onChange={onCurrentPositionOnlyChanged} />}
            label={t("Components.WorkspaceBoardSuggestion.CurrentPositionOnly")}
            labelPlacement="start"
            sx={{ marginLeft: "auto" }}
          />
        </Stack>
      </Box>
    </Box>
  );
}
