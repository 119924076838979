import { Box, Typography } from "@mui/material";
import { TLShapeId, useEditor, useValue } from "tldraw";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IHtmlDocumentShapeExternalData, useBoardDatastore } from "BoardComponents/BoardDatastore";
import {
  OnWorkspaceBoardSuggestionCreateSubscription,
  OnWorkspaceBoardSuggestionCreateSubscriptionVariables,
  SuggestionStatus,
  WorkspaceBoardSuggestionsListQuery,
  useOnWorkspaceBoardSuggestionCreateSubscription,
  useOnWorkspaceBoardSuggestionDeleteSubscription,
  useOnWorkspaceBoardSuggestionUpdateSubscription,
  useWorkspaceBoardSuggestionsListQuery,
} from "GraphQL/Generated/Apollo";
import { WORKSPACE_BOARD_SUGGESTION_CREATE_SUBSCRIPTION } from "GraphQL/WorkspaceBoardSuggestion/Subscription";
import { WorkspaceBoardSuggestion } from "./WorkspaceBoardSuggestion";
import { WorkspaceBoardSuggestionsFilterPanel } from "./WorkspaceBoardSuggestionsFilterPanel";
import { getBlockIdsInOrderOfAppearance } from "@bigpi/cookbook";
import { IHtmlDocumentShape } from "@bigpi/tl-schema";

// *********************************************
// Interfaces
// *********************************************/
export interface IWorkspaceBoardSuggestionsProps {
  scrollDocumentToBlockId: (blockId: string) => void;
  shapeId: string;
  workspaceId: string;
  workspaceBoardId: string;
}

export interface IStatusCount {
  status: SuggestionStatus;
  count: number;
}

// *********************************************
// Public methods
// *********************************************/
export function WorkspaceBoardSuggestions(props: IWorkspaceBoardSuggestionsProps) {
  const { scrollDocumentToBlockId, shapeId, workspaceId, workspaceBoardId } = props;
  const [selectedStatus, setSelectedStatus] = useState<SuggestionStatus>();
  const [showCurrentPositionOnly, setShowCurrentPositionOnly] = useState<boolean>(true);
  const [suggestionData, setSuggestionData] = useState<
    NonNullable<WorkspaceBoardSuggestionsListQuery["workspaceBoardSuggestions"]>
  >([]);
  const [statusCounts, setStatusCounts] = useState<Array<IStatusCount>>([]);
  const [currentStatusCounts, setCurrentStatusCounts] = useState<Array<IStatusCount>>([]);

  const tldrawEditor = useEditor();
  const { t } = useTranslation();

  const updateStatusCounts = useCallback(
    (suggestions: NonNullable<WorkspaceBoardSuggestionsListQuery["workspaceBoardSuggestions"]>): Array<IStatusCount> => {
      const statusCountMap = new Map<SuggestionStatus, number>();

      suggestions.forEach((suggestion) => {
        const status = suggestion.status;
        const count = statusCountMap.get(status) || 0;
        statusCountMap.set(status, count + 1);
      });

      return Array.from(statusCountMap).map(([status, count]) => ({ status, count }));
    },
    [],
  );

  // Get the selected block IDs from the shape datastore
  const datastore = useBoardDatastore();
  const selectedBlockIds = useValue(
    `WorkspaceBoardSuggestions.selectedBlockIds.${shapeId}`,
    () => {
      const shapeData = datastore.state.get()?.[shapeId]?.get() as IHtmlDocumentShapeExternalData | undefined;
      return shapeData?.selectedBlockIds.get();
    },
    [datastore, shapeId],
  );

  const { data, error, loading, subscribeToMore } = useWorkspaceBoardSuggestionsListQuery({
    variables: {
      filters: {
        workspaceBoardId,
        shapeId,
      },
    },
  });

  // These keep the cache up-to-date while we're here
  useOnWorkspaceBoardSuggestionCreateSubscription({
    variables: {
      workspaceBoardId,
      shapeId,
    },
  });

  useOnWorkspaceBoardSuggestionUpdateSubscription({
    variables: {
      workspaceBoardId,
      shapeId,
    },
  });

  useOnWorkspaceBoardSuggestionDeleteSubscription({
    variables: {
      workspaceBoardId,
      shapeId,
    },
    onData: (options) => {
      const suggestion = options.data.data?.onWorkspaceBoardSuggestionDelete;

      if (suggestion) {
        const cache = options.client.cache;
        const id = cache.identify({
          __typename: "WorkspaceBoardSuggestion",
          id: suggestion.id,
        });
        if (id) {
          cache.evict({ id });
        }
      }
    },
  });

  useEffect(() => {
    const unsubscribeCreated = subscribeToMore<
      OnWorkspaceBoardSuggestionCreateSubscription,
      OnWorkspaceBoardSuggestionCreateSubscriptionVariables
    >({
      document: WORKSPACE_BOARD_SUGGESTION_CREATE_SUBSCRIPTION,
      variables: {
        workspaceBoardId,
        shapeId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.onWorkspaceBoardSuggestionCreate) {
          return prev;
        }

        const newItem = subscriptionData.data.onWorkspaceBoardSuggestionCreate;

        return Object.assign({}, prev, {
          workspaceBoardSuggestions: [
            ...(Array.isArray(prev.workspaceBoardSuggestions) ? prev.workspaceBoardSuggestions : []),
            newItem,
          ],
        });
      },
    });

    return () => {
      unsubscribeCreated();
    };
  }, [subscribeToMore, workspaceBoardId, shapeId]);

  useEffect(() => {
    // Save the data to the state so we don't jump around while switching filters
    if (!loading) {
      // Get the current content of the document
      const documentShape = tldrawEditor.getShape(shapeId as TLShapeId);
      if (!documentShape) {
        return;
      }
      let result = [...(data?.workspaceBoardSuggestions || [])];

      // Filter the suggestions by status
      result = selectedStatus ? result.filter((suggestion) => selectedStatus === suggestion.status) : result;

      // Filter the suggestions by block IDs in the document
      result = result.filter((suggestion) => {
        if (showCurrentPositionOnly) {
          return selectedBlockIds?.some(
            (blockId) => suggestion.blockIds.includes(blockId) || suggestion.generatedBlockIds.includes(blockId),
          );
        } else {
          return true;
        }
      });

      // Sort the suggestions by the order of the blocks in the document
      const sortedBlockIds = getBlockIdsInOrderOfAppearance((documentShape as IHtmlDocumentShape).props.html);
      result.sort((a, b) => {
        const firstBlockIdA = a.blockIds.length > 0 ? a.blockIds[0] : "";
        const firstBlockIdB = b.blockIds.length > 0 ? b.blockIds[0] : "";
        const aIndex = sortedBlockIds.findIndex((blockId) => blockId === firstBlockIdA);
        const bIndex = sortedBlockIds.findIndex((blockId) => blockId === firstBlockIdB);

        return aIndex - bIndex;
      });

      // Use the sorted and filtered data
      setSuggestionData(result);
    }
  }, [data, loading, selectedBlockIds, selectedStatus, showCurrentPositionOnly, tldrawEditor]);

  useEffect(() => {
    if (data?.workspaceBoardSuggestions) {
      setStatusCounts(updateStatusCounts(data.workspaceBoardSuggestions));

      // Filter the suggestions by block IDs in the document
      const currentStatusCounts = data.workspaceBoardSuggestions.filter((suggestion) => {
        if (showCurrentPositionOnly) {
          return selectedBlockIds?.some(
            (blockId) => suggestion.blockIds.includes(blockId) || suggestion.generatedBlockIds.includes(blockId),
          );
        } else {
          return true;
        }
      });

      setCurrentStatusCounts(updateStatusCounts(currentStatusCounts));
    }
  }, [data, selectedBlockIds, showCurrentPositionOnly, updateStatusCounts]);

  const totalCount = data?.workspaceBoardSuggestions?.length || 0;
  return (
    <Box>
      <WorkspaceBoardSuggestionsFilterPanel
        filteredCount={suggestionData.length || 0}
        currentStatusCounts={currentStatusCounts}
        onStatusClick={(status) => setSelectedStatus(status)}
        onCurrentPositionOnlyChanged={() => setShowCurrentPositionOnly(!showCurrentPositionOnly)}
        currentPositionOnly={showCurrentPositionOnly}
        totalCount={totalCount}
        statusCounts={statusCounts}
      />

      {loading || suggestionData.length > 0 ? (
        suggestionData.map((suggestion, index) => {
          return (
            <WorkspaceBoardSuggestion
              key={index}
              scrollDocumentToBlockId={scrollDocumentToBlockId}
              workspaceId={workspaceId}
              workspaceBoardSuggestionId={suggestion.id}
            />
          );
        })
      ) : (
        <Typography variant="body1" sx={{ p: 2 }}>
          {totalCount > 0
            ? t(`Components.HtmlDocumentCompanion.Questionnaire.Tabs.Suggestions.Count.NoCurrentSuggestions`, {
                count: totalCount,
              })
            : t(`Components.HtmlDocumentCompanion.Questionnaire.Tabs.Suggestions.NoContent`)}
        </Typography>
      )}
    </Box>
  );
}
