import { TLShapeId, useValue, useEditor } from "tldraw";

export const useIsChildEditing = (shapeId: TLShapeId) => {
  const editor = useEditor();

  return useValue(
    "isChildEditing",
    () => {
      const childShapeIds = editor.getSortedChildIdsForParent(shapeId);
      return childShapeIds.some((childShapeId) => editor.getCurrentPageState().editingShapeId === childShapeId);
    },
    [editor, shapeId],
  );
};
