import { createShapePropsMigrationIds, createShapePropsMigrationSequence } from "@tldraw/tlschema";

import { IDataGridPreferences } from "../DataGrid/index.js";
import { IQuestionAnalysisFacets } from "./IQuestionAnalysisFacets.js";

export const QuestionAnalysisShapeVersions = createShapePropsMigrationIds("questionAnalysis", {
  RemoveDataGridPreferencesColumnVisibility: 1,
  AddPreferencesFacetSortModel: 2,
  AddStandardisedFacetsAndBounds: 3,
  AddSelection: 4,
});

export const questionAnalysisShapeMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: QuestionAnalysisShapeVersions.RemoveDataGridPreferencesColumnVisibility,
      up(props) {
        const { dataGrid, ...rest } = props.preferences;
        const dataGridPreferences = dataGrid as IDataGridPreferences & { columnVisibility?: Record<string, boolean> };
        if (dataGridPreferences) {
          delete dataGridPreferences.columnVisibility;
        }
        props.preferences = {
          ...props.preferences,
          dataGrid: dataGridPreferences,
        };
      },
      down(props) {
        props.preferences = {
          ...props.preferences,
          dataGrid: {
            ...props.preferences.dataGrid,
            columnVisibility: {},
          },
        };
      },
    },
    {
      id: QuestionAnalysisShapeVersions.AddPreferencesFacetSortModel,
      up(props) {
        const analysisPreferences = props.preferences.analysis || {};
        analysisPreferences.facetSort = [];
        props.preferences = {
          ...props.preferences,
          analysis: analysisPreferences,
        };
      },
      down(props) {
        delete props.preferences.analysis;
      },
    },
    {
      id: QuestionAnalysisShapeVersions.AddStandardisedFacetsAndBounds,
      up(props) {
        const { facets } = props;

        let selectedFacetValues: IQuestionAnalysisFacets = {};
        let boundsFacetValues = {};

        if (facets) {
          if (facets.eventDate) {
            selectedFacetValues.eventDate = facets.eventDate;
          }

          if (facets.selectedFirms) {
            selectedFacetValues.firm = facets.selectedFirms;
          }

          if (facets.selectedFirmTypes) {
            selectedFacetValues.firmType = facets.selectedFirmTypes;
          }

          if (facets.selectedSegments) {
            selectedFacetValues.segment = facets.selectedSegments;
          }

          if (facets.selectedTopics) {
            selectedFacetValues.topics = facets.selectedTopics;
          }
        }

        props.selectedFacetValues = selectedFacetValues;
        props.boundsFacetValues = boundsFacetValues;
        props.toolbar = {
          availableFields: ["eventDate", "firm", "firmType", "segment", "topics"],
        };

        if (facets) {
          delete props.facets;
        }
      },
      down(props) {
        const { preferences, selectedFacetValues } = props;

        let facets: {
          eventDate?: { from?: string; to?: string };
          selectedFirms?: Array<string>;
          selectedFirmTypes?: Array<string>;
          selectedSegments?: Array<string>;
          selectedTopics?: Array<string>;
        } = {};

        // Reverse the latest selectedFacetValues to old facets
        if (selectedFacetValues) {
          if (selectedFacetValues.eventDate) {
            facets.eventDate = selectedFacetValues.eventDate;
          }

          if (selectedFacetValues.topics) {
            facets.selectedTopics = selectedFacetValues.topics;
          }

          if (selectedFacetValues.firm) {
            facets.selectedFirms = selectedFacetValues.firm;
          }

          if (selectedFacetValues.firmType) {
            facets.selectedFirmTypes = selectedFacetValues.firmType;
          }

          if (selectedFacetValues.segment) {
            facets.selectedSegments = selectedFacetValues.segment;
          }
        }

        if (preferences && preferences.analysis && preferences.analysis.showDataToDisplayInToolbar !== undefined) {
          delete preferences.analysis.showDataToDisplayInToolbar;
        }

        if (props.boundsFacetValues) {
          delete props.boundsFacetValues;
        }
        if (props.selectedBoundsFields) {
          delete props.selectedBoundsFields;
        }
        if (props.selectedFacetValues) {
          delete props.selectedFacetValues;
        }
        if (props.toolbar) {
          delete props.toolbar;
        }

        props.facets = facets;
      },
    },
    {
      id: QuestionAnalysisShapeVersions.AddSelection,
      up(props) {
        // No changes needed for up migration
      },
      down(props) {
        delete props.selection;
      },
    },
  ],
});
