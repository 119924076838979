import { DocumentType } from "@bigpi/cookbook";
import { Typography } from "@mui/material";
import { SimpleTreeView, TreeItem2 } from "@mui/x-tree-view";
import { MouseEvent, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { HtmlDocumentShapeType, WorkspaceNavigationDetailsQuery } from "GraphQL/Generated/Apollo";
import { IShapeTreeItemIconProps, ShapeTreeItemIcon } from "Navigation/Components/ShapeTreeItemIcon";
import { IShapeTreeItemLabelProps, ShapeTreeItemLabel } from "Navigation/Components/ShapeTreeItemLabel";
import { NavigationShapeType } from "./NavigationPanel";

export interface IWorkspaceNavigationDetailsProps {
  onBoardNavigation: (boardId: string) => void;
  onShapeNavigation: (boardId: string, shapeId: string) => void;
  openShapeTocNavigation: (boardId: string, shapeId: string) => void;
  workspaceNavigationDetails: WorkspaceNavigationDetailsQuery["workspaceNavigationDetails"];
  workspaceBoardId: string;
  selectedShapeId?: string;
}

export function WorkspaceNavigationDetails(props: IWorkspaceNavigationDetailsProps) {
  const { openShapeTocNavigation, workspaceNavigationDetails, workspaceBoardId, selectedShapeId } = props;

  const { t } = useTranslation();

  const [expandedItems, setExpandedItems] = useState<Array<string>>([]);
  useEffect(() => {
    // Expand all the boards by default
    setExpandedItems(workspaceNavigationDetails?.boards.map((board) => board.id) || []);
  }, [workspaceNavigationDetails]);

  const getSelectedItemId = useCallback(
    (workspaceBoardId: string, selectedShapeId?: string) => {
      const isKeyShape =
        selectedShapeId &&
        workspaceNavigationDetails?.boards.find((board) => board.keyShapes.find((shape) => shape.id === selectedShapeId))?.id;

      // If the selected shape is a key shape, return the selected shape id else return the workspace board id
      return isKeyShape ? selectedShapeId : workspaceBoardId;
    },
    [workspaceNavigationDetails],
  );

  const scrollTreeItemIntoView = useCallback((boardId: string, shapeId?: string) => {
    let treeItemId: string | null = null;
    if (boardId && shapeId) {
      const isShapeInWorkspaceBoard =
        workspaceNavigationDetails?.boards.find((board) => board.keyShapes.find((shape) => shape.id === shapeId))?.id === boardId;
      if (isShapeInWorkspaceBoard) {
        treeItemId = `workspace-navigation-details-treeitem-${shapeId}`;
      }
    } else if (boardId) {
      treeItemId = `workspace-navigation-details-treeitem-${boardId}`;
    }

    if (treeItemId) {
      const elementToScroll = document.getElementById(`workspace-navigation-details-treeitem-${boardId}`);
      elementToScroll?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }, []);

  const [selectedItem, setSelectedItem] = useState<string | null>(getSelectedItemId(workspaceBoardId, selectedShapeId));
  useEffect(() => {
    // Scroll the selected item into view when the selected item changes
    scrollTreeItemIntoView(workspaceBoardId, selectedShapeId);

    // Update the selected item when the selected shape id changes
    setSelectedItem(getSelectedItemId(workspaceBoardId, selectedShapeId));
  }, [selectedShapeId, workspaceBoardId]);

  const onExpandedItemsChange = useCallback((event: SyntheticEvent, items: Array<string>) => {
    event.stopPropagation();
    setExpandedItems(items);
  }, []);

  const onSelectedItemChange = useCallback((_event: SyntheticEvent, item: string | null) => {
    setSelectedItem(item);
  }, []);

  const onBoardNavigation = (event: MouseEvent, boardId: string) => {
    event.stopPropagation();

    setSelectedItem(boardId);
    props.onBoardNavigation(boardId);
  };

  const onShapeNavigation = (event: MouseEvent, boardId: string, shapeId: string) => {
    event.stopPropagation();

    setSelectedItem(shapeId);
    props.onShapeNavigation(boardId, shapeId);
  };

  if (!workspaceNavigationDetails || workspaceNavigationDetails.boards.length === 0) {
    return <Typography>{t("Components.WorkspaceNavigationDetails.NavigationDetailsNotFound")}</Typography>;
  }

  return (
    <SimpleTreeView
      expansionTrigger="iconContainer"
      expandedItems={expandedItems}
      onExpandedItemsChange={onExpandedItemsChange}
      selectedItems={selectedItem}
      onSelectedItemsChange={onSelectedItemChange}
      multiSelect={false}
    >
      {workspaceNavigationDetails.boards.map((board) => (
        <TreeItem2
          id={`workspace-navigation-details-treeitem-${board.id}`}
          itemId={board.id}
          label={board.name}
          key={board.id}
          onClick={(event) => onBoardNavigation(event, board.id)}
        >
          {board.keyShapes.map((keyShape) => {
            const iconProps: IShapeTreeItemIconProps = {
              documentType: keyShape.documentType as DocumentType | null | undefined,
              shapeType: keyShape.type as NavigationShapeType,
            };
            const labelProps: IShapeTreeItemLabelProps = {
              isShapeTableOfContentsAvailable:
                keyShape.type === "htmlDocument" && keyShape.documentType === HtmlDocumentShapeType.Questionnaire,
              openShapeToc: () => openShapeTocNavigation(board.id, keyShape.id),
            };

            return (
              <TreeItem2
                id={`workspace-navigation-details-treeitem-${keyShape.id}`}
                key={keyShape.id}
                itemId={keyShape.id}
                label={keyShape.name || t("Components.WorkspaceNavigationDetails.UntitledDocument")}
                onClick={(event) => onShapeNavigation(event, board.id, keyShape.id)}
                slots={{ icon: ShapeTreeItemIcon, label: ShapeTreeItemLabel }}
                slotProps={{
                  icon: iconProps,
                  label: labelProps,
                }}
              />
            );
          })}
        </TreeItem2>
      ))}
    </SimpleTreeView>
  );
}
