import { extractFileNameAndExtension, isMetadataFile } from "@bigpi/cookbook";
import { useCallback, useState } from "react";
import { FetchResult } from "@apollo/client";

import { IFileStatusDetail, IFileWithId, FileStatus } from "../FilesList";
import { UploadFilesMutation, UploadWorkspaceFilesMutation } from "GraphQL/Generated/Apollo";

/**
 * Hook to upload a batch of files. Also tracks uploading, cancelled files & ability to abort the upload.
 *
 * @returns Object with abortUpload, uploadingFiles, and uploadFilesBatch
 */
export function useUploadFilesBatch(
  onUpdateFilesStatus: (filesToUpdate: IFileStatusDetail) => void,
  uploadFiles: (variables: any) => Promise<FetchResult<UploadFilesMutation> | FetchResult<UploadWorkspaceFilesMutation>>,
  refetchQueries: Array<string>,
) {
  const [abortControllers, setAbortControllers] = useState<Record<string, AbortController>>({});

  // Callback to upload a batch of files
  const uploadBatch = useCallback(
    async (batch: Array<IFileWithId>, extraVariables?: { workspaceId?: string; bundleId?: string }) => {
      const controllers: Record<string, AbortController> = {};
      const processedFiles: Array<{ fileName: string; fileId: string }> = [];
      // Add files to uploadingFiles list
      onUpdateFilesStatus(batch.reduce((acc, file) => ({ ...acc, [file.id]: FileStatus.Uploading }), {}));

      const uploadPromises = batch.map((file) => {
        // AbortController to cancel the upload
        const controller = new AbortController();
        controllers[file.id] = controller;
        // Mutation to upload the file
        return uploadFiles({
          variables: {
            input: {
              files: [
                {
                  id: file.id,
                  file: file.file,
                  fileSize: file.file.size,
                },
              ],
              ...extraVariables,
            },
          },
          context: {
            fetchOptions: {
              signal: controller.signal,
            },
          },
          refetchQueries,
        })
          .then((response) => {
            // Handle failed and succeeded files
            const failedFiles: IFileStatusDetail = {};
            const successFiles: IFileStatusDetail = {};
            let uploadFilesResponse;
            if (response.data) {
              if ("uploadFiles" in response.data) {
                uploadFilesResponse = response.data.uploadFiles;
              } else if ("uploadWorkspaceFiles" in response.data) {
                uploadFilesResponse = response.data.uploadWorkspaceFiles;
              }
            }

            uploadFilesResponse?.failed.forEach((failedFile) => {
              failedFiles[failedFile.id] = { status: FileStatus.Failed, failureReason: failedFile.reason };
            });

            uploadFilesResponse?.succeeded.forEach((successFile) => {
              successFiles[successFile.id] = { status: FileStatus.Success };
              processedFiles.push({ fileName: file.file.name, fileId: successFile.fileId || "" });
            });

            onUpdateFilesStatus({
              ...failedFiles,
              ...successFiles,
            });
          })
          .catch((error) => {
            if (error.name !== "AbortError" && controller.signal.aborted === false) {
              console.error("Error uploading file:", file.id, error);
            }

            onUpdateFilesStatus({ [file.id]: { status: FileStatus.Cancelled } });
          })
          .finally(() => {
            // TODO: Should we handle anything here?
          });
      });

      setAbortControllers((prev) => ({ ...prev, ...controllers }));
      // Wait for all files to be uploaded
      await Promise.all(uploadPromises);

      return processedFiles;
    },
    [],
  );

  // Aborts current running upload (cancels the netowrk request)
  const abortUpload = useCallback(
    (fileId: string) => {
      const controller = abortControllers[fileId];
      controller?.abort();
    },
    [abortControllers],
  );

  return {
    abortUpload,
    uploadFilesBatch: uploadBatch,
  };
}

/**
 * Group files into metadata and main files.
 *
 * @param files Files to group.
 *
 * @returns Metadata and main files.
 */
export function groupFiles(files: Array<IFileWithId>) {
  const metadataFiles = files.filter((file) => isMetadataFile(file.file.name));
  const mainFiles = files.filter((file) => !isMetadataFile(file.file.name));
  return {
    metadataFiles,
    mainFiles,
  };
}

/**
 * Rename metadata files with the fileIds of the main files.
 *
 * @param metadataFiles Metadata files to rename.
 * @param processedFiles Main files that have been processed and having fileIds.
 *
 * @returns Renamed metadata files.
 */
export function renameMetadataFiles(
  metadataFiles: Array<IFileWithId>,
  processedFiles: Array<{ fileName: string; fileId: string }>,
) {
  return metadataFiles.map((metadataFile) => {
    const metadataFileNameParts = metadataFile.file.name.split(".metadata.");
    const metadataBaseFileName = metadataFileNameParts[0];

    const extension = metadataFileNameParts[1];
    const processedFile = processedFiles.find((file) => {
      const fileName = file.fileName;
      const { name: baseFileName } = extractFileNameAndExtension(fileName);
      // Match the metadata file name with the processed file name or base file name
      if (fileName === metadataBaseFileName || baseFileName === metadataBaseFileName) {
        return true;
      }
      return false;
    });

    if (processedFile) {
      const newFileName = `${processedFile.fileId}.metadata.${extension}`;
      const newFile = new File([metadataFile.file], newFileName, { type: metadataFile.file.type });
      return {
        ...metadataFile,
        file: newFile,
      };
    }
    return metadataFile;
  });
}
