import { InMemoryCache } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";
import { getObjectHash } from "@bigpi/cookbook";

import { StrictTypedTypePolicies } from "GraphQL/Generated/ApolloHelpers";
import { TranscriptsQuery, WorkspaceBoardKeyShape, WorkspaceBoardNavigationDetails } from "./Generated/Apollo";

function paginatedMerge(existing: any, incoming: any, { args }: { args: Record<string, any> | null }) {
  const offset = args?.offset || 0;
  const merged = existing ? existing.slice(0) : [];
  for (let i = 0; i < incoming.length; ++i) {
    merged[offset + i] = incoming[i];
  }
  return merged;
}

function paginatedRead(existing: any, { args }: { args: Record<string, any> | null }) {
  const offset = args?.offset || 0;
  // Limit results to request amount if set. Otherwise attempt to return all results
  const limit = args?.limit || (Array.isArray(existing) && existing.length ? existing.length : 0);
  if (existing && existing.length >= offset + limit) {
    return existing && existing.slice(offset, offset + limit);
  } else {
    return undefined;
  }
}

function mergeNavigationDetailsEntities<T>(
  existing: Array<T> = [],
  incoming: Array<T>,
  mergeObjects: (a: T, b: T) => T,
  readField: <V>(fieldName: string, obj: T) => V,
): Array<T> {
  // Get ids of incoming entities
  const incomingIds = incoming.map((entity) => readField("id", entity));

  // Remove entries from existing that are not in incoming
  const merged = existing.filter((entity) => incomingIds.includes(readField("id", entity)));

  // Merge incoming entities into existing entities
  incoming.forEach((entity) => {
    const index = merged.findIndex((e) => readField("id", e) === readField("id", entity));
    if (index !== -1) {
      merged[index] = mergeObjects(merged[index], entity);
    } else {
      merged.push(entity);
    }
  });

  // Sort merged entities by name
  return merged.sort((a, b) => {
    const aName = readField<string>("name", a);
    const bName = readField<string>("name", b);

    // If both elements have names, compare them
    if (aName && bName) {
      return aName.localeCompare(bName);
    }

    // If only a has name, a should come first
    if (aName) {
      return -1;
    }

    // If only b has name, b should come first
    if (bName) {
      return 1;
    }

    // If neither has name, keep original order
    return 0;
  });
}

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      documents: {
        ...offsetLimitPagination(),
        keyArgs: ["limit", "orderBy", "filters"],
      },
      fileBundleView: {
        ...offsetLimitPagination(),
        keyArgs: ["limit", "orderBy", "filters"],
      },
      files: {
        ...offsetLimitPagination(),
        keyArgs: ["orderBy", "filters"],
      },
      transcripts: {
        merge: (
          existing: TranscriptsQuery["transcripts"] | undefined,
          incoming: TranscriptsQuery["transcripts"] | undefined,
          { args }: { args: Record<string, any> | null },
        ) => {
          const merged = {
            results: existing?.results?.slice(0) || [],
            totalCount: existing?.totalCount || 0,
            status: existing?.status || "success",
          };
          merged.results = merged.results.concat(incoming?.results || []);
          merged.totalCount = incoming?.totalCount || merged.results.length;
          merged.status = incoming?.status || "success";

          return merged;
        },
        keyArgs: ["orderBy", "filters"],
      },
      userGroups: {
        merge: paginatedMerge,
        read: paginatedRead,
        keyArgs: ["orderBy"],
      },
      userGroupMembers: {
        merge: paginatedMerge,
        read: paginatedRead,
        keyArgs: ["orderBy", "userGroupId", "userId"],
      },
      workspaces: {
        ...offsetLimitPagination(),
        keyArgs: ["orderBy", "filters"],
      },
      workspaceBoards: {
        ...offsetLimitPagination(),
        keyArgs: ["workspaceId", "orderBy", "filters"],
      },
      workspaceBoardSuggestions: {
        ...offsetLimitPagination(),
        keyArgs: ["filters"],
      },
      workspaceFiles: {
        ...offsetLimitPagination(),
        keyArgs: ["workspaceId", "orderBy", "filters"],
      },
    },
  },
  WorkspaceBoardKeyShape: {
    merge: true,
  },
  WorkspaceBoardNavigationDetails: {
    fields: {
      keyShapes: {
        merge: (
          existing: Array<WorkspaceBoardKeyShape> = [],
          incoming: Array<WorkspaceBoardKeyShape>,
          { mergeObjects, readField },
        ) => {
          return mergeNavigationDetailsEntities<WorkspaceBoardKeyShape>(existing, incoming, mergeObjects, readField);
        },
      },
    },
  },
  WorkspaceNavigationDetails: {
    fields: {
      boards: {
        merge: (
          existing: Array<WorkspaceBoardNavigationDetails> = [],
          incoming: Array<WorkspaceBoardNavigationDetails>,
          { mergeObjects, readField },
        ) => {
          return mergeNavigationDetailsEntities<WorkspaceBoardNavigationDetails>(existing, incoming, mergeObjects, readField);
        },
      },
    },
  },
  UserPreference: {
    // NOTE: Only the client uses just the `key` field as a key. The real key is key/organizationId/userId.
    keyFields: ["key"],
  },
  GroupedAnalystQuestion: {
    fields: {
      group: {
        keyArgs: (args, context) => {
          // Creating unique id for the group based on the variables to have a unique cache key
          return `GroupedAnalystQuestion:group:${getObjectHash(JSON.stringify(context.variables))}`;
        },
      },
    },
  },
};

export const cache = new InMemoryCache({
  typePolicies,
});
