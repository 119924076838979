import { useApolloClient } from "@apollo/client";
import { FileUploadStatus } from "@bigpi/cookbook";
import { useCallback, useEffect, useState } from "react";

import { groupFiles, renameMetadataFiles, useUploadFilesBatch } from "Components/Upload/Hooks/useUploadFilesBatch";
import { useUploadFilesMutation } from "GraphQL/Generated/Apollo";
import { FILE_UPLOAD_SUBSCRIPTION } from "GraphQL/Upload/Subscription";
import { FileStatus, IFileWithId, IFileStatusDetail } from "Components/Upload/FilesList";

export function useLibraryFilesUploadBatch() {
  const [filesStatusMap, setFilesStatusMap] = useState<IFileStatusDetail>({});
  const [progressingFiles, setProgressingFiles] = useState<Record<string, number>>({});
  const client = useApolloClient();

  const onUpdateFilesStatus = useCallback((filesToUpdate: IFileStatusDetail) => {
    setFilesStatusMap((prev) => {
      return { ...prev, ...filesToUpdate };
    });
  }, []);

  useEffect(() => {
    // Subscribe to the uploading progress of the files
    const clientSubscribe = client.subscribe({ query: FILE_UPLOAD_SUBSCRIPTION }).subscribe({
      next: (response) => {
        const addFileResponse = response.data.onFileAdded;
        if (addFileResponse) {
          const fileId = addFileResponse.id;
          if (addFileResponse.uploadStatus === FileUploadStatus.Running) {
            setProgressingFiles({
              [fileId]: addFileResponse.uploadedSize / addFileResponse.totalSize,
            });
          } else if (addFileResponse.uploadStatus === FileUploadStatus.Success) {
            onUpdateFilesStatus({ [fileId]: { status: FileStatus.Success } });
          } else if (addFileResponse.uploadStatus === FileUploadStatus.Failure) {
            onUpdateFilesStatus({ [fileId]: { status: FileStatus.Failed, failureReason: addFileResponse.error } });
          }
        }
      },
    });

    return () => {
      clientSubscribe.unsubscribe();
    };
  }, []);

  const [uploadLibraryFiles] = useUploadFilesMutation();
  const { abortUpload, uploadFilesBatch } = useUploadFilesBatch(
    onUpdateFilesStatus,
    (variables) => uploadLibraryFiles(variables),
    ["Files", "FileBundleView"],
  );

  // Upload files in batch
  const uploadFiles = useCallback(
    async (files: Array<IFileWithId>, bundleId?: string) => {
      const { mainFiles, metadataFiles } = groupFiles(files);
      const processedFiles: Array<{ fileName: string; fileId: string }> = [];
      let currentSize = 0;
      const batchSize = 5;

      // Process main files
      let mainBatchFiles = mainFiles.slice(currentSize, batchSize);
      while (mainBatchFiles.length > 0) {
        const results = await uploadFilesBatch(mainBatchFiles, { bundleId });
        currentSize += batchSize;
        mainBatchFiles = files.slice(currentSize, currentSize + batchSize);
        processedFiles.push(...results);
      }

      if (metadataFiles.length > 0) {
        // Process metadata files
        const renamedMetadataFiles = renameMetadataFiles(metadataFiles, processedFiles);
        currentSize = 0;
        let metadataBatchFiles = renamedMetadataFiles.slice(currentSize, batchSize);
        while (metadataBatchFiles.length > 0) {
          await uploadFilesBatch(renamedMetadataFiles, { bundleId });
          currentSize += batchSize;
          metadataBatchFiles = renamedMetadataFiles.slice(currentSize, currentSize + batchSize);
        }
      }
    },
    [uploadFilesBatch],
  );

  return {
    abortUpload,
    filesStatusMap,
    progressingFiles,
    uploadFiles,
  };
}
