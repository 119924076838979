import { DocumentType } from "@bigpi/cookbook";
import { TreeItem2SlotProps } from "@mui/x-tree-view";

import { ShapeIcon } from "BoardComponents/ShapeIcon/ShapeIcon";
import { NavigationShapeType } from "Navigation/NavigationPanel/NavigationPanel";

export type IShapeTreeItemIconProps = TreeItem2SlotProps["icon"] & {
  documentType?: DocumentType | null;
  shapeType: NavigationShapeType;
};

export const ShapeTreeItemIcon = (props: IShapeTreeItemIconProps) => {
  const { documentType, shapeType, ...rest } = props;

  return <ShapeIcon documentType={documentType} shapeType={shapeType} />;
};
