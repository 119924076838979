import { createShapePropsMigrationIds, createShapePropsMigrationSequence } from "@tldraw/tlschema";

export const LockedTextShapeVersions = createShapePropsMigrationIds("lockedText", {
  RemoveJustify: 1,
  AlignToTextAlign: 2,
});

export const lockedTextShapeMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: LockedTextShapeVersions.RemoveJustify,
      up(props) {
        if (props.align === "justify") {
          props.align = "start";
        }
      },
      down(props) {
        // No changes needed for down migration
      },
    },
    {
      id: LockedTextShapeVersions.AlignToTextAlign,
      up(props) {
        if (props.align) {
          props.textAlign = props.align;
        }

        delete props.align;
      },
      down(props) {
        if (props.textAlign) {
          props.align = props.textAlign;
        }

        delete props.textAlign;
      },
    },
  ],
});
