import { createShapePropsMigrationIds, createShapePropsMigrationSequence } from "@tldraw/tlschema";

import { IAnalystQuestionAnalysisSelection } from "./IAnalystQuestionAnalysisShape.js";
import { IAnalystQuestionAnalysisFacets } from "./IAnalystQuestionAnalysisFacets.js";
import { IDateRange } from "@bigpi/cookbook";

export const AnalystQuestionAnalysisShapeVersions = createShapePropsMigrationIds("analystQuestionAnalysis", {
  AddBackgroundColorField: 1,
  AddPreferencesField: 2,
  RemoveDataGridPreferencesColumnVisibility: 3,
  AddPreferencesFacetSortModel: 4,
  AddStandardisedFacetsAndBounds: 5,
});

export const analystQuestionAnalysisShapeMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: AnalystQuestionAnalysisShapeVersions.AddBackgroundColorField,
      up(props) {
        props.enableBackground = false;
      },
      down(props) {
        delete props.enableBackground;
      },
    },
    {
      id: AnalystQuestionAnalysisShapeVersions.AddPreferencesField,
      up(props) {
        props.preferences = {};
      },
      down(props) {
        delete props.preferences;
      },
    },
    {
      id: AnalystQuestionAnalysisShapeVersions.RemoveDataGridPreferencesColumnVisibility,
      up(props) {
        if (props.preferences?.dataGrid) {
          delete props.preferences.dataGrid.columnVisibility;
        }
      },
      down(props) {
        if (props.preferences?.dataGrid) {
          props.preferences.dataGrid.columnVisibility = {};
        }
      },
    },
    {
      id: AnalystQuestionAnalysisShapeVersions.AddPreferencesFacetSortModel,
      up(props) {
        const { preferences } = props;
        const analysisPreferences = preferences.analysis || {};
        analysisPreferences.facetSort = [];
        props.preferences = {
          ...preferences,
          analysis: analysisPreferences,
        };
      },
      down(props) {
        const { preferences } = props;
        if (preferences.analysis) {
          delete preferences.analysis.facetSort;
        }
      },
    },
    {
      id: AnalystQuestionAnalysisShapeVersions.AddStandardisedFacetsAndBounds,
      up(props) {
        const { facets } = props;

        let selectedFacetValues: IAnalystQuestionAnalysisFacets = {};
        let selection: IAnalystQuestionAnalysisSelection = {};

        if (facets) {
          if (facets.eventDateBarChart) {
            selectedFacetValues.eventDate = facets.eventDateBarChart;
          }

          if (facets.firmBarChart) {
            selectedFacetValues.firm = facets.firmBarChart;
          }

          if (facets.firmTypeBarChart) {
            selectedFacetValues.firmType = facets.firmTypeBarChart;
          }

          if (facets.segmentBarChart) {
            selectedFacetValues.segment = facets.segmentBarChart;
          }

          if (facets.topicsBarChart) {
            selectedFacetValues.topics = facets.topicsBarChart;
          }

          // Selection
          if (facets.timeScale) {
            selection.selectedTimeScale = facets.timeScale;
          }
        }

        props.selectedFacetValues = selectedFacetValues;
        props.selection = selection;
        props.toolbar = {
          availableFields: ["eventDate", "firm", "firmType", "segment", "topics"],
        };

        // Remove old facets
        if (props.facets) {
          delete props.facets;
        }
      },
      down(props) {
        let facets: {
          eventDateBarChart?: IDateRange;
          firmBarChart?: Array<string>;
          firmTypeBarChart?: Array<string>;
          segmentBarChart?: Array<string>;
          topicsBarChart?: Array<string>;
          timeScale?: string;
        } = {};

        // Reverse the latest selectedFacetValues to old facets
        if (props.selectedFacetValues) {
          const { selectedFacetValues } = props;

          if (selectedFacetValues.eventDate) {
            facets.eventDateBarChart = selectedFacetValues.eventDate;
          }

          if (selectedFacetValues.topics) {
            facets.topicsBarChart = selectedFacetValues.topics;
          }

          if (selectedFacetValues.firm) {
            facets.firmBarChart = selectedFacetValues.firm;
          }

          if (selectedFacetValues.firmType) {
            facets.firmTypeBarChart = selectedFacetValues.firmType;
          }

          if (selectedFacetValues.segment) {
            facets.segmentBarChart = selectedFacetValues.segment;
          }

          if (selectedFacetValues.topics) {
            facets.topicsBarChart = selectedFacetValues.topics;
          }

          if (
            props.preferences &&
            props.preferences.analysis &&
            props.preferences.analysis.showDataToDisplayInToolbar !== undefined
          ) {
            delete props.preferences.analysis.showDataToDisplayInToolbar;
          }

          // Selection
          if (props.selection && props.selection.selectedTimeScale) {
            facets.timeScale = props.selection.selectedTimeScale;
          }
        }

        props.facets = facets;

        if (props.selectedFacetValues) {
          delete props.selectedFacetValues;
        }
        if (props.selection) {
          delete props.selection;
        }
        if (props.toolbar) {
          delete props.toolbar;
        }
      },
    },
  ],
});
