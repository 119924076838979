import { Constants } from "@bigpi/permission";
import { Extension, Range } from "@tiptap/core";

// We need to declare our custom commands so that TS doesn't throw warning everywhere saying these commands do not exist.
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    insertAiUserPrivateMention: {
      insertAiUserPrivateMention: (searchPrompt: string, range?: Range) => ReturnType;
    };
  }
}

/**
 * Command to insert a private mention for the AI user.
 */
export const InsertAiUserPrivateMentionCommand = Extension.create({
  name: "insertAiUserPrivateMention",

  addCommands() {
    return {
      insertAiUserPrivateMention:
        (searchPrompt: string, range?: Range) =>
        ({ commands, state, chain }) => {
          const { selection } = state;
          const { from, to } = range || selection;

          return chain()
            .focus()
            .insertContentAt({ from, to }, [
              {
                type: "privateMention",
                attrs: { id: Constants.AI_USER_FULL_NAME },
              },
              {
                type: "text",
                text: " ",
              },
              {
                type: "text",
                text: searchPrompt,
              },
            ])
            .run();
        },
    };
  },
});
