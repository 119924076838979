import { DocumentType } from "@bigpi/cookbook";
import { TFunction } from "i18next";

export enum FieldType {
  ArrayOfStrings = "ArrayOfStrings",
  Date = "Date",
  Number = "Number",
  String = "String",
}

export interface IOption {
  value: string;
  label: string;
}

export interface IFieldDefinition {
  name: string;
  key: string;
  type: FieldType;
  defaultValue?: string | Array<string>;
  options?: Array<IOption>;
}

export function getFieldsConfig(t: TFunction): Array<IFieldDefinition> {
  return [
    {
      name: "Name",
      key: "name",
      type: FieldType.String,
    },
    {
      name: "Type",
      key: "documentType",
      type: FieldType.ArrayOfStrings,
      defaultValue: DocumentType.None,
      options: [
        { value: DocumentType.None, label: t(`Global.DocumentType.${DocumentType.None}`) },
        { value: DocumentType.KnowledgeBaseArticle, label: t(`Global.DocumentType.${DocumentType.KnowledgeBaseArticle}`) },
        { value: DocumentType.Plan, label: t(`Global.DocumentType.${DocumentType.Plan}`) },
        { value: DocumentType.Questionnaire, label: t(`Global.DocumentType.${DocumentType.Questionnaire}`) },
      ],
    },
    {
      name: "Date",
      key: "itemDate",
      type: FieldType.Date,
    },
  ];
}
