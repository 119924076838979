import { AddToBoardType, FileSourceType } from "@bigpi/cookbook";
import { Box, Typography, useTheme } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { FileItemCompact } from "Components/FileItem/FileItemCompact";
import { SplitButton } from "Components/SplitButton/SplitButton";
import { FilesAndDocumentsQuery, useWorkspaceFileLazyQuery } from "GraphQL/Generated/Apollo";

interface IWorkspaceFileListProps {
  data: FilesAndDocumentsQuery["workspaceFiles"];
  onAddDocumentToBoard: (id: string, fileName: string, fileSourceType: FileSourceType, metadata: Record<string, any>) => void;
  onAddPreviewToBoard: (id: string, fileSourceType: FileSourceType, fileName: string) => void;
}

export function WorkspaceFileList(props: IWorkspaceFileListProps) {
  const { data, onAddDocumentToBoard, onAddPreviewToBoard } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [getWorkspaceFile] = useWorkspaceFileLazyQuery();

  const onSplitButtonClick = useCallback(
    async (option: string, id: string, fileName: string) => {
      switch (option) {
        case AddToBoardType.AddPreview:
          onAddPreviewToBoard(id, FileSourceType.WorkspaceFile, fileName);
          break;
        case AddToBoardType.AddDocument:
          const workspaceFile = await getWorkspaceFile({ variables: { id } });
          onAddDocumentToBoard(id, fileName, FileSourceType.WorkspaceFile, workspaceFile.data?.workspaceFile?.metadata);
          break;
        default:
          break;
      }
    },
    [onAddPreviewToBoard],
  );

  return (
    <Box>
      <Typography variant="subtitle2">{t("Components.AddToBoard.SectionHeader.WorkspaceFile")}</Typography>
      {data.map((file) => {
        return (
          <FileItemCompact
            fileCreatedAt={file.createdAt}
            fileId={file.id}
            fileMimeType={file.mimeType}
            fileName={file.name}
            fileSourceType={FileSourceType.WorkspaceFile}
            key={file.id}
            secondaryAction={() => getSecondaryAction(file.id, file.name)}
          />
        );
      })}
    </Box>
  );

  /**
   * Gets the secondary action with SplitButton.
   *
   * @param id Id of the file.
   *
   * @returns SplitButton component with AddPreview action.
   */
  function getSecondaryAction(id: string, fileName: string) {
    return (
      <SplitButton
        handleClick={(option) => onSplitButtonClick(option, id, fileName)}
        options={[
          { value: AddToBoardType.AddPreview, label: t("Components.AddToBoard.Actions.AddPreview") },
          { value: AddToBoardType.AddDocument, label: t("Components.AddToBoard.Actions.AddDocument") },
        ]}
        slotProps={{
          popper: { sx: { zIndex: theme.zIndex.modal } },
        }}
      />
    );
  }
}
