import {
  COMMAND_ACCEPT_BOARD_QUESTIONNAIRE_SUGGESTION_TEMPLATE,
  COMMAND_REVERT_BOARD_QUESTIONNAIRE_SUGGESTION_TEMPLATE,
  ICommandRequest,
} from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import { Box, Button } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { CommandContext } from "CommandContext/CommandContext";
import { useCommandExecutor } from "Components/CommandManagers/useCommandExecutor";
import {
  ObjectRole,
  SuggestionStatus,
  useDeleteWorkspaceBoardSuggestionMutation,
  useUpdateWorkspaceBoardSuggestionMutation,
} from "GraphQL/Generated/Apollo";
import { useHasWorkspaceRole } from "Hooks/useHasWorkspaceRole";
import { getSuggestionStatusRelatedActions, SuggestionStatusAction } from "./SuggestionStatusRelatedActions";

interface ISuggestionStatusActionsProps {
  scrollDocumentToFirstBlockId: () => void;
  status: SuggestionStatus;
  workspaceId: string;
  workspaceBoardSuggestionId: string;
  workspaceBoardSuggestionCreatedBy: string;
}

export function SuggestionStatusActions(props: ISuggestionStatusActionsProps) {
  const { scrollDocumentToFirstBlockId, status, workspaceId, workspaceBoardSuggestionCreatedBy, workspaceBoardSuggestionId } =
    props;
  const relatedActions = getSuggestionStatusRelatedActions(status);
  const user = useAuthUser();
  const { t } = useTranslation();
  const commandExecutor = useCommandExecutor();
  const hasDeletePermissions = useHasWorkspaceRole(workspaceId, [
    ObjectRole.ContentManager,
    ObjectRole.Manager,
    ObjectRole.Owner,
  ]);

  // Delete mutation
  const [deleteSuggestionMutation, { loading: deleteLoading }] = useDeleteWorkspaceBoardSuggestionMutation();
  const deleteSuggestion = useCallback(() => {
    deleteSuggestionMutation({
      variables: {
        input: {
          id: workspaceBoardSuggestionId,
        },
      },
    });
  }, [deleteSuggestionMutation, workspaceBoardSuggestionId]);

  // Update mutation
  const [updateSuggestionMutation] = useUpdateWorkspaceBoardSuggestionMutation();
  const updateSuggestionStatus = useCallback(
    (status: SuggestionStatus) => {
      updateSuggestionMutation({
        variables: {
          input: {
            id: workspaceBoardSuggestionId,
            status,
          },
        },
      });
    },
    [updateSuggestionMutation, workspaceBoardSuggestionId],
  );

  // Revert mutation
  const revertSuggestionRequestId = `workspace-board-shape-revert-suggestion-${workspaceBoardSuggestionId}`;
  const revertSuggestion = useCallback(async () => {
    // Scroll to the first block of the suggestion
    scrollDocumentToFirstBlockId();

    const command = {
      ...COMMAND_REVERT_BOARD_QUESTIONNAIRE_SUGGESTION_TEMPLATE,
    };

    // Create the overrides/values that are not set automatically by CommandExecutor
    const commandRequest: Partial<ICommandRequest> = {
      commandContext: {
        ...CommandContext.getCommandContext(),
        selection: {
          workspaceBoardSuggestionId: workspaceBoardSuggestionId,
        },
      },
      deduplicationId: revertSuggestionRequestId,
      requestId: revertSuggestionRequestId,
    };

    await commandExecutor.executeCommand(command, commandRequest);
  }, [scrollDocumentToFirstBlockId, commandExecutor, revertSuggestionRequestId, scrollDocumentToFirstBlockId]);

  // Accept mutation
  const applySuggestionRequestId = `workspace-board-shape-apply-suggestion-${workspaceBoardSuggestionId}`;
  const applySuggestion = useCallback(async () => {
    // Scroll to the first block of the suggestion
    scrollDocumentToFirstBlockId();

    const command = {
      ...COMMAND_ACCEPT_BOARD_QUESTIONNAIRE_SUGGESTION_TEMPLATE,
    };

    // Create the overrides/values that are not set automatically by CommandExecutor
    const commandRequest: Partial<ICommandRequest> = {
      commandContext: {
        ...CommandContext.getCommandContext(),
        selection: {
          workspaceBoardSuggestionId: workspaceBoardSuggestionId,
        },
      },
      deduplicationId: applySuggestionRequestId,
      requestId: applySuggestionRequestId,
    };

    await commandExecutor.executeCommand(command, commandRequest);
  }, [commandExecutor, applySuggestionRequestId, scrollDocumentToFirstBlockId]);

  return (
    <Box sx={{ marginLeft: "auto" }}>
      {(workspaceBoardSuggestionCreatedBy === user.id || hasDeletePermissions) &&
        relatedActions.includes(SuggestionStatusAction.Delete) && (
          <Button color="danger" onClick={deleteSuggestion} disabled={deleteLoading}>
            {t("Components.WorkspaceBoardSuggestion.Action.Delete")}
          </Button>
        )}
      {relatedActions.includes(SuggestionStatusAction.Dismiss) && (
        <Button onClick={() => updateSuggestionStatus(SuggestionStatus.Dismissed)}>
          {t("Components.WorkspaceBoardSuggestion.Action.Dismiss")}
        </Button>
      )}
      {relatedActions.includes(SuggestionStatusAction.Revert) && (
        <Button
          color="info"
          onClick={revertSuggestion}
          variant="contained"
          sx={{
            backgroundColor: "action.disabledBackground",
            color: "text.disabled",
            "&:hover": {
              backgroundColor: "action.disabledBackground",
            },
            marginLeft: "12px",
          }}
        >
          {t("Components.WorkspaceBoardSuggestion.Action.Revert")}
        </Button>
      )}
      {relatedActions.includes(SuggestionStatusAction.Accept) && (
        <Button onClick={applySuggestion} variant="contained" sx={{ marginLeft: "12px" }}>
          {t("Components.WorkspaceBoardSuggestion.Action.Accept")}
        </Button>
      )}
    </Box>
  );
}
