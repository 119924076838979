import { TabContext, TabPanel, TabPanelProps } from "@mui/lab";
import { Button, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { ReactNode, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import AppSidebarIcon from "Assets/Icons/app-sidebar-icon.svg";
import { ChatPane } from "Chat/ChatPane/ChatPane";
import { useIsChatEnabled } from "Chat/Hooks";
import { ResizePanel } from "Components/Resizer/ResizePanel";
import {
  ChatMessageChannelType,
  useUpsertWorkspacesPageUserPreferencesMutation,
  useWorkspacesPageUserPreferencesQuery,
} from "GraphQL/Generated/Apollo";
import { IRightSidebarContentProps, RightSidebarContent } from "./RightSidebarContent";
import { RightSidebarHeader } from "./RightSidebarHeader";
import { RightSidebarTab } from "./RightSidebarTab";
import { RightSidebarTabs } from "./RightSidebarTabs";

/***********************************
 * Interface
 ***********************************/
export interface IAppRightSidebarTab {
  children: React.ReactNode;
  tabId: string;
  title: string | ReactNode;
  isFixed: boolean;
  isPinned: boolean;
  allowPinning: boolean;
  sx?: TabPanelProps["sx"];
}

export interface IAppRightSidebarProps {
  selectedShapeId?: string;
  slotProps?: {
    RightSidebarContent?: {
      sx?: IRightSidebarContentProps["sx"];
    };
    TabPanel?: Partial<TabPanelProps>;
  };
  workspaceId: string;
}

/***********************************
 * Constants
 ***********************************/
export const APP_RIGHT_SIDEBAR_DOCUMENT_TAB_CONTENT_ID = (shapeId: string) => `app-right-sidebar-content-${shapeId}`;
export const APP_RIGHT_SIDEBAR_DOCUMENT_TAB_TITLE_ID = (shapeId: string) => `app-right-sidebar-title-${shapeId}`;
export const APP_SIDEBAR_ICON_HEIGHT = 25;
export const APP_SIDEBAR_ICON_WIDTH = 25;
const DEFAULT_WIDTH = 490;
const MIN_WIDTH = 200;
const MAX_WIDTH = 720;

/***********************************
 * Enums
 ***********************************/
enum AppRightSidebarTab {
  Chat = "Chat",
  Shape = "Shape",
}

/***********************************
 * Component
 ***********************************/
export function AppRightSidebar(props: IAppRightSidebarProps) {
  const { slotProps, workspaceId, selectedShapeId } = props;

  const isChatEnabled = useIsChatEnabled();
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation();

  const { data: sidebarPreferences } = useWorkspacesPageUserPreferencesQuery({
    variables: {
      key: location.pathname,
    },
  });
  const [upsertUserPreference] = useUpsertWorkspacesPageUserPreferencesMutation({
    // Update cache directly since the server will not return the fully cascaded data, just the user portion
    // This ensures any default and org-level preferences are not lost
    update: (cache, data) => {
      cache.modify({
        fields: {
          userPreference(existing = {}) {
            return {
              ...existing,
              data: {
                ...existing.data,
                ...data.data?.upsertUserPreference.data,
              },
            };
          },
        },
      });
    },
  });

  // Persists the user preferences. NOTE: This upserts with a patch, so we don't need to send all the preferences
  const updatePreferences = useCallback(
    (open: boolean, width: number) => {
      upsertUserPreference({
        variables: {
          input: {
            key: location.pathname,
            data: {
              open,
              sidebarWidth: width,
            },
          },
        },
      });
    },
    [upsertUserPreference, location.key],
  );

  // State
  const [childrenWidth, setChildrenWidth] = useState<number>(
    sidebarPreferences?.userPreference?.data?.sidebarWidth ?? DEFAULT_WIDTH,
  );
  const [open, setOpen] = useState(sidebarPreferences?.userPreference?.data?.open ?? false);
  const [tabs, setTabs] = useState<Array<IAppRightSidebarTab>>([]);
  const [pinnedShapeIds, setPinnedShapeIds] = useState<Array<string>>([]);
  const [activeShapeId, setActiveShapeId] = useState<string | undefined>(undefined);
  const [activeTabId, setActiveTabId] = useState<string | undefined>(tabs.length > 0 ? tabs[0].tabId : undefined);

  const tooltipTitle = open ? t("Components.AppSidebar.Collapse") : t("Components.AppSidebar.Expand");

  const debouncedSetChildrenWidth = useDebouncedCallback((width: number) => {
    setChildrenWidth(width);
    updatePreferences(open, width);
  }, 50);

  useEffect(() => {
    setOpen(sidebarPreferences?.userPreference?.data?.open ?? false);
    setChildrenWidth(sidebarPreferences?.userPreference?.data?.sidebarWidth ?? DEFAULT_WIDTH);
  }, [sidebarPreferences]);

  useEffect(() => {
    if (selectedShapeId && activeShapeId !== selectedShapeId) {
      setActiveShapeId(selectedShapeId);
      setActiveTabId(selectedShapeId);
    }
  }, [selectedShapeId]);

  useEffect(() => {
    const shapeTabDetails: (
      shapeId: string,
      isFixed: boolean,
      isPinned: boolean,
      allowPinning: boolean,
    ) => IAppRightSidebarTab = (shapeId: string, isFixed: boolean, isPinned: boolean, allowPinning: boolean) => ({
      tabId: shapeId,
      title: (
        <Box key={shapeId} id={shapeId ? APP_RIGHT_SIDEBAR_DOCUMENT_TAB_TITLE_ID(shapeId) : undefined}>
          <Typography
            variant="button"
            sx={{
              // Hide the message if the shape has companion content, show it otherwise
              display: "none",
              "&:only-child": {
                display: "flex",
              },
            }}
          >
            {t(`Components.AppRightSidebar.Tabs.${AppRightSidebarTab.Shape}.Title`)}
          </Typography>
        </Box>
      ),
      children: (
        <Box key={shapeId} id={shapeId ? APP_RIGHT_SIDEBAR_DOCUMENT_TAB_CONTENT_ID(shapeId) : undefined}>
          <Typography
            variant="body1"
            sx={{
              padding: "20px 24px 0",
              // Hide the message if the shape has companion content, show it otherwise
              display: "none",
              "&:only-child": {
                display: "flex",
              },
            }}
          >
            {t(`Components.AppRightSidebar.Tabs.${AppRightSidebarTab.Shape}.NoContent`)}
          </Typography>
        </Box>
      ),
      sx: {
        p: 0,
        height: "100%",
      },

      isFixed,
      allowPinning,
      isPinned,
    });

    const tabs: Array<IAppRightSidebarTab> = isChatEnabled
      ? [
          {
            tabId: AppRightSidebarTab.Chat,
            title: t(`Components.AppRightSidebar.Tabs.${AppRightSidebarTab.Chat}.Title`),
            children: <ChatPane channelId={workspaceId} channelType={ChatMessageChannelType.Workspace} />,
            sx: {
              p: 0,
              height: "100%",
            },

            isFixed: true,
            isPinned: false,
            allowPinning: false,
          },
        ]
      : [];

    tabs.push(...pinnedShapeIds.map((shapeId) => shapeTabDetails(shapeId, false, true, true)));

    if (activeShapeId) {
      if (pinnedShapeIds.indexOf(activeShapeId) === -1) {
        tabs.push(shapeTabDetails(activeShapeId, false, false, true));
      } else {
        setActiveTabId(activeShapeId);
      }
    }

    setTabs(tabs);
  }, [activeShapeId, pinnedShapeIds, t, workspaceId]);

  useEffect(() => {
    if (!activeTabId && tabs.length > 0) {
      setActiveTabId(tabs[0].tabId);
    }
  }, [activeTabId, tabs]);

  const toggleTabPinning = useCallback(
    (tabId: string) => {
      const shapeIndex = pinnedShapeIds.indexOf(tabId);
      if (shapeIndex === -1) {
        setPinnedShapeIds((prev) => [...prev, tabId]);
      } else {
        setPinnedShapeIds((prev) => prev.filter((id) => id !== tabId));
      }
    },
    [pinnedShapeIds],
  );

  if (tabs.length === 0) {
    return null;
  }

  return (
    <Box>
      {open && (
        <ResizePanel
          defaultWidth={DEFAULT_WIDTH}
          minWidth={MIN_WIDTH}
          maxWidth={MAX_WIDTH}
          width={childrenWidth}
          onWidthChange={debouncedSetChildrenWidth}
          anchor="right"
        >
          <Box
            sx={{ borderLeft: `1px solid ${theme.palette.divider}`, display: "flex", flexDirection: "column", width: "100%" }}
            component="aside"
          >
            {tabs.length > 1 ? (
              <>
                <RightSidebarHeader>
                  <RightSidebarTabs
                    value={activeTabId ?? tabs[0].tabId}
                    onChange={(_event: React.SyntheticEvent, newValue: string) => {
                      // Event?
                      setActiveTabId(newValue);
                    }}
                    sx={{ flexGrow: 1, pr: 8 }}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {tabs.map((tab) => (
                      <RightSidebarTab
                        key={tab.tabId}
                        label={tab.title}
                        value={tab.tabId}
                        isFixed={tab.isFixed}
                        isPinned={tab.isPinned}
                        allowPinning={tab.allowPinning}
                        toggleTabPinning={() => toggleTabPinning(tab.tabId)}
                      />
                    ))}
                  </RightSidebarTabs>
                </RightSidebarHeader>
                <RightSidebarContent {...(slotProps ? slotProps.RightSidebarContent : {})}>
                  <TabContext value={activeTabId ?? tabs[0].tabId}>
                    {tabs.map((tab) => (
                      <TabPanel key={tab.tabId} value={tab.tabId} sx={tab.sx}>
                        {tab.children}
                      </TabPanel>
                    ))}
                  </TabContext>
                </RightSidebarContent>
              </>
            ) : (
              <RightSidebarContent {...(slotProps ? slotProps.RightSidebarContent : {})}>{tabs[0]?.children}</RightSidebarContent>
            )}
          </Box>
        </ResizePanel>
      )}
      <Tooltip title={tooltipTitle} placement="left-start">
        <Button
          onClick={onToggleSidebar}
          sx={{ position: "fixed", top: "0.1%", right: "0.1%", zIndex: "var(--layer-sidebar-toggle-icon)" }}
        >
          <img
            src={AppSidebarIcon}
            height={APP_SIDEBAR_ICON_HEIGHT}
            width={APP_SIDEBAR_ICON_WIDTH}
            alt={tooltipTitle}
            style={{ filter: "invert(0.4)" }}
          />
        </Button>
      </Tooltip>
    </Box>
  );

  function onToggleSidebar() {
    setOpen(!open);
    updatePreferences(!open, DEFAULT_WIDTH);
  }
}
