import { createShapePropsMigrationIds, createShapePropsMigrationSequence } from "@tldraw/tlschema";

export const HtmlDocumentShapeVersions = createShapePropsMigrationIds("htmlDocument", {
  RemoveOpacity: 1,
  AddCanScroll: 2,
  AddAsyncUpdateLock: 3,
});

export const htmlDocumentShapeMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: HtmlDocumentShapeVersions.RemoveOpacity,
      up(props) {
        const { opacity, ...rest } = props;
        Object.assign(props, rest);
      },
      down(props) {
        props.opacity = true;
      },
    },
    {
      id: HtmlDocumentShapeVersions.AddCanScroll,
      up(props) {
        props.canScroll = true;
      },
      down(props) {
        delete props.canScroll;
      },
    },
    {
      id: HtmlDocumentShapeVersions.AddAsyncUpdateLock,
      up(props) {
        props.asyncUpdateLock = false;
      },
      down(props) {
        delete props.asyncUpdateLock;
      },
    },
  ],
});
