import { IAnalysisFieldConfig } from "@bigpi/cookbook";
import { IAnalysisFacets } from "BoardComponents/Types";

import { useValue } from "tldraw";

/**
 * Validates the fields which should actually go to backend via GraphQL
 * Mainly to support facets which can stay on just client side not necessarily connect to backend
 *
 * @param facets Facets to validate
 * @param fieldsConfig Each field config for the facet fields
 * @returns Valid facets which can be passed to GraphQL Query
 */
export function useQueryValidFacets(facets: IAnalysisFacets, fieldsConfig: Array<IAnalysisFieldConfig>) {
  const validFacets = useValue(
    "validMergeFacets",
    () => {
      return getQueryValidFacetValues(facets, fieldsConfig);
    },
    [facets],
  );
  return validFacets;
}

/**************************************
 * Utils
 **************************************/
/**
 * Validates the facet applied fields has config, applied value and not on client side
 *
 * @param facets Facets to validate
 * @param fieldsConfig Fields config to verify the field config exists
 * @returns Valid facets which has config, value & not on client side fields
 */
export function getQueryValidFacetValues(facets: IAnalysisFacets, fieldsConfig: Array<IAnalysisFieldConfig>) {
  const validFacets: Record<string, IAnalysisFacets> = {};
  Object.keys(facets).forEach((facetField) => {
    const fieldConfig = fieldsConfig.find((fieldConfig) => fieldConfig.field === facetField);
    const facetValue = facets[facetField as keyof IAnalysisFacets];
    // Checks whether fieldConfig is available & isClientSide is false
    if (fieldConfig && !fieldConfig.isClientSide && facetValue) {
      validFacets[facetField] = facetValue as IAnalysisFacets;
    }
  });
  return validFacets;
}
