import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { Box, Card, CardActions, CardContent, IconButton, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactTimeAgo from "react-time-ago";

import { HasWorkspaceRole } from "Components/HasWorkspaceRole/HasWorkspaceRole";
import { ObjectRole, useImageTokenQuery, useWorkspaceBoardSuggestionQuery } from "GraphQL/Generated/Apollo";
import { ExpandableSuggestionContent } from "./ExpandableSuggestedContent";
import { SuggestionStatusChip } from "./SuggestionStatusChip";
import { SuggestionStatusActions } from "./SuggestionStatusActions";

import "./WorkspaceBoardSuggestion.css";
import { SuggestionReference } from "./SuggestionReference";

// *********************************************
// Interfaces
// *********************************************/
export interface IWorkspaceBoardSuggestionProps {
  scrollDocumentToBlockId: (blockId: string) => void;
  workspaceId: string;
  workspaceBoardSuggestionId: string;
}

// *********************************************
// Public methods
// *********************************************/
export function WorkspaceBoardSuggestion(props: IWorkspaceBoardSuggestionProps) {
  const { scrollDocumentToBlockId, workspaceId, workspaceBoardSuggestionId } = props;

  const { t } = useTranslation();
  const { data: imageTokenData } = useImageTokenQuery();

  const parseAndAppendImageToken = useCallback((htmlContent: string, imageToken: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const images = doc.querySelectorAll("img");
    images.forEach((img) => {
      const url = img.getAttribute("src");
      if (url) {
        try {
          const parsedUrl = new URL(url);
          parsedUrl.searchParams.set("t", imageToken);
          img.setAttribute("src", parsedUrl.href);
        } catch (e) {
          console.error(`Invalid URL encountered "${url}"`, e);
        }
      }
    });

    return doc.body.innerHTML;
  }, []);

  const { data } = useWorkspaceBoardSuggestionQuery({
    variables: {
      id: workspaceBoardSuggestionId,
    },
    skip: !workspaceBoardSuggestionId,
  });

  const processedWorkspaceSuggestion = useMemo(() => {
    if (!data?.workspaceBoardSuggestion) {
      return "";
    }
    const suggestion = data.workspaceBoardSuggestion;

    if (!imageTokenData?.imageToken.token) {
      return suggestion.suggestedContent;
    }

    return parseAndAppendImageToken(suggestion.suggestedContent, imageTokenData.imageToken.token);
  }, [data, imageTokenData, parseAndAppendImageToken]);

  const scrollDocumentToFirstBlockId = useCallback(() => {
    if (!data?.workspaceBoardSuggestion) {
      return;
    }

    if (data.workspaceBoardSuggestion.blockIds.length === 0) {
      return;
    }

    const firstBlockId = data.workspaceBoardSuggestion.blockIds[0];
    scrollDocumentToBlockId(firstBlockId);
  }, [data, scrollDocumentToBlockId]);

  if (!data?.workspaceBoardSuggestion) {
    return null;
  }

  return (
    <Card
      sx={{
        boxShadow: "none",
        mt: 1,
        pb: 2,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
        <Box>
          <Tooltip title={t("Components.WorkspaceBoardSuggestion.ScrollIntoView.Tooltip")}>
            <IconButton onClick={scrollDocumentToFirstBlockId}>
              <ReadMoreIcon sx={{ transform: "rotate(180deg)" }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="caption" sx={{ mr: 1 }}>
            <ReactTimeAgo date={new Date(data.workspaceBoardSuggestion.updatedAt)} timeStyle="minute-now" />
          </Typography>
          <SuggestionStatusChip
            label={t(`Components.WorkspaceBoardSuggestion.Status.${data.workspaceBoardSuggestion.status}`)}
            status={data.workspaceBoardSuggestion.status}
          />
        </Box>
      </Stack>
      <CardContent
        sx={{
          border: "solid 1px #666",
          borderRadius: 2,
          "&:last-child": {
            paddingBottom: 1,
          },
        }}
        className="allow-selection"
      >
        <ExpandableSuggestionContent status={data.workspaceBoardSuggestion.status} content={processedWorkspaceSuggestion} />
        <hr style={{ border: "none", borderTop: "solid 1px var(--astra-color-gray40)" }} />
        <Typography variant="caption" dangerouslySetInnerHTML={{ __html: data.workspaceBoardSuggestion.suggestionDescription }} />
        {data.workspaceBoardSuggestion.references.length > 0 && (
          <>
            <Typography variant="h6">
              {t("Components.WorkspaceBoardSuggestion.Sources", { count: data.workspaceBoardSuggestion.references.length })}
            </Typography>
            <ul style={{ listStyle: "none", paddingLeft: "8px" }}>
              {data.workspaceBoardSuggestion.references.map((reference, index) => (
                <li key={index}>
                  <SuggestionReference
                    excerpt={reference.excerpt}
                    sourceUri={reference.sourceUri}
                    title={reference.title}
                    workspaceId={workspaceId}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        {data.workspaceBoardSuggestion.statusDescription && (
          <Typography variant="caption">{data.workspaceBoardSuggestion.statusDescription}</Typography>
        )}
        <CardActions disableSpacing sx={{ pl: 0 }}>
          <HasWorkspaceRole
            workspaceId={workspaceId}
            roles={[ObjectRole.ContentManager, ObjectRole.Contributor, ObjectRole.Manager, ObjectRole.Owner]}
          >
            <SuggestionStatusActions
              scrollDocumentToFirstBlockId={scrollDocumentToFirstBlockId}
              status={data.workspaceBoardSuggestion.status}
              workspaceId={workspaceId}
              workspaceBoardSuggestionId={data.workspaceBoardSuggestion.id}
              workspaceBoardSuggestionCreatedBy={data.workspaceBoardSuggestion.createdBy}
            />
          </HasWorkspaceRole>
        </CardActions>
      </CardContent>
    </Card>
  );
}
