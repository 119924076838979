import { StateNode } from "tldraw";

import { Idle, PointingCanvas, PointingShape } from "./Children";

export class InteractTool extends StateNode {
  static override id = "interact";
  static override initial = "idle";
  reactor: undefined | (() => void) = undefined;

  static override children = () => [Idle, PointingCanvas, PointingShape];

  override onEnter = () => {
    this.editor.setSelectedShapes([]);
    this.editor.setEditingShape(null);
  };

  override onExit = () => {
    this.editor.setHoveredShape(null);
  };
}
