import { gql } from "@apollo/client";

export const COMMAND_STATUS_QUERY = gql`
  query CommandStatus($id: ID!) {
    commandStatus(id: $id) {
      commandId
      requestId
      status
    }
  }
`;
