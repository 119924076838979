import { RecordProps } from "@tldraw/tlschema";
import { T } from "@tldraw/validate";

import { IGroupBubbleChartShape } from "./IGroupBubbleChartShape.js";

// *********************************************
// Private constants
// *********************************************/
/**
 * The default width of the shape.
 */
const DEFAULT_WIDTH = 3888;

/**
 * The default height of the shape.
 */
const DEFAULT_HEIGHT = 1000;

// *********************************************
// Public constants
// *********************************************/
/**
 * Gets the default props for the shape.
 *
 * @returns The default props.
 */
export const getGroupBubbleChartShapeDefaultProps = (): IGroupBubbleChartShape["props"] => {
  return {
    w: DEFAULT_WIDTH,
    h: DEFAULT_HEIGHT,
  };
};

/**
 * The shape props schema definition.
 */
export const groupBubbleChartShapeProps: RecordProps<IGroupBubbleChartShape> = {
  h: T.nonZeroNumber,
  w: T.nonZeroNumber,
};
