import {
  ArrangeMenuSubmenu,
  ClipboardMenuGroup,
  ConvertToBookmarkMenuItem,
  ConvertToEmbedMenuItem,
  DefaultContextMenu,
  EditLinkMenuItem,
  FitFrameToContentMenuItem,
  GroupMenuItem,
  RemoveFrameMenuItem,
  ReorderMenuSubmenu,
  SelectAllMenuItem,
  TldrawUiMenuGroup,
  ToggleAutoSizeMenuItem,
  ToggleLockMenuItem,
  UngroupMenuItem,
  useEditor,
  useValue,
} from "tldraw";
import { memo } from "react";

/**
 * Cloned from https://github.com/tldraw/tldraw/blob/41b5fffa2ef17ff852c1efc227a5ad5c37dc5c7a/packages/tldraw/src/lib/ui/components/ContextMenu/DefaultContextMenuContent.tsx#L22
 * and made some modifications. Changes -
 *
 * - Removed `MoveToPageMenu` from `modify` group.
 * - Removed `ConversionsMenuGroup` from the context menu.
 */
export function ContextMenuContent() {
  const editor = useEditor();

  const selectToolActive = useValue("isSelectToolActive", () => editor.getCurrentToolId() === "select", [editor]);

  if (!selectToolActive) {
    return null;
  }

  return (
    <>
      <TldrawUiMenuGroup id="misc">
        <GroupMenuItem />
        <UngroupMenuItem />
        <EditLinkMenuItem />
        <ToggleAutoSizeMenuItem />
        <RemoveFrameMenuItem />
        <FitFrameToContentMenuItem />
        <ConvertToEmbedMenuItem />
        <ConvertToBookmarkMenuItem />
        <ToggleLockMenuItem />
      </TldrawUiMenuGroup>

      <TldrawUiMenuGroup id="modify">
        <ArrangeMenuSubmenu />
        <ReorderMenuSubmenu />
        {/* Modified by BigPi - Removed `MoveToPageMenu` from `modify` group.
        <MoveToPageMenu /> */}
      </TldrawUiMenuGroup>

      <ClipboardMenuGroup />

      {/* Modified by BigPi - Removed `ConversionsMenuGroup` from the context menu.
      <ConversionsMenuGroup /> */}

      <TldrawUiMenuGroup id="select-all">
        <SelectAllMenuItem />
      </TldrawUiMenuGroup>
    </>
  );
}

export const ContextMenu = memo(function CustomContextMenu() {
  return (
    <DefaultContextMenu>
      <ContextMenuContent />
    </DefaultContextMenu>
  );
});
