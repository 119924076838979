import { FileSourceType } from "@bigpi/cookbook";
import { useFileQuery, useWorkspaceFileQuery } from "GraphQL/Generated/Apollo";

export function useFileDetails(id: string, fileType: FileSourceType) {
  const { data: fileDetails } = useFileQuery({
    variables: {
      id,
    },
    skip: fileType === FileSourceType.WorkspaceFile,
  });

  const { data: workspaceFileDetails } = useWorkspaceFileQuery({
    variables: {
      id,
    },
    skip: fileType === FileSourceType.Library,
  });
  return fileType === FileSourceType.WorkspaceFile ? workspaceFileDetails?.workspaceFile?.metadata : fileDetails?.file?.metadata;
}
