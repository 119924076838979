import { CONFIG_KEY_ORGANIZATION_PREFERENCES } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import { useValue } from "tldraw";

import { useGetConfigDataQuery } from "GraphQL/Generated/Apollo";

/**
 * Hook to represent the app level isDocumentCompanionEnabled config.
 */
export function useIsDocumentCompanionEnabled() {
  const { data: appConfigDataResult } = useGetConfigDataQuery({
    variables: {
      key: CONFIG_KEY_ORGANIZATION_PREFERENCES,
      organizationId: useAuthUser()?.tenantId,
    },
  });
  const isDocumentCompanionEnabled = useValue(
    "isDocumentCompanionEnabled",
    () => {
      const configData = JSON.parse(appConfigDataResult?.Config?.data || "{}");
      if (configData.isDocumentCompanionEnabled === true) {
        return true;
      }

      return false;
    },
    [appConfigDataResult],
  );
  return isDocumentCompanionEnabled;
}
