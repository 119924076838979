import { findAll } from "domutils";
import { parseDocument } from "htmlparser2";

/**
 * Get the block IDs in order from the content HTML.
 *
 * @param contentHtml The content HTML
 *
 * @returns The block IDs in order
 */
export function getBlockIdsInOrderOfAppearance(contentHtml: string): Array<string> {
  const dom = parseDocument(contentHtml);
  const elements = findAll((elem) => {
    return !!elem.attribs["data-id"];
  }, dom.children);

  return elements.map((elem) => {
    return elem.attribs["data-id"];
  });
}

/**
 * Check if a block ID exists in the content HTML.
 *
 * @param contentHtml The content HTML
 * @param blockId The block ID to check
 *
 * @returns `true` if the block ID exists in the content HTML, `false` otherwise
 */
export function isBlockIdPresentInHtml(contentHtml: string, blockId: string): boolean {
  if (!blockId || !blockId.trim()) {
    return false;
  }

  const dom = parseDocument(contentHtml);
  const elements = findAll((elem) => {
    return !!elem.attribs["data-id"] && elem.attribs["data-id"] === blockId;
  }, dom.children);

  return elements.length > 0;
}
