import { RecordProps } from "@tldraw/tlschema";
import { T } from "@tldraw/validate";

import { ITopicDiscussionAnalysisShape } from "./ITopicDiscussionAnalysisShape.js";
import { TopicDiscussionAnalysisFieldsEnum } from "./ITopicDiscussionAnalysisFacets.js";
import { dataGridPreferencesValidatable } from "../DataGrid/index.js";
import { analysisPreferencesFacetSortValidatable } from "../../Common.js";

// *********************************************
// Private constants
// *********************************************/
/**
 * The default width of the shape.
 */
const DEFAULT_WIDTH = 500;

/**
 * The default height of the shape.
 */
const DEFAULT_HEIGHT = 500;

// *********************************************
// Public constants
// *********************************************/
/**
 * Gets the default props for the shape.
 *
 * @returns The default props.
 */
export const getTopicDiscussionAnalysisShapeDefaultProps = (): ITopicDiscussionAnalysisShape["props"] => {
  return {
    boundsFacetValues: {},
    enableBackground: true,
    selectedFacetValues: {},
    h: DEFAULT_HEIGHT,
    preferences: {},
    w: DEFAULT_WIDTH,
    selection: {},
  };
};

/**
 * The shape props schema definition.
 */
export const topicDiscussionAnalysisShapeProps: RecordProps<ITopicDiscussionAnalysisShape> = {
  selectedBoundsFields: T.arrayOf(
    T.setEnum(
      new Set([
        TopicDiscussionAnalysisFieldsEnum.Category,
        TopicDiscussionAnalysisFieldsEnum.EventDate,
        TopicDiscussionAnalysisFieldsEnum.EventDateShortcut,
        TopicDiscussionAnalysisFieldsEnum.Section,
        TopicDiscussionAnalysisFieldsEnum.Ticker,
        TopicDiscussionAnalysisFieldsEnum.Topic,
        TopicDiscussionAnalysisFieldsEnum.Type,
      ]),
    ),
  ).optional(),
  selectedFacetValues: T.object({
    category: T.arrayOf(T.string).optional(),
    eventDate: T.object({
      from: T.string.optional(),
      to: T.string.optional(),
    }).optional(),
    eventDateShortcut: T.string.optional(),
    section: T.arrayOf(T.string).optional(),
    ticker: T.arrayOf(T.string).optional(),
    topic: T.arrayOf(T.string).optional(),
    type: T.arrayOf(T.string).optional(),
  }),
  w: T.nonZeroInteger.optional(),
  h: T.nonZeroInteger.optional(),
  enableBackground: T.boolean.optional(),
  preferences: T.object({
    analysis: T.object({
      facetSort: analysisPreferencesFacetSortValidatable,
      showQuestions: T.boolean.optional(),
      startColor: T.string.optional(),
      showDataToDisplayInToolbar: T.boolean.optional(),
    }).optional(),
    dataGrid: dataGridPreferencesValidatable,
  }),
  boundsFacetValues: T.object({
    category: T.arrayOf(T.string).optional(),
    eventDate: T.object({
      from: T.string.optional(),
      to: T.string.optional(),
    }).optional(),
    eventDateShortcut: T.string.optional(),
    section: T.arrayOf(T.string).optional(),
    ticker: T.arrayOf(T.string).optional(),
    topic: T.arrayOf(T.string).optional(),
    type: T.arrayOf(T.string).optional(),
  }),
  toolbar: T.object({
    availableFields: T.arrayOf(
      T.setEnum(
        new Set([
          TopicDiscussionAnalysisFieldsEnum.Category,
          TopicDiscussionAnalysisFieldsEnum.EventDate,
          TopicDiscussionAnalysisFieldsEnum.EventDateShortcut,
          TopicDiscussionAnalysisFieldsEnum.Section,
          TopicDiscussionAnalysisFieldsEnum.Ticker,
          TopicDiscussionAnalysisFieldsEnum.Topic,
          TopicDiscussionAnalysisFieldsEnum.Type,
        ]),
      ),
    ).optional(),
  }).optional(),
  selection: T.object({
    expandedGroups: T.arrayOf(T.string).optional(),
    selectedGroup: T.string.optional(),
    selectedItem: T.string.optional(),
    selectedXAxis: T.string.optional(),
  }).optional(),
};
