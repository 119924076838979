import { Cancel, Delete, Edit, Save } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import {
  GridRowModesModel,
  GridRowModes,
  DataGridPremium,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from "@mui/x-data-grid-premium";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface IFieldsDataGridProps {
  fields: Record<string, string>;
  onChange: (fields: Record<string, string>) => void;
}

type InternalRowType = {
  id: string;
  fieldName: string;
  fieldValue: string;
  isNew: boolean;
};

export function FieldsDataGrid(props: IFieldsDataGridProps) {
  const { fields, onChange } = props;
  const [rows, setRows] = useState<Array<InternalRowType>>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const { t } = useTranslation();

  useEffect(() => {
    const newRows = Object.entries(fields).map(([key, value], index) => ({
      id: index.toString(),
      fieldName: key,
      fieldValue: value,
      isNew: false,
    }));
    setRows(newRows);
  }, [fields]);

  const onAddNewRow = useCallback(() => {
    const newRow = {
      id: rows.length.toString(),
      fieldName: "",
      fieldValue: "",
      isNew: true,
    };
    setRows((oldState) => {
      return [...oldState, newRow];
    });
    setRowModesModel((oldState) => {
      return { ...oldState, [newRow.id]: { mode: GridRowModes.Edit, fieldToFocus: "fieldName" } };
    });
  }, [rows]);

  const onSaveRows = useCallback(
    (rows: Array<InternalRowType>) => {
      const newFields = rows.reduce(
        (acc, row) => {
          acc[row.fieldName] = row.fieldValue;
          return acc;
        },
        {} as Record<string, string>,
      );
      onChange(newFields);
    },
    [onChange],
  );

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel((oldState) => {
        return { ...oldState, [id]: { mode: GridRowModes.Edit } };
      });
    },
    [],
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel((oldState) => {
        return { ...oldState, [id]: { mode: GridRowModes.View } };
      });
    },
    [rows],
  );

  const handleDeleteClick = useCallback(
    (id: GridRowId) => () => {
      const newRows = rows.filter((row) => row.id !== id);
      setRows(newRows);

      onSaveRows(newRows);
    },
    [rows, onSaveRows],
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel((oldState) => {
        return { ...oldState, [id]: { mode: GridRowModes.View, ignoreModifications: true } };
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow && editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    },
    [rows, rowModesModel],
  );

  const processRowUpdate = useCallback(
    (newRow: GridRowModel) => {
      const updatedRow = { ...newRow, isNew: false } as InternalRowType;
      const newRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
      setRows(newRows);
      onSaveRows(newRows);
      return updatedRow;
    },
    [rows],
  );

  const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  }, []);

  const columns: GridColDef[] = useMemo(() => {
    return [
      { field: "fieldName", headerName: t("Components.EditFileDetailsDialog.FieldLabels.Field"), flex: 1, editable: true },
      { field: "fieldValue", headerName: t("Components.EditFileDetailsDialog.FieldLabels.Value"), flex: 1, editable: true },
      {
        field: "actions",
        type: "actions",
        headerName: "",
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<Save />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<Cancel />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem icon={<Delete />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />,
          ];
        },
      },
    ];
  }, [handleEditClick, handleSaveClick, handleDeleteClick, handleCancelClick, rowModesModel, t]);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: 200,
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Button onClick={onAddNewRow} color="primary">
        {t("Components.EditFileDetailsDialog.AddRow")}
      </Button>
      <DataGridPremium
        sx={{ minHeight: 200 }}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        hideFooter
        disableColumnResize
        disableAggregation
        disableColumnFilter
        disableRowGrouping
      />
    </Box>
  );
}
