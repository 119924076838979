import { createShapePropsMigrationIds, createShapePropsMigrationSequence } from "@tldraw/tlschema";

export const InlineFrameShapeVersions = createShapePropsMigrationIds("inlineFrame", {
  RemoveOpacity: 1,
  AddCanScroll: 2,
});

export const inlineFrameShapeMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: InlineFrameShapeVersions.RemoveOpacity,
      up(props) {
        const { opacity, ...rest } = props;
        Object.assign(props, rest);
      },
      down(props) {
        props.opacity = true;
      },
    },
    {
      id: InlineFrameShapeVersions.AddCanScroll,
      up(props) {
        props.canScroll = true;
      },
      down(props) {
        delete props.canScroll;
      },
    },
  ],
});
