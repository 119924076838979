import { createShapePropsMigrationIds, createShapePropsMigrationSequence } from "@tldraw/tlschema";

export const FilePreviewShapeVersions = createShapePropsMigrationIds("filePreview", {
  AddFileName: 1,
});

export const filePreviewShapeMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: FilePreviewShapeVersions.AddFileName,
      up(props) {
        props.fileName = "";
      },
      down(props) {
        delete props.fileName;
      },
    },
  ],
});
