import { gql } from "@apollo/client";
import { IDateRange } from "@bigpi/cookbook";
import { IFeedShape, feedShapeMigrations, feedShapeProps } from "@bigpi/tl-schema";
import { HTMLContainer, TLShapeUtilCanBindOpts, useEditor, useValue } from "tldraw";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { BoxBaseUtil } from "BoardComponents/BaseShapes/BoxBaseUtil";
import { useIsInteracting } from "BoardComponents/Tools";
import { useShapeEvents } from "BoardComponents/useShapeEvents";
import { DataDateRangePicker } from "Components/Charting/Elements/DataDateRangePicker";
import { FeedTranscriptsTranscriptQuery, SortOrder, useFeedTranscriptsTranscriptQuery } from "GraphQL/Generated/Apollo";
import { getFormattedDate } from "Utils/DateUtils";
import { useCompanionManager } from "BoardComponents/Companion/useCompanionManager";
import { ThemeDiscussionAnalysisContainer } from "../../Components/ThemeDiscussionAnalysis/ThemeDiscussionAnalysisContainer";
import { DistilledTranscript } from "../../Components/DistilledTranscript/DistilledTranscript";
import { TranscriptQuestionsGrid } from "../../Components/TranscriptQuestionsGrid/TranscriptQuestionsGrid";

// *********************************************
// Private Types
// *********************************************/
type FeedTranscriptType = NonNullable<NonNullable<FeedTranscriptsTranscriptQuery["transcripts"]>["results"]>[0];

// *********************************************
// Public enums
// *********************************************/
export enum FeedContentType {
  Transcripts = "Transcripts",
}

// *********************************************
// Public constants
// *********************************************/
export const DEFAULT_WIDTH = 600;
export const DEFAULT_HEIGHT = 950;

// *********************************************
// Private constants
// *********************************************/
const DEFAULT_PAGE_SIZE = 20;

const TRANSCRIPTS_QUERY = gql`
  query FeedTranscriptsTranscript($filters: TranscriptFilters, $limit: Int, $offset: Int, $orderBy: [TranscriptOrderBy]) {
    transcripts(offset: $offset, limit: $limit, orderBy: $orderBy, filters: $filters) {
      results {
        id
        displaySymbol
        eventType
        itemDate
        title
      }
      status
      totalCount
    }
    transcriptAggregate {
      minItemDate
      maxItemDate
    }
  }
`;

// *********************************************
// Shape Util
// *********************************************/
export class FeedUtil extends BoxBaseUtil<IFeedShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "feed";

  static props = feedShapeProps;

  static migrations = feedShapeMigrations;

  // *********************************************
  // Protected fields
  // *********************************************/
  /**
   * The minimum height of the shape.
   */
  protected minHeight = 192;

  /**
   * The minimum width of the shape.
   */
  protected minWidth = 192;

  /**
   * The maximum height of the shape.
   */
  protected maxHeight = Infinity;

  /**
   * The maximum width of the shape.
   */
  protected maxWidth = Infinity;

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: IFeedShape) => false;

  /**
   * @inheritdoc
   */
  override canScroll = (shape: IFeedShape) => {
    return shape.props.canScroll;
  };

  /**
   * @inheritdoc
   */
  override canResize = (shape: IFeedShape) => true;

  /**
   * @inheritdoc
   */
  override canBind = (options: TLShapeUtilCanBindOpts<IFeedShape>) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: IFeedShape) => true;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): IFeedShape["props"] {
    return {
      w: DEFAULT_WIDTH,
      h: DEFAULT_HEIGHT,
      feedItems: [],
      startDate: "",
      endDate: "",

      canScroll: false,
    };
  }

  /**
   * @inheritdoc
   */
  component(shape: IFeedShape) {
    const { id, type } = shape;

    const companionManager = useCompanionManager();
    const isCompanionOpen = useValue("isCompanionOpen", () => companionManager.isOpen, [companionManager]);
    const tldrawEditor = useEditor();
    const { t } = useTranslation();
    const [eventDate, setEventDate] = useState<IDateRange | undefined>(undefined);
    const [selectedTranscript, setSelectedTranscript] = useState<FeedTranscriptType | undefined>(undefined);

    const contentRef = useRef<HTMLDivElement | null>(null);

    const { data, loading, error, fetchMore } = useFeedTranscriptsTranscriptQuery({
      variables: {
        limit: DEFAULT_PAGE_SIZE,
        offset: 0,
        orderBy: [{ itemDate: SortOrder.Desc }],
        filters: {
          startDate: eventDate?.from,
          endDate: eventDate?.to,
        },
      },
      notifyOnNetworkStatusChange: true,
    });
    const transcripts = data?.transcripts?.results || [];
    const transcriptAggregates = data?.transcriptAggregate;
    const totalCount = data?.transcripts?.totalCount || 0;

    const [infiniteScrollSentryRef] = useInfiniteScroll({
      loading,
      hasNextPage: totalCount > transcripts.length,
      onLoadMore: () => {
        fetchMore({
          variables: {
            offset: transcripts.length,
          },
        });
      },
      disabled: !!error,
    });

    const isEditing = useValue("isEditing", () => tldrawEditor.getCurrentPageState().editingShapeId === id, [tldrawEditor, id]);
    const isInteracting = useIsInteracting(id);
    const isSelected = useValue("isSelected", () => tldrawEditor.getCurrentPageState().selectedShapeIds.includes(id), [
      tldrawEditor,
      id,
    ]);

    // Set active ID if we should show the selected item (companion is open and we're editing, interacting, or selected)
    const activeTranscriptId = isCompanionOpen && (isEditing || isInteracting || isSelected) ? selectedTranscript?.id : undefined;

    const { handleInputPointerDown } = useShapeEvents(shape.id);

    const onListItemClick = useCallback(
      (transcript: FeedTranscriptType) => {
        setSelectedTranscript(transcript);

        companionManager.showCompanion(shape.id, {
          tabs: [
            {
              tabId: "themesSummary",
              title: t("Components.FeedCompanionOverlay.TabLabel.ThemesSummary"),
              children: <ThemeDiscussionAnalysisContainer transcriptId={transcript.id} />,
              actions: [],
            },
            {
              tabId: "transcript",
              title: t("Components.FeedCompanionOverlay.TabLabel.Transcript"),
              children: <DistilledTranscript transcriptId={transcript.id} />,
              actions: [],
            },
            {
              tabId: "questions",
              title: t("Components.FeedCompanionOverlay.TabLabel.Questions"),
              children: <TranscriptQuestionsGrid transcriptId={transcript.id} />,
              actions: [],
            },
          ],
          title: (
            <div style={{ display: "flex" }}>
              <Typography
                variant="h5"
                sx={{ flexGrow: 1, maxWidth: "870px", overflow: "hidden", textOverflow: "ellipsis", textWrap: "nowrap" }}
              >
                {transcript.title ?? ""}
              </Typography>
              <Typography variant="body1" sx={{ textAlign: "end", margin: "auto", ml: "30px" }}>
                {getFormattedDate(transcript.itemDate, t("Formatting.ShortDate"))}
              </Typography>
            </div>
          ),
        });
      },
      [companionManager],
    );

    return (
      <HTMLContainer
        id={shape.id}
        style={{
          background: "#fff",
          border: `1px solid ${isEditing ? "transparent" : "black"}`,
          display: "flex",
          pointerEvents: "all",
          justifyContent: "center",
        }}
      >
        {loading && (
          <Box
            sx={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <div
          style={{
            display: "flex",
            flex: 1,
            overflow: "hidden",
            pointerEvents: isEditing || isInteracting ? "auto" : "none",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <div onPointerDown={handleInputPointerDown} style={{ width: "100%", height: "100%" }}>
            {error || data?.transcripts?.status === "failure" ? (
              <Box sx={{ display: "flex", justifyContent: "space-between", m: "24px" }}>
                <Typography variant="h6" gutterBottom>
                  {t("Components.Feed.FetchFailureMessage")}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", padding: "24px", pb: 0, mb: "24px" }}>
                  <DataDateRangePicker
                    field={t("Components.Feed.EventDate.Label")}
                    dateRange={{
                      min: getFormattedDate(
                        transcriptAggregates?.minItemDate ?? new Date("2023-01-01"),
                        t("Components.Feed.EventDate.Format"),
                      ),
                      max: getFormattedDate(
                        transcriptAggregates?.maxItemDate ?? new Date(),
                        t("Components.Feed.EventDate.Format"),
                      ),
                    }}
                    value={eventDate}
                    isDisabled={false}
                    label={t("Components.Feed.EventDate.Label")}
                    localeText={{ start: t("Components.Feed.EventDate.From"), end: t("Components.Feed.EventDate.To") }}
                    onDateRangeChange={(dateRange) => setEventDate(dateRange)}
                    dateRangePickerSx={{ width: 250 }}
                  />

                  <FormControl>
                    <InputLabel id="content-type-label">{t("Components.Feed.ContentType.Label")}</InputLabel>
                    <Select
                      labelId="content-type-label"
                      id="content-type"
                      value={FeedContentType.Transcripts}
                      label={t("Components.Feed.ContentType.Label")}
                    >
                      <MenuItem value={FeedContentType.Transcripts}>{FeedContentType.Transcripts}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box
                  component="div"
                  ref={contentRef}
                  sx={{
                    overflow: isEditing || isInteracting ? "hidden auto" : "hidden",
                    scrollbarGutter: "stable",
                    padding: "24px",
                    pt: 0,
                  }}
                >
                  <List component="nav" sx={{ display: "flex", flexDirection: "column" }}>
                    {transcripts?.map((transcript, index) => {
                      return (
                        <ListItem
                          key={`${index}-${transcript.id}`}
                          disablePadding
                          sx={{
                            justifyContent: "space-between",
                            backgroundColor: activeTranscriptId === transcript.id ? "rgba(0,0,0,0.1)" : undefined,
                          }}
                        >
                          <ListItemButton onClick={() => onListItemClick(transcript)}>
                            <ListItemIcon>
                              <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                  <Typography variant="body1" fontWeight={500}>
                                    {transcript.title}
                                  </Typography>
                                  <Typography variant="body1" fontWeight={500} ml={2}>
                                    {getFormattedDate(transcript.itemDate, t("Formatting.ShortDate"))}
                                  </Typography>
                                </Box>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                    {(loading || totalCount > transcripts.length) && (
                      <ListItem key="infinite-scroll-sentry-ref-element" ref={infiniteScrollSentryRef} />
                    )}
                  </List>
                </Box>
              </Box>
            )}
          </div>
        </div>
      </HTMLContainer>
    );
  }
}
