import { ICommand } from "@bigpi/cookbook";

export const chatCommandPaletteList: Array<ICommand> = [
  {
    id: "bistro-command-21d1046a-7323-41a1-bc88-b24810017cc0",
    name: "Search",
    description: "Inserts @@Astra Intelligence find in workspace:",
    action: {
      // Should this be some generic type?
      actionType: "ChatCommand",
      data: {},
    },
    inputs: [],
    inputTemplate: "",
    isBlocking: false,
    outputTypes: [],
  },
];
