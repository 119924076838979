import { AssetRecordType, DEFAULT_SUPPORTED_IMAGE_TYPES, MediaHelpers, TLAsset, TLAssetId, getHashForString } from "tldraw";

const MAX_ASSET_WIDTH = 1000;
const MAX_ASSET_HEIGHT = 1000;

type BoxWidthHeight = {
  w: number;
  h: number;
};

/**
 * This method is cloned from an older implementation of tldraw without modification.
 * https://github.com/tldraw/tldraw/blob/a3a780414a0734e7cfae5262e5f4fe3edd06b57d/packages/tldraw/src/lib/utils/assets.ts#L16
 *
 * Contains the size within the given box size
 *
 * @param originalSize - The size of the asset
 * @param containBoxSize - The container size
 *
 * @returns Adjusted size
 */
function containBoxSize(originalSize: BoxWidthHeight, containBoxSize: BoxWidthHeight): BoxWidthHeight {
  const overByXScale = originalSize.w / containBoxSize.w;
  const overByYScale = originalSize.h / containBoxSize.h;

  if (overByXScale <= 1 && overByYScale <= 1) {
    return originalSize;
  } else if (overByXScale > overByYScale) {
    return {
      w: originalSize.w / overByXScale,
      h: originalSize.h / overByXScale,
    };
  } else {
    return {
      w: originalSize.w / overByYScale,
      h: originalSize.h / overByYScale,
    };
  }
}

/**
 * This method is cloned from an older implementation of tldraw without modification.
 * https://github.com/tldraw/tldraw/blob/b3186f5881498c739d4b43047e5c5b03d69cdddb/packages/tldraw/src/lib/utils/assets.ts#L66
 *
 * Get the size of an image from its source.
 *
 * @param dataURLForImage - The image file as a string.
 * @param width - The desired width.
 * @param height - The desired height.
 *
 * @returns The resized image's data URL.
 */
async function getResizedImageDataUrl(dataURLForImage: string, width: number, height: number): Promise<string> {
  return await new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      // Initialize the canvas and it's size
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) return;

      // Set width and height
      canvas.width = width * 2;
      canvas.height = height * 2;

      // Draw image and export to a data-uri
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const newDataURL = canvas.toDataURL();

      // Do something with the result, like overwrite original
      resolve(newDataURL);
    };
    img.crossOrigin = "anonymous";
    img.src = dataURLForImage;
  });
}

/**
 * This implementation is based on an older version of the tldraw codebase, that tries to contain the image within a
 * box of max size and creates data URL for the file. This implementation is more complex and has more features than
 * the example provided in the latest tldraw build. URL for the older implementation -
 * https://github.com/tldraw/tldraw/blob/b3186f5881498c739d4b43047e5c5b03d69cdddb/packages/tldraw/src/lib/useRegisterExternalContentHandlers.ts#L40
 *
 * Example in the latest tldraw build -
 * https://github.com/tldraw/tldraw/blob/41b5fffa2ef17ff852c1efc227a5ad5c37dc5c7a/apps/dotcom/src/utils/createAssetFromFile.ts#L12
 *
 * Get an asset from a file.
 *
 * @param file - The file.
 *
 * @returns An image or video asset partial.
 */
export async function getMediaAssetFromFile(file: File): Promise<TLAsset> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => reject(reader.error);
    reader.onload = async () => {
      let dataUrl = reader.result as string;

      const isImageType = DEFAULT_SUPPORTED_IMAGE_TYPES.includes(file.type);
      const sizeFn = isImageType ? MediaHelpers.getImageSize : MediaHelpers.getVideoSize;

      // Hack to make .mov videos work via dataURL.
      if (file.type === "video/quicktime" && dataUrl.includes("video/quicktime")) {
        dataUrl = dataUrl.replace("video/quicktime", "video/mp4");
      }

      const originalSize = await sizeFn(file);
      const size = containBoxSize(originalSize, { w: MAX_ASSET_WIDTH, h: MAX_ASSET_HEIGHT });
      if (size !== originalSize && (file.type === "image/jpeg" || file.type === "image/png")) {
        // If we created a new size and the type is an image, rescale the image
        dataUrl = await getResizedImageDataUrl(dataUrl, size.w, size.h);
      }

      const assetId: TLAssetId = AssetRecordType.createId(getHashForString(dataUrl));

      let isAnimated: boolean;
      if (isImageType) {
        if (file.type === "image/gif") {
          isAnimated = await MediaHelpers.isAnimated(file);
        } else {
          isAnimated = false;
        }
      } else {
        isAnimated = true;
      }

      const asset: Extract<TLAsset, { type: "image" | "video" }> = {
        id: assetId,
        type: isImageType ? "image" : "video",
        typeName: "asset",
        props: {
          name: file.name,
          src: dataUrl,
          w: size.w,
          h: size.h,
          mimeType: file.type,
          isAnimated,
        },
        meta: {},
      };

      resolve(asset);
    };

    reader.readAsDataURL(file);
  });
}
