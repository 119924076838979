import { IAnalysisFieldConfig, IDateRange, IDateRangeOption } from "@bigpi/cookbook";
import { useValue } from "tldraw";

import { IAnalysisFacets } from "BoardComponents/Types";
import { applyBounds } from "./useApplyBounds";
import { getQueryValidFacetValues } from "./useQueryValidFacets";
import { getSelectedDate } from "./useSelectedDate";

/**
 * This will transform selectedFaceValues, boundsFacetValues to be ready to use for the GraphQL query, form elements.
 *
 * @param selectedFacetValues User selected facet values to transform, validate & applies bounds on
 * @param boundsFacetValues Bounds facet values to transform, validate & applies bounds
 * @param fieldsConfig Fields config to identify the field type
 * @param shortcutItems Shortcut items with date ranges, helps to transform the shortcut date to date range
 * @param dateField Date field to which transformed date range will be replaced. This varies based on analysis
 * @returns selectedFacetValues, boundsFacetValues, validSelectedFacetValues, validBoundsFacetValues, mergedFacetValues
 */
export function useCuratedFacetValues<TFacets extends IAnalysisFacets>(
  selectedFacetValues: TFacets,
  boundsFacetValues: TFacets,
  fieldsConfig: Array<IAnalysisFieldConfig>,
  shortcutItems: Array<IDateRangeOption>,
  dateField: string,
) {
  const curatedFacetValues = useValue(
    "curatedFacetValues",
    () => {
      return getCuratedFacetValues(selectedFacetValues, boundsFacetValues, fieldsConfig, shortcutItems, dateField);
    },
    [selectedFacetValues, boundsFacetValues, fieldsConfig, shortcutItems, dateField],
  );

  return curatedFacetValues;
}

/**********************************
 * Utils
 **********************************/
/**
 * Transforms the given facets in the following order:
 * 1. Transforms any shortcut date to date range
 * 2. Replaces the date field value with transformed value
 * 3. Get the valid facet values based on the fields configuration, this is to make sure wrong values are not passed to the GraphQL query
 * 4. Applies bounds facets to the selected facets
 *
 * @param selectedFacetValues User selected facet values to transform, validate & applies bounds on
 * @param boundsFacetValues Bounds facet values to transform, validate & applies bounds
 * @param fieldsConfig Fields config to identify the field type
 * @param shortcutItems Shortcut items with date ranges, helps to transform the shortcut date to date range
 * @param dateField Date field to which transformed date range will be replaced. This varies based on analysis
 * @returns selectedFacetValues, boundsFacetValues, validSelectedFacetValues, validBoundsFacetValues, mergedFacetValues
 */
export function getCuratedFacetValues<TFacets extends IAnalysisFacets>(
  selectedFacetValues: TFacets,
  boundsFacetValues: TFacets,
  fieldsConfig: Array<IAnalysisFieldConfig>,
  shortcutItems: Array<IDateRangeOption>,
  dateField: string,
) {
  // Get the selected date from shortct/selected date
  const selectedFacetValuesSelectedDate = getSelectedDate(
    shortcutItems,
    selectedFacetValues[`${dateField}Shortcut` as keyof TFacets] as string,
    selectedFacetValues[dateField as keyof TFacets] as IDateRange,
  );
  const boundsFacetValuesSelectedDate = getSelectedDate(
    shortcutItems,
    boundsFacetValues[`${dateField}Shortcut` as keyof TFacets] as string,
    boundsFacetValues[dateField as keyof TFacets] as IDateRange,
  );

  // Replace the date field value with the selected date
  const selectedFacetValuesTransformed = {
    ...selectedFacetValues,
    [dateField]: selectedFacetValuesSelectedDate,
  };
  const boundsFacetValuesTransformed = {
    ...boundsFacetValues,
    [dateField]: boundsFacetValuesSelectedDate,
  };

  // Make sure the facet values are valid, so the wrong values are not passed to the GraphQL query
  const validSelectedFacetValues = getQueryValidFacetValues(selectedFacetValuesTransformed, fieldsConfig);
  const validBoundsFacetValues = getQueryValidFacetValues(boundsFacetValuesTransformed, fieldsConfig);

  const mergedFacetValues = applyBounds(validBoundsFacetValues, validSelectedFacetValues);

  return {
    selectedFacetValues: selectedFacetValuesTransformed,
    boundsFacetValues: boundsFacetValuesTransformed,
    validSelectedFacetValues,
    validBoundsFacetValues,
    mergedFacetValues,
  };
}
