import { BoxProps } from "@mui/system";
import React, { useState } from "react";

// *********************************************
// Types/Interfaces
// *********************************************/
export interface AppRightSidebarProviderProps {
  children: React.ReactNode;
}

export interface IAppRightSidebarComponents {
  rightSidebarSx: BoxProps["sx"] | null;
  setRightSidebarSx: (sx: IAppRightSidebarComponents["rightSidebarSx"]) => void;
}

export interface RightSidebarTitleComponentProps {
  children: React.ReactNode;
  sx?: IAppRightSidebarComponents["rightSidebarSx"];
}

// *********************************************
// Context
// *********************************************/
const AppRightSidebarComponents = React.createContext<IAppRightSidebarComponents>({
  rightSidebarSx: null,
  setRightSidebarSx: () => null,
});

export function AppRightSidebarProvider(props: AppRightSidebarProviderProps) {
  const { children } = props;
  const [rightSidebarSx, setRightSidebarSx] = useState<IAppRightSidebarComponents["rightSidebarSx"]>(null);

  return (
    <AppRightSidebarComponents.Provider value={{ rightSidebarSx, setRightSidebarSx }}>
      {children}
    </AppRightSidebarComponents.Provider>
  );
}

export function useAppRightSidebarComponents() {
  return React.useContext(AppRightSidebarComponents);
}

// *********************************************
// Components
// *********************************************/
export function useRightSidebarSx() {
  const { rightSidebarSx } = useAppRightSidebarComponents();
  return rightSidebarSx;
}
