import { ItemGridSize, ItemViewType, ISortOption, UpdatedAtDescSortOption } from "@bigpi/cookbook";
import { Box, Button, Typography } from "@mui/material";
import { useValue } from "tldraw";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DocumentsList } from "./DocumentsList";
import { DocumentsGrid } from "./DocumentsGrid";
import { useDocumentsQuery } from "GraphQL/Generated/Apollo";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
interface IDocumentsViewProps {
  gridSize: ItemGridSize;
  onGridSizeChange: (gridSize: ItemGridSize) => void;
  onSelectionChange: (id: string) => void;
  pageSize: number;
  searchValue?: string;
  selectedIds: Array<string>;
  viewType: ItemViewType;
  selectedSortOption?: ISortOption;
}

const DEFAULT_OFFSET = 0;

// *********************************************
// Component
// *********************************************/
export function DocumentsView(props: IDocumentsViewProps) {
  const { t } = useTranslation();
  const {
    gridSize,
    onGridSizeChange,
    onSelectionChange,
    pageSize,
    selectedIds,
    selectedSortOption = UpdatedAtDescSortOption,
    searchValue,
    viewType,
  } = props;

  // State
  const [orderBy, setOrderBy] = useState<Record<string, string>>({ [selectedSortOption.field]: selectedSortOption.sortOrder });

  // Documents query
  const {
    data: documentsData,
    fetchMore: fetchMoreDocuments,
    loading,
    error,
  } = useDocumentsQuery({
    variables: {
      limit: pageSize,
      offset: DEFAULT_OFFSET,
      orderBy,
      filters: {
        searchTerm: searchValue,
      },
    },
  });

  // Hooks
  useEffect(() => {
    setOrderBy({ [selectedSortOption.field]: selectedSortOption.sortOrder });
  }, [selectedSortOption]);

  const rows = useValue(
    "documentsRows",
    () => {
      return documentsData?.documents || [];
    },
    [documentsData],
  );

  const count = useValue(
    "documentsCount",
    () => {
      return documentsData?.documentAggregate.count || 0;
    },
    [documentsData],
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          minHeight: "300px",
        }}
      >
        <Typography variant="caption">{t("Global.Status.Loading")}</Typography>
      </Box>
    );
  } else if (searchValue && !loading && !error && rows.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          minHeight: "300px",
        }}
      >
        <Typography variant="body1" color="GrayText">
          {t("Pages.Documents.List.NoItemsFound")}
        </Typography>
      </Box>
    );
  } else if (!loading && !error && rows.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          minHeight: "300px",
        }}
      >
        <Typography variant="body1" color="GrayText">
          {t("Pages.Documents.List.NoItems")}
        </Typography>
      </Box>
    );
  } else if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          minHeight: "300px",
        }}
      >
        <Typography variant="body1" color="GrayText">
          {t("Pages.Documents.List.LoadError")}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="caption">
        {t("Global.Aggregate.Some", { count, selectedCount: rows.length, totalCount: count })}
      </Typography>
      <Box sx={{}}>
        {viewType === ItemViewType.List ? (
          <DocumentsList loading={loading} onSelectionChange={onSelectionChange} rows={rows} selectedIds={selectedIds} />
        ) : (
          <DocumentsGrid
            gridSize={gridSize}
            loading={loading}
            onGridSizeChange={onGridSizeChange}
            onSelectionChange={onSelectionChange}
            rows={rows}
            selectedIds={selectedIds}
          />
        )}
      </Box>

      {count > rows.length && (
        <Button
          onClick={() => {
            fetchMoreDocuments({
              variables: {
                offset: rows.length,
                orderBy,
                filters: {
                  searchTerm: searchValue,
                },
              },
            });
          }}
        >
          {t("Global.Action.ShowMore")}
        </Button>
      )}
    </Box>
  );
}
