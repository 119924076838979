import { IDataGridAction, DataGridActionType } from "@bigpi/cookbook";
import { Check } from "@mui/icons-material";
import { Button, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { Editor, TLShapeId } from "tldraw";

import { SplitButton, SplitButtonProps } from "Components/SplitButton/SplitButton";

import { useDataGridActions } from "./useDataGridActions";

interface DataGridActionsProps {
  editor: Editor;
  shapeId: TLShapeId;
}

export function DataGridActionsToolbarItems(props: DataGridActionsProps) {
  const { editor, shapeId } = props;
  const actions = useDataGridActions(editor, shapeId);

  if (!actions) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", alignContent: "space-around", marginTop: "10px" }}>
      {Object.keys(actions).map((category) => {
        const categoryActions = actions[category as keyof typeof actions] || [];
        if (categoryActions.length === 0) {
          return null;
        }
        if (categoryActions.length === 1) {
          return (
            <Button key={categoryActions[0].key} variant="contained" onClick={categoryActions[0].onAction}>
              {categoryActions[0].title}
            </Button>
          );
        }
        const splitButtonOptions: SplitButtonProps["options"] = categoryActions.map((action) => {
          if (action.type === DataGridActionType.Divider) {
            return {
              value: action.key,
              label: <Divider />,
              disabled: true,
            };
          } else if (action.type === DataGridActionType.Checkbox) {
            return {
              value: action.key,
              label: (
                <>
                  {action.isSelected ? <Check /> : ""}
                  {action.title}
                </>
              ),
              disabled: action.isDisabled,
            };
          }
          return {
            value: action.key,
            label: action.title,
            disabled: action.isDisabled,
          };
        });

        return (
          <Box key={category} sx={{ marginLeft: "10px" }}>
            <SplitButton
              options={splitButtonOptions}
              handleClick={(option) => {
                const action = categoryActions.find((action: IDataGridAction) => action.key === option);
                if (action) {
                  action.onAction();
                }
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
}
