import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton, Stack } from "@mui/material";
import { TreeItem2Label, TreeItem2SlotProps } from "@mui/x-tree-view";
import { MouseEvent } from "react";

export type IShapeTreeItemLabelProps = TreeItem2SlotProps["label"] & {
  isShapeTableOfContentsAvailable: boolean;
  openShapeToc: () => void;
};

export const ShapeTreeItemLabel = (props: IShapeTreeItemLabelProps) => {
  const { isShapeTableOfContentsAvailable, openShapeToc, ...rest } = props;

  const onButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    openShapeToc();
  };

  return (
    <Stack direction="row" justifyContent="space-between" width="100%" alignItems={"center"}>
      <TreeItem2Label {...rest} />
      <Stack flexDirection="row" justifyContent="space-around" alignItems="center">
        {isShapeTableOfContentsAvailable && (
          <IconButton size="small" onClick={onButtonClick} disableRipple>
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};
