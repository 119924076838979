import React, { useEffect, useState } from "react";

// *********************************************
// Types/Interfaces
// *********************************************/
export interface AppLeftSidebarProviderProps {
  children: React.ReactNode;
}

export interface IAppLeftSidebarComponents {
  leftSidebarSx: {
    backgroundColor: string;
  } | null;
  LeftSidebarTitleComponent: React.ReactNode;
  setLeftSidebarSx: (sx: IAppLeftSidebarComponents["leftSidebarSx"]) => void;
  setLeftSidebarTitleComponent: (component: React.ReactNode) => void;
}

export interface LeftSidebarTitleComponentProps {
  children: React.ReactNode;
  sx?: IAppLeftSidebarComponents["leftSidebarSx"];
}

// *********************************************
// Context
// *********************************************/
const AppLeftSidebarComponents = React.createContext<IAppLeftSidebarComponents>({
  leftSidebarSx: null,
  LeftSidebarTitleComponent: null,
  setLeftSidebarSx: () => null,
  setLeftSidebarTitleComponent: () => null,
});

export function AppLeftSidebarProvider(props: AppLeftSidebarProviderProps) {
  const { children } = props;
  const [LeftSidebarTitleComponent, setLeftSidebarTitleComponent] = useState<React.ReactNode>();
  const [leftSidebarSx, setLeftSidebarSx] = useState<IAppLeftSidebarComponents["leftSidebarSx"]>(null);

  return (
    <AppLeftSidebarComponents.Provider
      value={{ LeftSidebarTitleComponent, setLeftSidebarTitleComponent, leftSidebarSx, setLeftSidebarSx }}
    >
      {children}
    </AppLeftSidebarComponents.Provider>
  );
}

export function useAppLeftSidebarComponents() {
  return React.useContext(AppLeftSidebarComponents);
}

// *********************************************
// Components
// *********************************************/
/************ Sidebar Component ***********/
export function LeftSidebarTitle(props: LeftSidebarTitleComponentProps) {
  const { children, sx } = props;
  const { setLeftSidebarTitleComponent, setLeftSidebarSx } = useAppLeftSidebarComponents();

  useEffect(() => {
    setLeftSidebarTitleComponent(children);
  }, [children, setLeftSidebarTitleComponent]);

  useEffect(() => {
    setLeftSidebarSx(sx || null);
  }, [sx, setLeftSidebarSx]);

  return null;
}

export function LeftSidebarTitleComponent() {
  const { LeftSidebarTitleComponent } = useAppLeftSidebarComponents();
  return LeftSidebarTitleComponent ? LeftSidebarTitleComponent : null;
}

export function useLeftSidebarSx() {
  const { leftSidebarSx } = useAppLeftSidebarComponents();
  return leftSidebarSx;
}
