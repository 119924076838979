import { useHashParams } from "@bigpi/cutlery";
import { isShapeFullyWithinPageBounds } from "@bigpi/tl-schema";
import { useAuthUser } from "@frontegg/react";
import { Box } from "@mui/material";
import { Editor, TLShapeId, useValue } from "tldraw";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { BoardDatastore, BoardDatastoreContext } from "BoardComponents/BoardDatastore";
import { INavigableShape } from "BoardComponents/BoardDatastore/INavigableShape";
import { WorkspaceBoardChatActionManager } from "Chat/ChatActionManagers/WorkspaceBoard/WorkspaceBoardChatActionManager";
import { WorkspaceBoardCommandManager } from "Components/CommandManagers/WorkspaceBoard/WorkspaceBoardCommandManager";
import { useIsWorkspaceEditable } from "Components/Workspace/Hooks/useIsWorkspaceEditable";
import { CommandContext } from "CommandContext";
import { Config } from "Config";
import { useImageTokenPolling } from "Editor/Extensions/PasteContent/useImageTokenPolling";
import { useWorkspaceBoardUserPreferencesQuery, useWorkspaceQuery } from "GraphQL/Generated/Apollo";
import { WORKSPACE_BOARD_USER_PREFERENCES_KEY } from "GraphQL/UserPreference";
import { CollaborativeBoard, CollaborativeBoardConfig } from "TldrawBoard/CollaborativeBoard";
import { getRandomColor } from "Utils/ColorUtils";
import { POSITION_SEARCH_PARAM_NAME, SHAPE_BLOCK_ID_SEARCH_PARAM_NAME, SHAPE_ID_SEARCH_PARAM_NAME } from "./Constants";

import "./WorkspaceBoard.css";

export interface WorkspaceBoardProps {
  workspaceBoardId: string;
  workspaceId: string;
}

export function WorkspaceBoard(props: WorkspaceBoardProps) {
  const { workspaceBoardId, workspaceId } = props;

  const { params } = useHashParams();

  const { t } = useTranslation();

  const user = useAuthUser();

  const [tldrawEditor, setTldrawEditor] = useState<Editor>();
  const { isEditable: isWorkspaceEditable, loading: aclLoading } = useIsWorkspaceEditable(workspaceId);

  const shapeIdFromHashParams = useValue("shapeIdFromHashParams", () => params.get(SHAPE_ID_SEARCH_PARAM_NAME), [params]);
  const shapeBlockIdFromHashParams = useValue("shapeBlockIdFromHashParams", () => params.get(SHAPE_BLOCK_ID_SEARCH_PARAM_NAME), [
    params,
  ]);
  const positionFromHashParams = useValue(
    "positionFromHashParams",
    () => {
      const position = params.get(POSITION_SEARCH_PARAM_NAME);
      if (!position) {
        return null;
      }

      const [xFromHashParams, yFromHashParams, zFromHashParams] = position.split(",").map(parseFloat);
      return {
        x: xFromHashParams,
        y: yFromHashParams,
        z: zFromHashParams,
      };
    },
    [params],
  );

  const { data, loading, error } = useWorkspaceQuery({ variables: { id: workspaceId } });
  const { data: workspaceBoardPreferences } = useWorkspaceBoardUserPreferencesQuery({
    variables: {
      key: WORKSPACE_BOARD_USER_PREFERENCES_KEY(workspaceBoardId),
    },
  });

  useEffect(() => {
    document.documentElement.style.overscrollBehaviorX = "contain";
    document.body.style.overscrollBehaviorX = "contain";
    return () => {
      document.documentElement.style.overscrollBehaviorX = "auto";
      document.body.style.overscrollBehaviorX = "auto";
    };
  }, []);

  // Create a new datastore for shapes to share data
  const datastore = BoardDatastore.useNewBoardDatastore();

  // Poll for image (access) tokens to make sure they don't expire
  useImageTokenPolling();

  // Set current application session context
  useEffect(() => {
    CommandContext.replaceSessionContext([{ workspaceId }, { workspaceBoardId: workspaceBoardId }]);
  }, [workspaceId, workspaceBoardId]);

  const boardConfig: CollaborativeBoardConfig = useMemo(() => {
    return {
      boardId: workspaceBoardId,
      editorUser: {
        accessToken: user.accessToken,
        name: user.name,
        color: getRandomColor(),
        userId: user.id,
      },
      // Only set `readOnly` to `false` if the user has a role that allows them to edit the board
      readOnly: isWorkspaceEditable !== true,
      runnerHttpUrl: Config.runnerHttpUrl,
      runnerWsUrl: Config.runnerWsUrl,
      workspaceId,
    };
  }, [isWorkspaceEditable, workspaceBoardId, user.accessToken, user.id, user.name, workspaceId]);

  const setCameraPosition = (
    editor: Editor,
    shapeId: string | null,
    blockId: string | null,
    position: { x: number; y: number; z: number } | null,
  ) => {
    if (shapeId) {
      // Try to find the shape by ID
      const shape = editor.getShape(shapeId as TLShapeId);
      if (shape) {
        // Select the shape
        editor.setSelectedShapes([shape]);

        // Use zoom level from URL when available
        const targetZoom = position?.z;

        // Move camera to the shape
        const pageBounds = editor.getViewportPageBounds();
        const shapePageBounds = editor.getShapePageBounds(shape.id);
        if (shapePageBounds) {
          // Zoom to shape if it's not completely visible
          const isShapeCompletelyVisible = isShapeFullyWithinPageBounds(pageBounds, shapePageBounds);
          if (!isShapeCompletelyVisible) {
            editor.zoomToBounds(shapePageBounds, {
              targetZoom,
              animation: {
                duration: 1000,
              },
            });
          }

          // Scroll shape to block if block ID is provided
          if (blockId) {
            const shapeData = datastore.state.get()[shapeId]?.get() as INavigableShape | undefined;
            if (shapeData) {
              const scrollToBlock = shapeData.scrollToBlock?.get();
              scrollToBlock?.(blockId);
            }
          }

          // Exit here to prevent the camera from being reset to the position in URL
          // or the user preferences
          return;
        }
      }
    }

    // Use camera position and zoom level from URL when available, fallback to user
    // preferences if available, otherwise use default values
    const zoomLevel = position?.z || workspaceBoardPreferences?.userPreference?.data.zoomLevel || 1;
    const cameraPosition = position
      ? { x: position.x, y: position.y }
      : (workspaceBoardPreferences?.userPreference?.data.cameraPosition ?? { x: 0, y: 0 });

    // Set the camera to the user's last position
    editor.setCamera(
      {
        x: cameraPosition.x,
        y: cameraPosition.y,
        z: zoomLevel,
      },
      {
        animation: {
          duration: 1000,
        },
      },
    );
  };

  useEffect(() => {
    if (tldrawEditor) {
      setCameraPosition(tldrawEditor, shapeIdFromHashParams, shapeBlockIdFromHashParams, positionFromHashParams);
    }
  }, [shapeIdFromHashParams, shapeBlockIdFromHashParams, positionFromHashParams, tldrawEditor]);

  // Check if we're loading
  if (loading || aclLoading) {
    return (
      <Box sx={{ display: "flex", flex: 1, m: 3, p: 3 }}>
        <Box sx={{ alignSelf: "center", textAlign: "center", flex: 1 }}>{t("Global.Status.Loading")}</Box>
      </Box>
    );
  }

  return (
    <BoardDatastoreContext.Provider value={datastore}>
      <WorkspaceBoardCommandManager app={tldrawEditor} workspaceBoardId={workspaceBoardId} />
      <WorkspaceBoardChatActionManager app={tldrawEditor} workspaceBoardId={workspaceBoardId} />
      {/* The div here is required and intentional. Otherwise, the tools get clipped on the right side. */}
      {workspaceBoardId !== "" && (
        <div className="tldraw">
          <CollaborativeBoard config={boardConfig} onMount={onMount} />
        </div>
      )}
    </BoardDatastoreContext.Provider>
  );

  function onMount(editor: Editor) {
    setTldrawEditor(editor);

    // Set the camera position
    setCameraPosition(editor, shapeIdFromHashParams, shapeBlockIdFromHashParams, positionFromHashParams);
  }
}
