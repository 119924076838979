import { gql, useApolloClient } from "@apollo/client";
import { getStoreKeyName } from "@apollo/client/utilities";
import { Constants } from "@bigpi/permission";
import { useAuthUser } from "@frontegg/react";
import { Box, InputLabel, List } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ThreadMessage } from "./ThreadMessage";
import { ChatMessage } from "Chat/ChatMessage/ChatMessage";
import { getChatMessageToolbarActions } from "Chat/Utils/ChatMessageToolbarActions";
import { CHAT_MESSAGES_ADDED_SUBSCRIPTION } from "GraphQL/Chat/Subscription";
import {
  ChatMessageChannelType,
  ChatMessageQuery,
  OnChatMessagesAddedSubscription,
  OnChatMessagesAddedSubscriptionVariables,
  useChatMessagesQuery,
  useOnChatMessagesDeletedSubscription,
  useOnChatMessagesUpdatedSubscription,
} from "GraphQL/Generated/Apollo";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
export interface ChatMessageListProps {
  channelId: string;
  filterByUserId: string;
  parentId: string | null;
  onMessageParentSet: (parentId: string | null, isPrivate?: boolean) => void;
}

const TIME_DIFFERENCE_THRESHOLD = 10 * 1000;

// *********************************************
// Component
// *********************************************/
export function ChatMessageList(props: ChatMessageListProps) {
  const { channelId, filterByUserId, onMessageParentSet, parentId } = props;
  const { t } = useTranslation();
  const messagesRef = useRef<HTMLUListElement>(null);
  const [pendingAddedMessages, setPendingAddedMessages] = useState<
    Array<Exclude<OnChatMessagesAddedSubscription["onChatMessageAdded"], null | undefined>>
  >([]);
  const user = useAuthUser();

  const { data, loading, error, subscribeToMore, refetch } = useChatMessagesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      channelType: ChatMessageChannelType.Workspace,
      channelId,
      filters: {
        parentId,
        privateRecipientIds: filterByUserId ? [filterByUserId] : undefined,
        mentionTargetIds: filterByUserId ? [filterByUserId] : undefined,
        createdBy: filterByUserId === user.id ? [filterByUserId] : undefined,
      },
    },
  });

  // We need to hook up the update subscription even though we don't directly use the data here
  // * it'll update the cache and trigger relevant UI updates (streaming bot responses, etc.)
  // * it'll also update childCounts for parent messages
  useOnChatMessagesUpdatedSubscription({
    variables: {
      channelId,
      channelType: ChatMessageChannelType.Workspace,
      filters: {
        parentId,
        privateRecipientIds: filterByUserId ? [filterByUserId] : undefined,
        mentionTargetIds: filterByUserId ? [filterByUserId] : undefined,
        createdBy: filterByUserId === user.id ? [filterByUserId] : undefined,
      },
    },
  });

  useOnChatMessagesDeletedSubscription({
    variables: {
      channelId,
      channelType: ChatMessageChannelType.Workspace,
      parentId,
    },
    onData: (options) => {
      const message = options.data.data?.onChatMessageDeleted;

      if (message) {
        const cache = options.client.cache;
        const id = cache.identify({
          __typename: "ChatMessage",
          channelType: ChatMessageChannelType.Workspace,
          id: message.id,
        });
        if (id) {
          cache.evict({ id });
        }
      }
    },
  });

  const apolloClient = useApolloClient();

  // Subscribe to updates for this channel or thread
  useEffect(() => {
    const unsubscribeCreated = subscribeToMore<OnChatMessagesAddedSubscription, OnChatMessagesAddedSubscriptionVariables>({
      document: CHAT_MESSAGES_ADDED_SUBSCRIPTION,
      variables: {
        channelType: ChatMessageChannelType.Workspace,
        channelId,
        filters: {
          parentId,
          privateRecipientIds: filterByUserId ? [filterByUserId] : undefined,
          mentionTargetIds: filterByUserId ? [filterByUserId] : undefined,
          createdBy: filterByUserId === user.id ? [filterByUserId] : undefined,
        },
      },
      updateQuery: (prev, { subscriptionData }) => {
        // Return previous data if no new data
        if (!subscriptionData.data || !subscriptionData.data.onChatMessageAdded) {
          return prev;
        }
        const newItem = subscriptionData.data.onChatMessageAdded;

        // Open thread (set parentId) when new message from Human has AI user mention
        const currentTimeInMs = new Date().getTime();
        const createdTimeInMs = new Date(newItem.createdAt).getTime();
        const timeDiffInMs = currentTimeInMs - createdTimeInMs;
        const mentionsTargetIds = newItem.mentions?.map((mention) => mention.targetId) || [];
        const privateRecipientIds = newItem.privateRecipientIds || [];

        // Switches to thread view when the AI user is mentioned
        if (
          !parentId &&
          newItem.createdBy === user.id &&
          mentionsTargetIds.includes(Constants.AI_USER_ID) &&
          newItem.parentId === null &&
          timeDiffInMs < TIME_DIFFERENCE_THRESHOLD
        ) {
          onMessageParentSet(newItem.id);
        }

        // Switches to thread view when current user created private message
        if (
          !parentId &&
          newItem.createdBy === user.id &&
          privateRecipientIds.includes(user.id) &&
          newItem.parentId === null &&
          timeDiffInMs < TIME_DIFFERENCE_THRESHOLD
        ) {
          onMessageParentSet(newItem.id, true);
        }

        if (loading) {
          // We need to stash the new messge and only add after the initial query is done
          setPendingAddedMessages([...pendingAddedMessages, newItem]);

          // Handle the case where subscription returns before the cache has the list of messages populated
          if (Object.keys(prev).length === 0) {
            return {
              chatMessages: [],
            };
          } else {
            return prev;
          }
        } else {
          return Object.assign({}, prev, {
            chatMessages: [
              ...(Array.isArray(prev.chatMessages) ? prev.chatMessages : []),
              {
                ...newItem,
              },
            ],
          });
        }
      },
    });

    return () => {
      unsubscribeCreated();
    };
  }, [apolloClient, channelId, loading, onMessageParentSet, parentId, subscribeToMore, user.id, filterByUserId]);

  useEffect(() => {
    if (messagesRef.current) {
      // TODO: Check if the user has scrolled away from the newest messages before scrolling
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [data]);

  useEffect(() => {
    if (loading === false) {
      if (pendingAddedMessages.length > 0) {
        // Get still relevant messages from the queue
        // const newMessages = pendingAddedMessages.filter((message) => message.parentId === parentId);
        const newMessages = pendingAddedMessages;

        // Modify the Apollo cache to add the new messages
        newMessages.forEach((message) => {
          apolloClient.cache.modify({
            fields: {
              chatMessages(existingMessages = [], { fieldName, storeFieldName, readField }) {
                if (
                  getStoreKeyName("chatMessages", { channelId, channelType: ChatMessageChannelType.Workspace, parentId }) !==
                  storeFieldName
                ) {
                  return existingMessages;
                } else {
                  const newMessageRef = apolloClient.cache.writeFragment({
                    id: apolloClient.cache.identify(message),
                    data: message,
                    fragment: gql`
                      fragment NewChatMessage on ChatMessage {
                        id

                        channelId
                        parentId
                        messageType
                        message
                        privateRecipientIds

                        attachments {
                          mimeType
                          name
                          storageLocationUrl
                        }
                        mentions {
                          targetId
                          name
                          mentionType
                        }
                        reactions {
                          reaction
                          userIds
                        }

                        createdAt
                        createdBy
                        updatedAt
                        updatedBy
                        deletedAt
                        deletedBy

                        childCount
                      }
                    `,
                  });

                  // Quick safety check - if the new message is already
                  // present in the cache, we don't need to add it again.
                  if (
                    existingMessages.some(
                      (ref: Exclude<ChatMessageQuery["chatMessage"], null | undefined>) => readField("id", ref) === message.id,
                    )
                  ) {
                    return existingMessages;
                  }

                  return [...existingMessages, newMessageRef];
                }
              },
            },
          });
        });

        // Clear the pending messages
        setPendingAddedMessages([]);
      }
    }
  }, [apolloClient, channelId, loading, parentId, pendingAddedMessages]);

  const onCloseThread = useCallback(() => {
    onMessageParentSet(null);
  }, [onMessageParentSet]);

  if (error) {
    return <Box sx={{ display: "flex", flex: 1 }}>{t("Components.Chat.MessageList.Error", { errorMessage: error.message })}</Box>;
  }

  if (data?.chatMessages?.length === 0 && !parentId) {
    return (
      <Box sx={{ display: "flex", flex: 1 }}>
        <InputLabel sx={{ p: 2, textWrap: "pretty" }}>{t("Components.Chat.MessageList.AiSearchInstructions")}</InputLabel>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", minHeight: 0, flexDirection: "column", flex: 1, overflowY: "scroll" }} ref={messagesRef}>
      {parentId && (
        <ThreadMessage
          channelId={channelId}
          parentId={parentId}
          onClose={onCloseThread}
          channelType={ChatMessageChannelType.Workspace}
        />
      )}
      <List sx={{ display: "flex", flexDirection: "column" }}>
        {data?.chatMessages.map((message) => {
          const chatMessageToolbarActions = getChatMessageToolbarActions(message, user.id, "list");
          return (
            <ChatMessage
              channelId={channelId}
              channelType={ChatMessageChannelType.Workspace}
              key={message.id}
              message={message}
              onMessageParentSet={onMessageParentSet}
              toolbarActions={chatMessageToolbarActions}
            />
          );
        })}
      </List>
    </Box>
  );
}
