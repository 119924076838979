import { Box } from "tldraw";
import { getPerfectDashProps } from "../Utils/getPerfectDashProps";

/**
 * Renders a dashed outline box around the given bounds.
 *
 * @remarks This class was copied from the tldraw package.
 *
 * @param param0 Options.
 *
 * @returns The component to render.
 */
export function DashedOutlineBox({ bounds, zoomLevel, className }: { bounds: Box; zoomLevel: number; className?: string }) {
  return (
    <g className={className} pointerEvents="none" strokeLinecap="round" strokeLinejoin="round">
      {bounds.sides.map((side, i) => {
        const { strokeDasharray, strokeDashoffset } = getPerfectDashProps(side[0].dist(side[1]), 1 / zoomLevel, {
          style: "dashed",
          lengthRatio: 4,
        });

        return (
          <line
            key={i}
            x1={side[0].x}
            y1={side[0].y}
            x2={side[1].x}
            y2={side[1].y}
            strokeDasharray={strokeDasharray}
            strokeDashoffset={strokeDashoffset}
          />
        );
      })}
    </g>
  );
}
