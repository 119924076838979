import { IAnalysisFieldConfig } from "@bigpi/cookbook";
import { useValue } from "tldraw";

/**
 * Ignores the fields just for client side & doesn't actually exists on database/GraphQL schema
 *
 * @param fields Fields to validate
 * @param fieldsConfig Each field config for the fields
 * @returns Valid fields which can be passed to GraphQL Query
 */
export function useQueryValidFields(fields: Array<IAnalysisFieldConfig["field"]>, fieldsConfig: Array<IAnalysisFieldConfig>) {
  const validFields = useValue(
    "validFields",
    () => {
      return fields.filter((field) => {
        const fieldConfig = fieldsConfig.find((fieldConfig) => fieldConfig.field === field);
        return fieldConfig && !fieldConfig.isClientSide ? true : false;
      });
    },
    [fields],
  );
  return validFields;
}
