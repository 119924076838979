import { FacetDisplaySortType } from "@bigpi/cookbook";
import { RecordProps } from "@tldraw/tlschema";
import { T } from "@tldraw/validate";

import { ITopicDiscussionSummaryAnalysisShape } from "./ITopicDiscussionSummaryAnalysisShape.js";
import { TopicDiscussionSummaryAnalysisFieldTypeEnum } from "./ITopicDiscussionSummaryAnalysisFieldFacets.js";
import { dataGridPreferencesValidatable } from "../DataGrid/index.js";
import { analysisPreferencesFacetSortValidatable } from "../../Common.js";

// *********************************************
// Private constants
// *********************************************/
/**
 * The default width of the shape.
 */
const DEFAULT_WIDTH = 500;

/**
 * The default height of the shape.
 */
const DEFAULT_HEIGHT = 500;

// *********************************************
// Public constants
// *********************************************/
/**
 * Gets the default props for the shape.
 *
 * @returns The default props.
 */
export const getTopicDiscussionSummaryAnalysisShapeDefaultProps = (): ITopicDiscussionSummaryAnalysisShape["props"] => {
  return {
    boundsFacetValues: {},
    enableBackground: true,
    selectedFacetValues: {},
    h: DEFAULT_HEIGHT,
    preferences: {},
    selection: {},
    w: DEFAULT_WIDTH,
  };
};

/**
 * The shape props schema definition.
 */
export const topicDiscussionSummaryAnalysisShapeProps: RecordProps<ITopicDiscussionSummaryAnalysisShape> = {
  selectedBoundsFields: T.arrayOf(
    T.setEnum(
      new Set([
        TopicDiscussionSummaryAnalysisFieldTypeEnum.AnalysisName,
        TopicDiscussionSummaryAnalysisFieldTypeEnum.Category,
        TopicDiscussionSummaryAnalysisFieldTypeEnum.Event,
        TopicDiscussionSummaryAnalysisFieldTypeEnum.EventDate,
        TopicDiscussionSummaryAnalysisFieldTypeEnum.EventType,
        TopicDiscussionSummaryAnalysisFieldTypeEnum.Period,
        TopicDiscussionSummaryAnalysisFieldTypeEnum.Section,
        TopicDiscussionSummaryAnalysisFieldTypeEnum.Ticker,
        TopicDiscussionSummaryAnalysisFieldTypeEnum.Topic,
      ]),
    ),
  ).optional(),
  // Field facet values
  selectedFacetValues: T.object({
    analysisName: T.arrayOf(T.string).optional(),
    category: T.arrayOf(T.string).optional(),
    event: T.arrayOf(T.string).optional(),
    eventDate: T.object({
      from: T.string.optional(),
      to: T.string.optional(),
    }).optional(),
    eventType: T.arrayOf(T.string).optional(),
    eventDateShortcut: T.string.optional(),
    period: T.arrayOf(T.string).optional(),
    section: T.arrayOf(T.string).optional(),
    ticker: T.arrayOf(T.string).optional(),
    topic: T.arrayOf(T.string).optional(),
  }),
  w: T.nonZeroInteger.optional(),
  h: T.nonZeroInteger.optional(),
  // Toolbar
  toolbar: T.object({
    availableFields: T.arrayOf(
      T.setEnum(
        new Set([
          TopicDiscussionSummaryAnalysisFieldTypeEnum.AnalysisName,
          TopicDiscussionSummaryAnalysisFieldTypeEnum.Category,
          TopicDiscussionSummaryAnalysisFieldTypeEnum.Event,
          TopicDiscussionSummaryAnalysisFieldTypeEnum.EventDate,
          TopicDiscussionSummaryAnalysisFieldTypeEnum.EventType,
          TopicDiscussionSummaryAnalysisFieldTypeEnum.Period,
          TopicDiscussionSummaryAnalysisFieldTypeEnum.Section,
          TopicDiscussionSummaryAnalysisFieldTypeEnum.Ticker,
          TopicDiscussionSummaryAnalysisFieldTypeEnum.Topic,
        ]),
      ),
    ).optional(),
  }).optional(),
  enableBackground: T.boolean.optional(),
  // Preferences
  preferences: T.object({
    analysis: T.object({
      facetSort: analysisPreferencesFacetSortValidatable,
      showDataToDisplayInToolbar: T.boolean.optional(),
      showQuestions: T.boolean.optional(),
      startColor: T.string.optional(),
    }).optional(),
    dataGrid: dataGridPreferencesValidatable,
  }),
  // Bounds facets
  boundsFacetValues: T.object({
    analysisName: T.arrayOf(T.string).optional(),
    category: T.arrayOf(T.string).optional(),
    event: T.arrayOf(T.string).optional(),
    eventDate: T.object({
      from: T.string.optional(),
      to: T.string.optional(),
    }).optional(),
    eventType: T.arrayOf(T.string).optional(),
    eventDateShortcut: T.string.optional(),
    period: T.arrayOf(T.string).optional(),
    section: T.arrayOf(T.string).optional(),
    ticker: T.arrayOf(T.string).optional(),
    topic: T.arrayOf(T.string).optional(),
  }),
  // Selection
  selection: T.object({
    expandedGroups: T.arrayOf(T.string).optional(),
    selectedGroup: T.string.optional(),
    selectedItem: T.string.optional(),
    selectedXAxis: T.string.optional(),
  }).optional(),
};
