import { SlotComponentProps } from "@mui/material";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";

import { CustomTreeItemLabel } from "./CustomItemLabel";
import { WorkspaceBoardTableOfContentsType } from "./WorkspaceBoardTableOfContents";

// *********************************************
// Types/Constants
// *********************************************/
interface ICustomTreeItemProps {
  itemId: string;
  label: string;
  reactions?: WorkspaceBoardTableOfContentsType["reactions"];
  children?: Array<WorkspaceBoardTableOfContentsType>;
  onClick: (itemId: string) => void;
}

type TreeItem2LabelCustomSlotProps = SlotComponentProps<
  "div",
  {},
  { itemId?: string; reactions?: WorkspaceBoardTableOfContentsType["reactions"] }
>;

// *********************************************
// Component
// *********************************************/
export function CustomTreeItem(props: ICustomTreeItemProps) {
  const { itemId, label, reactions, children = [], onClick } = props;

  return (
    <TreeItem2
      id={`workspace-board-toc-treeitem-${itemId}`}
      key={itemId}
      itemId={itemId}
      label={label}
      slots={{ label: CustomTreeItemLabel }}
      slotProps={{ label: { itemId, reactions } as TreeItem2LabelCustomSlotProps }}
      onClick={(event) => {
        // Prevent the event from bubbling up to the parent tree item
        event.stopPropagation();

        onClick(itemId);
      }}
    >
      {children &&
        children.map((child) => (
          <CustomTreeItem
            key={child.id}
            itemId={child.blockId}
            label={child.title}
            reactions={child.reactions || []}
            children={child.children}
            onClick={onClick}
          />
        ))}
    </TreeItem2>
  );
}
