import {
  DataGridColumnAlignment,
  DataGridColumnFormat,
  DataGridDescendentCountFormat,
  DataGridDetailsPanelFormat,
  ShapeDatastoreType,
} from "@bigpi/cookbook";
import { T } from "@tldraw/validate";
import { RecordProps } from "@tldraw/tlschema";

import { dataGridPreferencesValidatable } from "./DataGridPreferencesValidatable.js";
import { IDataGridShape } from "./IDataGridShape.js";

// *********************************************
// Private constants
// *********************************************/
/**
 * The default width of the shape.
 */
const DEFAULT_WIDTH = 3073;

/**
 * The default height of the shape.
 */
const DEFAULT_HEIGHT = 500;

// *********************************************
// Public constants
// *********************************************/
/**
 * Gets the default props for the shape.
 *
 * @returns The default props.
 */
export const getDataGridShapeDefaultProps = (): IDataGridShape["props"] => {
  return {
    w: DEFAULT_WIDTH,
    h: DEFAULT_HEIGHT,
    config: {
      addToDocument: false,
      autoResize: true,
      checkboxSelection: false,
      columns: [],
      documentItemTemplate: "",
      fontSize: 16,
    },
    datastoreType: ShapeDatastoreType.ParentDatastore,
    preferences: {},
    selectedIds: [],
  };
};

/**
 * The shape props schema definition.
 */
export const dataGridShapeProps: RecordProps<IDataGridShape> = {
  h: T.nonZeroNumber,
  w: T.nonZeroNumber,
  config: T.object({
    addToDocument: T.boolean,
    autoResize: T.boolean,
    checkboxSelection: T.boolean,
    columns: T.arrayOf(
      T.object({
        align: T.setEnum(
          new Set([DataGridColumnAlignment.Left, DataGridColumnAlignment.Center, DataGridColumnAlignment.Right]),
        ).optional(),
        field: T.string,
        flex: T.number.optional(),
        format: T.setEnum(
          new Set([
            DataGridColumnFormat.ArrayOfStrings,
            DataGridColumnFormat.Boolean,
            DataGridColumnFormat.BulletList,
            DataGridColumnFormat.Date,
            DataGridColumnFormat.DateRange,
            DataGridColumnFormat.Decimal,
            DataGridColumnFormat.HtmlString,
            DataGridColumnFormat.Integer,
            DataGridColumnFormat.Sources,
            DataGridColumnFormat.Speakers,
            DataGridColumnFormat.String,
          ]),
        ),
        headerAlign: T.setEnum(
          new Set([DataGridColumnAlignment.Left, DataGridColumnAlignment.Center, DataGridColumnAlignment.Right]),
        ).optional(),
        headerName: T.string,
        isColumnMenuEnabled: T.boolean.optional(),
        isColumnPinEnabled: T.boolean.optional(),
        isEditEnabled: T.boolean.optional(),
        isExportEnabled: T.boolean.optional(),
        isGroupEnabled: T.boolean.optional(),
        isHideEnabled: T.boolean.optional(),
        isReorderEnabled: T.boolean.optional(),
        isResizeEnabled: T.boolean.optional(),
        isSortEnabled: T.boolean.optional(),
        isVisible: T.boolean.optional(),
        maxWidth: T.number.optional(),
        minWidth: T.number.optional(),
        showSortIcons: T.boolean.optional(),
        type: T.string.optional(),
        width: T.number.optional(),
      }),
    ),
    descendentCountFormat: T.setEnum(
      new Set([DataGridDescendentCountFormat.RowCount, DataGridDescendentCountFormat.RowCountPercentage]),
    ).optional(),
    detailsPanelFormat: T.setEnum(new Set([DataGridDetailsPanelFormat.TopicDiscussionSummaryMasterDetails])).optional(),
    documentItemTemplate: T.string,
    fontSize: T.number,
    isExportCsvEnabled: T.boolean.optional(),
    isExportExcelEnabled: T.boolean.optional(),
    isManageColumnsEnabled: T.boolean.optional(),
    isPaginationEnabled: T.boolean.optional(),
    maxHeight: T.string.optional(),
    minHeight: T.string.optional(),
  }),
  datastoreId: T.string.optional(),
  datastoreType: T.setEnum(new Set([ShapeDatastoreType.ParentDatastore, ShapeDatastoreType.ServerDatastore])),
  preferences: dataGridPreferencesValidatable,
  selectedIds: T.arrayOf(T.string),
};
