/**
 * Available text alignment styles.
 *
 * @createZodSchema
 */
export enum TLTextAlignStyle {
  /**
   * @description Align to the start.
   */
  start = "start",

  /**
   * @description Align to the center or middle.
   */
  middle = "middle",

  /**
   * @description Align to the end.
   */
  end = "end",
}
