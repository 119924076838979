import { ItemGridSize, ItemViewType, TAGS_FILTER_UNTAGGED_OPTION_KEY } from "@bigpi/cookbook";
import {
  Box,
  Button,
  ButtonBase,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Edit, GridViewOutlined, SearchOutlined, ViewListOutlined } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { CommandContext } from "CommandContext";
import { Tags } from "Components/Tags/Tags";
import {
  useUpsertWorkspacePageUserPreferencesMutation,
  useWorkspaceBoardTagsQuery,
  useWorkspacePageUserPreferencesQuery,
  useWorkspaceQuery,
} from "GraphQL/Generated/Apollo";
import { WORKSPACE_PAGE_USER_PREFERENCES_KEY } from "GraphQL/UserPreference";
import { useDefaultPagePreferences } from "Hooks/useDefaultPagePreferences";
import { RoutePaths } from "RoutePaths";
import {
  getGridSizeFromUserPreferences,
  getSelectedWorkspaceBoardTagsFromUserPreferences,
  getViewTypeFromUserPreferences,
} from "Utils/UserPreferencesUtils";
import { ManageWorkspaceDialog } from "./ManageWorkspaceDialog";
import { WorkspaceContentView } from "./WorkspaceContentView";

import "./WorkspacePage.css";

const SEARCH_VALUE_DEBOUNCE_TIME = 500;

export interface IWorkspacePageUserPreferences {
  gridSize: ItemGridSize;
  viewType: ItemViewType;
  selectedTags: Record<string, Array<string>>;
}

export function WorkspacePage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  const workspaceId = params.id || "";

  const defaultPagePreferences = useDefaultPagePreferences("workspace");
  const { data: persistedPreferences } = useWorkspacePageUserPreferencesQuery({
    variables: {
      key: WORKSPACE_PAGE_USER_PREFERENCES_KEY,
    },
  });

  const { data: workspaceBoardTagsData } = useWorkspaceBoardTagsQuery({ variables: { workspaceId } });
  const boardTags = workspaceBoardTagsData?.workspaceBoardTags || [];

  const [manageWorkspaceDialogOpen, setManageWorkspaceDialogOpen] = useState(false);
  const [preferences, setPreferences] = useState<IWorkspacePageUserPreferences>({
    gridSize: ItemGridSize.Medium,
    viewType: ItemViewType.Grid,
    selectedTags: {
      [workspaceId]: (workspaceBoardTagsData?.workspaceBoardTags || []).concat(TAGS_FILTER_UNTAGGED_OPTION_KEY),
    },
  });
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedSearchValue] = useDebounce(searchValue, SEARCH_VALUE_DEBOUNCE_TIME);

  const tagsFilterValue = useMemo(() => preferences.selectedTags[workspaceId] || [], [preferences.selectedTags, workspaceId]);

  const { data: workspaceData } = useWorkspaceQuery({ variables: { id: workspaceId } });
  const pageTitle = workspaceData?.workspace?.name || t("Pages.Workspace.Title");
  const workspaceName = workspaceData?.workspace?.name || "";

  useEffect(() => {
    const gridSize = getGridSizeFromUserPreferences(persistedPreferences, defaultPagePreferences, ItemGridSize.Medium);
    const viewType = getViewTypeFromUserPreferences(persistedPreferences, defaultPagePreferences, ItemViewType.Grid);
    const selectedTags = getSelectedWorkspaceBoardTagsFromUserPreferences(
      persistedPreferences,
      defaultPagePreferences,
      workspaceBoardTagsData,
      preferences,
      workspaceId,
    );

    // The preferences have changed, update the state
    setPreferences({
      gridSize,
      viewType,
      selectedTags,
    });
  }, [defaultPagePreferences, persistedPreferences, workspaceId, workspaceBoardTagsData?.workspaceBoardTags]);

  const [upsertUserPreference] = useUpsertWorkspacePageUserPreferencesMutation({
    // Update cache directly since the server will not return the fully cascaded data, just the user portion
    // This ensures any default and org-level preferences are not lost
    update: (cache, data) => {
      cache.modify({
        fields: {
          userPreference(existing = {}) {
            return {
              ...existing,
              data: {
                ...existing.data,
                ...data.data?.upsertUserPreference.data,
              },
            };
          },
        },
      });
    },
  });

  useEffect(() => {
    // Set current application session context
    CommandContext.replaceSessionContext([{ workspaceId }]);
  }, [workspaceId]);

  // Persists the user preferences. NOTE: This upserts with a patch, so we don't need to send all the preferences
  const updatePreferences = useCallback(
    (preferences: Partial<IWorkspacePageUserPreferences>) => {
      setPreferences((current) => {
        return { ...current, ...preferences };
      });
      upsertUserPreference({
        variables: {
          input: {
            key: WORKSPACE_PAGE_USER_PREFERENCES_KEY,
            data: preferences,
          },
        },
      });
    },
    [upsertUserPreference],
  );

  const setWorkspaceBoardsTagsFilter = useCallback(
    (value: Array<string>) => {
      updatePreferences({
        selectedTags: {
          ...preferences.selectedTags,
          [workspaceId]: value,
        },
      });
    },
    [updatePreferences, preferences.selectedTags, workspaceId],
  );

  const handleTagSelection = useCallback(
    (event: SelectChangeEvent<string>) => {
      const {
        target: { value },
      } = event;
      setWorkspaceBoardsTagsFilter(typeof value === "string" ? value.split(",") : value);
    },
    [setWorkspaceBoardsTagsFilter],
  );

  const navigateToWorkspaces = useCallback(() => {
    navigate(RoutePaths.workspaces.path);
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 3,
          mb: 0,
          alignSelf: "center",
          width: "90%",
          maxWidth: preferences.viewType === ItemViewType.List ? "960px" : "1920px",
          minWidth: "400px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ButtonBase
            onClick={navigateToWorkspaces}
            sx={{
              fontSize: "inherit",
              "&:hover": {
                textDecoration: "underline",
                color: "primary.main",
              },
            }}
          >
            {t("Pages.Workspaces.HeadingLabel")}
          </ButtonBase>
          &nbsp;
          {`> ${workspaceName}`}
        </Typography>

        <Box sx={{ display: "flex" }}>
          <Button
            startIcon={<Edit />}
            variant="outlined"
            sx={{ width: "220px" }}
            onClick={() => setManageWorkspaceDialogOpen(true)}
          >
            {t("Pages.Workspace.ManageWorkspace")}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          m: 3,
          alignSelf: "center",
          flexDirection: "column",
          width: "90%",
          maxWidth: preferences.viewType === ItemViewType.List ? "960px" : "1920px",
          minWidth: "400px",
          border: `1px solid #f1f1f1`,
          borderRadius: "16px",
          mb: 10,
        }}
      >
        <Box sx={{ display: "flex", p: 3, pb: 0, justifyContent: "center" }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="find-boards-and-files-input">{t("Pages.Workspace.FindLabel")}</InputLabel>
            <OutlinedInput
              id="find-boards-and-files-input"
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              }
              label={t("Pages.Workspace.FindLabel")}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", p: 3, pb: 0, justifyContent: "space-between", flexWrap: "wrap" }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2, mr: 2 }}>
            {boardTags && boardTags.length > 0 && (
              <Tags
                handleTagSelection={handleTagSelection}
                onDeselectAllTags={() => setWorkspaceBoardsTagsFilter([])}
                onSelectAllTags={() => setWorkspaceBoardsTagsFilter(boardTags.concat(TAGS_FILTER_UNTAGGED_OPTION_KEY))}
                tags={boardTags}
                tagsFilterValue={tagsFilterValue}
              />
            )}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <IconButton
              color={preferences.viewType === ItemViewType.List ? "primary" : "default"}
              size="medium"
              onClick={() => updatePreferences({ viewType: ItemViewType.List })}
            >
              <ViewListOutlined />
            </IconButton>

            <IconButton
              color={preferences.viewType === ItemViewType.Grid ? "primary" : "default"}
              size="medium"
              onClick={() => updatePreferences({ viewType: ItemViewType.Grid })}
            >
              <GridViewOutlined />
            </IconButton>
          </Box>
        </Box>

        <WorkspaceContentView
          gridSize={preferences.gridSize}
          onGridSizeChange={(gridSize) => updatePreferences({ gridSize })}
          filterTags={tagsFilterValue.filter((tag) => tag !== TAGS_FILTER_UNTAGGED_OPTION_KEY)}
          includeUntagged={tagsFilterValue.indexOf(TAGS_FILTER_UNTAGGED_OPTION_KEY) > -1}
          workspaceId={workspaceId}
          viewType={preferences.viewType}
          searchValue={debouncedSearchValue}
        />
      </Box>

      {manageWorkspaceDialogOpen && (
        <ManageWorkspaceDialog
          open={manageWorkspaceDialogOpen}
          onClose={() => setManageWorkspaceDialogOpen(false)}
          workspaceId={workspaceId}
        />
      )}
    </>
  );
}
