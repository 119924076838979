import { CommandActionType, CommandOutputType } from "@bigpi/cookbook";
import { createShapeId, TLUnknownShape, TLShapePartial, TLShape, Vec, Box } from "tldraw";

import { RequestPlugInBase } from "./RequestPlugInBase";
import { createShapesAtEmptyPoint } from "BoardComponents/Utils/CreateShapeUtils";
import { ICommandRequestPlugInInputs } from "Components/CommandManagers/WorkspaceBoard/WorkspaceBoardCommandManager";
import { DataUtils } from "Utils/DataUtils";

const DEFAULT_MARGIN = 50;

export class GenericClientActionRequestPlugIn extends RequestPlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = CommandActionType.GenericClientAction;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: ICommandRequestPlugInInputs): Promise<void> {
    const { app, command, commandRequest } = input;
    const { requestId } = commandRequest;

    if (!app) {
      console.warn(`No app instance for command request ${requestId}`);
      return;
    }

    const callerData = JSON.parse(commandRequest.callerData || "{}");
    // Get the preconfigured options from the command data, if any set
    const data = command.action.data;
    const newShapes: Array<TLShapePartial<TLUnknownShape>> = [];
    let source: TLShape | Vec | Box | null = null;
    let select: true | false = false;
    let direction: "horizontal" | "vertical" = "horizontal";
    let margin: number = DEFAULT_MARGIN;
    let autoFocus: boolean = true;

    // Handle shapes that can be added to board without server processing
    switch (callerData.outputType as CommandOutputType) {
      case CommandOutputType.AnalystQuestionAnalysis:
        newShapes.push({
          id: createShapeId(requestId),
          type: "analystQuestionAnalysis",
          props: {
            ...app.shapeUtils.analystQuestionAnalysis?.getDefaultProps(),
            selectedFacetValues: data?.selectedFacetValues || DataUtils.getImmutableEmptyObject(),
            preferences: data?.preferences || DataUtils.getImmutableEmptyObject(),
            toolbar: data?.toolbar || DataUtils.getImmutableEmptyObject(),
          },
        });
        select = true;
        margin = 100;
        break;

      case CommandOutputType.Feed:
        newShapes.push({
          id: createShapeId(requestId),
          type: "feed",
          props: {
            ...app.shapeUtils.feedShapeUtil?.getDefaultProps(),
          },
        });
        select = true;
        break;

      case CommandOutputType.QuestionAnalysis:
        newShapes.push({
          id: createShapeId(requestId),
          type: "questionAnalysis",
          props: {
            ...app.shapeUtils.questionAnalysis?.getDefaultProps(),
            selectedFacetValues: data?.selectedFacetValues || DataUtils.getImmutableEmptyObject(),
            preferences: data?.preferences || DataUtils.getImmutableEmptyObject(),
            boundsFacetValues: data?.boundsFacetValues || DataUtils.getImmutableEmptyObject(),
            toolbar: data?.toolbar || DataUtils.getImmutableEmptyObject(),
          },
        });
        select = true;
        margin = 100;
        break;

      case CommandOutputType.TopicDiscussionAnalysis:
        newShapes.push({
          id: createShapeId(requestId),
          type: "topicDiscussionAnalysis",
          props: {
            ...app.shapeUtils.topicDiscussionAnalysisUtil?.getDefaultProps(),
            // Set requested default settings for this command
            selectedFacetValues: data?.selectedFacetValues || DataUtils.getImmutableEmptyObject(),
            preferences: data?.preferences || DataUtils.getImmutableEmptyObject(),
            boundsFacetValues: data?.boundsFacetValues || DataUtils.getImmutableEmptyObject(),
            toolbar: data?.toolbar || DataUtils.getImmutableEmptyObject(),
            selection: data?.selection || DataUtils.getImmutableEmptyObject(),
          },
        });
        select = true;
        break;

      case CommandOutputType.TopicDiscussionInNewsArticleAnalysis:
        newShapes.push({
          id: createShapeId(requestId),
          type: "topicDiscussionInNewsArticleAnalysis",
          props: {
            ...app.shapeUtils.topicDiscussionInNewsArticleAnalysisUtil?.getDefaultProps(),
            selectedFacetValues: data?.selectedFacetValues || DataUtils.getImmutableEmptyObject(),
            preferences: data?.preferences || DataUtils.getImmutableEmptyObject(),
            boundsFacetValues: data?.boundsFacetValues || DataUtils.getImmutableEmptyObject(),
            toolbar: data?.toolbar || DataUtils.getImmutableEmptyObject(),
            selection: data?.selection || DataUtils.getImmutableEmptyObject(),
          },
        });
        select = true;
        break;

      case CommandOutputType.TopicDiscussionSummaryAnalysis:
        newShapes.push({
          id: createShapeId(requestId),
          type: "topicDiscussionSummaryAnalysis",
          props: {
            ...app.shapeUtils.topicDiscussionSummaryAnalysis?.getDefaultProps(),
            // Set requested default settings for this command
            selectedFacetValues: data?.selectedFacetValues || DataUtils.getImmutableEmptyObject(),
            boundsFacetValues: data?.boundsFacetValues || DataUtils.getImmutableEmptyObject(),
            toolbar: data?.toolbar || DataUtils.getImmutableEmptyObject(),
            preferences: data?.preferences || DataUtils.getImmutableEmptyObject(),
            selection: data?.selection || DataUtils.getImmutableEmptyObject(),
          },
        });
        select = true;
        break;
    }

    if (newShapes.length > 0) {
      createShapesAtEmptyPoint(app, newShapes, source, select, direction, margin, autoFocus);
    }
  }
}
