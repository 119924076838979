import { CircularProgress, Typography } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  OnWorkspaceBoardTableOfContentsCreateSubscription,
  OnWorkspaceBoardTableOfContentsCreateSubscriptionVariables,
  OnWorkspaceBoardTableOfContentsReactionCreateSubscription,
  OnWorkspaceBoardTableOfContentsReactionCreateSubscriptionVariables,
  useOnWorkspaceBoardTableOfContentsDeleteSubscription,
  useOnWorkspaceBoardTableOfContentsReactionDeleteSubscription,
  useOnWorkspaceBoardTableOfContentsUpdateSubscription,
  useWorkspaceBoardTableOfContentsQuery,
  useWorkspaceBoardTableOfContentsReactionsQuery,
  WorkspaceBoardTableOfContentsQuery,
  WorkspaceBoardTableOfContentsReactionsQuery,
} from "GraphQL/Generated/Apollo";
import { WORKSPACE_BOARD_TABLE_OF_CONTENTS_CREATE_SUBSCRIPTION } from "GraphQL/WorkspaceBoardTableOfContents/Subscription";
import { WORKSPACE_BOARD_TABLE_OF_CONTENTS_REACTION_CREATE_SUBSCRIPTION } from "GraphQL/WorkspaceBoardTableOfContentsReaction/Subscription";
import { CustomTreeItem } from "./CustomTreeItem";

// *********************************************
// Types/Constants
// *********************************************/
interface IWorkspaceBoardTableOfContentsProps {
  scrollDocumentToBlockId: (blockId: string) => void;
  shapeId: string;
  workspaceBoardId: string;
  selectedBlockId?: string;
}

export type WorkspaceBoardTableOfContentsType = NonNullable<
  WorkspaceBoardTableOfContentsQuery["workspaceBoardTableOfContents"]
>[0] & {
  children: Array<WorkspaceBoardTableOfContentsType>;
  reactions: Array<WorkspaceBoardTableOfContentsReactionsType>;
};

export type WorkspaceBoardTableOfContentsReactionsType = NonNullable<
  WorkspaceBoardTableOfContentsReactionsQuery["workspaceBoardTableOfContentsReactions"]
>[0];

// *********************************************
// Components
// *********************************************/
export function WorkspaceBoardTableOfContents(props: IWorkspaceBoardTableOfContentsProps) {
  const { scrollDocumentToBlockId, shapeId, workspaceBoardId, selectedBlockId } = props;

  const { t } = useTranslation();

  const [workspaceBoardTOC, setWorkspaceBoardTOC] = useState<Array<WorkspaceBoardTableOfContentsType>>([]);
  const [expandedTOC, setExpandedTOC] = useState<Array<string>>([]);

  const getSelectedItemId = useCallback(
    (selectedBlockId?: string) => {
      const blockIds = workspaceBoardTOC.map((item) => item.blockId);
      if (selectedBlockId && blockIds.includes(selectedBlockId)) {
        // Select the block ID if it exists in the table of contents
        return selectedBlockId;
      }

      // Select the first item if no selected block ID
      return workspaceBoardTOC.length > 0 ? workspaceBoardTOC[0].blockId : null;
    },
    [workspaceBoardTOC],
  );

  const scrollTreeItemIntoView = useCallback((blockId?: string) => {
    if (blockId) {
      const elementToScroll = document.getElementById(`workspace-board-toc-treeitem-${blockId}`);
      elementToScroll?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }, []);

  const [selectedItem, setSelectedItem] = useState<string | null>(getSelectedItemId(selectedBlockId));

  useEffect(() => {
    // Scroll the selected item into view when the selected item changes
    scrollTreeItemIntoView(selectedBlockId);

    // Update selected item when selected block ID changes
    setSelectedItem(getSelectedItemId(selectedBlockId));
  }, [selectedBlockId]);

  // Update subscription
  useOnWorkspaceBoardTableOfContentsUpdateSubscription({
    variables: {
      shapeId,
      workspaceBoardId,
    },
  });

  // Delete subscription
  useOnWorkspaceBoardTableOfContentsDeleteSubscription({
    variables: {
      shapeId,
      workspaceBoardId,
    },
    onData: (options) => {
      const workspaceBoardTableOfContents = options.data.data?.onWorkspaceBoardTableOfContentsDelete;

      if (workspaceBoardTableOfContents) {
        const cache = options.client.cache;
        const id = cache.identify({
          __typename: "WorkspaceBoardTableOfContents",
          id: workspaceBoardTableOfContents.id,
        });
        if (id) {
          cache.evict({ id });
        }
      }
    },
  });

  useOnWorkspaceBoardTableOfContentsReactionDeleteSubscription({
    variables: {
      shapeId,
      workspaceBoardId,
    },
    onData: (options) => {
      const workspaceBoardTableOfContentsReaction = options.data.data?.onWorkspaceBoardTableOfContentsReactionDelete;

      if (workspaceBoardTableOfContentsReaction) {
        const cache = options.client.cache;
        const id = cache.identify({
          __typename: "WorkspaceBoardTableOfContentsReaction",
          id: workspaceBoardTableOfContentsReaction.id,
        });
        if (id) {
          cache.evict({ id });
        }
      }
    },
  });

  const { data, subscribeToMore, loading } = useWorkspaceBoardTableOfContentsQuery({
    variables: {
      input: {
        shapeId,
        workspaceBoardId,
      },
    },
  });

  const { data: reactions, subscribeToMore: reactionsSubscribeMore } = useWorkspaceBoardTableOfContentsReactionsQuery({
    variables: {
      filters: {
        shapeId,
        workspaceBoardId,
      },
    },
  });

  useEffect(() => {
    const unsubscribeCreated = subscribeToMore<
      OnWorkspaceBoardTableOfContentsCreateSubscription,
      OnWorkspaceBoardTableOfContentsCreateSubscriptionVariables
    >({
      document: WORKSPACE_BOARD_TABLE_OF_CONTENTS_CREATE_SUBSCRIPTION,
      variables: {
        shapeId,
        workspaceBoardId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        // Return previous data if no new data
        if (!subscriptionData.data || !subscriptionData.data.onWorkspaceBoardTableOfContentsCreate) {
          return prev;
        }
        const newItem = subscriptionData.data.onWorkspaceBoardTableOfContentsCreate;

        return Object.assign({}, prev, {
          workspaceBoardTableOfContents: [
            ...(Array.isArray(prev.workspaceBoardTableOfContents) ? prev.workspaceBoardTableOfContents : []),
            {
              ...newItem,
              children: [],
              reactions: [],
            },
          ],
        });
      },
    });

    return () => {
      unsubscribeCreated();
    };
  }, [subscribeToMore, shapeId]);

  useEffect(() => {
    const unsubscribeCreated = reactionsSubscribeMore<
      OnWorkspaceBoardTableOfContentsReactionCreateSubscription,
      OnWorkspaceBoardTableOfContentsReactionCreateSubscriptionVariables
    >({
      document: WORKSPACE_BOARD_TABLE_OF_CONTENTS_REACTION_CREATE_SUBSCRIPTION,
      variables: {
        shapeId,
        workspaceBoardId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        // Return previous data if no new data
        if (!subscriptionData.data || !subscriptionData.data.onWorkspaceBoardTableOfContentsReactionCreate) {
          return prev;
        }
        const newItem = subscriptionData.data.onWorkspaceBoardTableOfContentsReactionCreate;

        return Object.assign({}, prev, {
          workspaceBoardTableOfContentsReactions: [
            ...(Array.isArray(prev.workspaceBoardTableOfContentsReactions) ? prev.workspaceBoardTableOfContentsReactions : []),
            {
              ...newItem,
              children: [],
              reactions: [],
            },
          ],
        });
      },
    });

    return () => {
      unsubscribeCreated();
    };
  }, [reactionsSubscribeMore, workspaceBoardId, shapeId]);

  useEffect(() => {
    if (data?.workspaceBoardTableOfContents) {
      const reactionsMap = groupedReactions(reactions?.workspaceBoardTableOfContentsReactions || []);
      setWorkspaceBoardTOC(
        buildHierarchy(data?.workspaceBoardTableOfContents as Array<WorkspaceBoardTableOfContentsType>, reactionsMap),
      );

      const allParentIds: Array<string> = [];
      data.workspaceBoardTableOfContents.forEach((item) => {
        if (item.parentId) {
          allParentIds.push(item.parentId);
        }
      });

      if (allParentIds) {
        setExpandedTOC(allParentIds);
      }
    }
  }, [data, reactions]);

  const onExpandedItemsChange = useCallback((event: React.SyntheticEvent, itemIds: Array<string>) => {
    setExpandedTOC(itemIds);
  }, []);

  const onSelectedItemChange = useCallback((_event: SyntheticEvent, item: string | null) => {
    setSelectedItem(item);
  }, []);

  const onTocClick = useCallback(
    (itemId: string) => {
      const workspaceBoardTableOfContents = data?.workspaceBoardTableOfContents;
      if (!workspaceBoardTableOfContents) {
        return;
      }

      // Find the details of the clicked item
      const details = workspaceBoardTableOfContents.find((item) => item.blockId === itemId);
      if (!details) {
        return;
      }

      // Set the blockId as selected item
      setSelectedItem(details.blockId);

      // Scroll to the corresponding node in the document
      scrollDocumentToBlockId(details.blockId);
    },
    [data, scrollDocumentToBlockId],
  );

  return (
    <>
      {loading ? (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      ) : workspaceBoardTOC.length === 0 ? (
        <Typography variant="body1" sx={{ p: 1 }}>
          {t(`Components.WorkspaceBoardTableOfContents.NoContent`)}
        </Typography>
      ) : (
        <SimpleTreeView
          expansionTrigger="iconContainer"
          expandedItems={expandedTOC}
          onExpandedItemsChange={onExpandedItemsChange}
          selectedItems={selectedItem}
          onSelectedItemsChange={onSelectedItemChange}
          multiSelect={false}
        >
          {workspaceBoardTOC.map((item, index) => {
            return (
              <CustomTreeItem
                key={item.id}
                itemId={item.blockId}
                label={item.title}
                reactions={item.reactions || []}
                children={item.children || []}
                onClick={onTocClick}
              />
            );
          })}
        </SimpleTreeView>
      )}
    </>
  );
}

/**
 * Build a hierarchy from workspaceBoardTableOfContents data.
 *
 * @param flatData workspaceBoardTableOfContents data.
 *
 * @returns Hierarchy of workspaceBoardTableOfContents data.
 */
function buildHierarchy(
  flatData: Array<WorkspaceBoardTableOfContentsType>,
  reactionsMap?: Record<string, Array<WorkspaceBoardTableOfContentsReactionsType>>,
) {
  if (!flatData) {
    return [];
  }
  // Step 1: Create a lookup map for each item by its ID
  const lookup = new Map<string, WorkspaceBoardTableOfContentsType>();

  // Step 2: Initialize the lookup map with each item
  flatData.forEach((item) => {
    lookup.set(item.id, { ...item, children: [], reactions: reactionsMap ? reactionsMap[item.id] : [] });
  });

  // Step 3: Populate the hierarchy
  const topLevelItems: Array<WorkspaceBoardTableOfContentsType> = [];
  for (const item of lookup.values()) {
    if (item.parentId) {
      const parentItem = lookup.get(item.parentId);
      if (parentItem) {
        parentItem.children.push(item);
        continue;
      }
    }
    // If there is no parent, it is a top level item
    topLevelItems.push(item);
  }

  return topLevelItems;
}

/**
 * Group reactions by workspace board table of contents ID.
 *
 * @param allReactions All workspace board table of contents reactions.
 *
 * @returns Grouped reactions by workspace board table of contents ID.
 */
function groupedReactions(allReactions: Array<WorkspaceBoardTableOfContentsReactionsType>) {
  const reactions = allReactions.reduce(
    (acc, reaction) => {
      if (reaction && reaction.workspaceBoardTableOfContentsId) {
        const key = reaction.workspaceBoardTableOfContentsId;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(reaction);
      }
      return acc;
    },
    {} as Record<string, Array<WorkspaceBoardTableOfContentsReactionsType>>,
  );

  return reactions;
}
