import { createShapePropsMigrationIds, createShapePropsMigrationSequence } from "@tldraw/tlschema";

export const FeedShapeVersions = createShapePropsMigrationIds("feed", {
  AddCanScroll: 1,
});

export const feedShapeMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: FeedShapeVersions.AddCanScroll,
      up(props) {
        props.canScroll = true;
      },
      down(props) {
        delete props.canScroll;
      },
    },
  ],
});
