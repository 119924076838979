import { useReactiveVar } from "@apollo/client";
import { ICommandContext } from "@bigpi/cookbook";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { useValue } from "tldraw";

import { CommandContext } from "CommandContext";
import { AppLeftSidebar } from "Components/AppLeftSidebar/AppLeftSidebar";
import { AppRightSidebar } from "Components/AppRightSidebar/AppRightSidebar";
import { NavigationRail } from "Components/NavigationRail/NavigationRail";
import { useIsNavigationPanelEnabled } from "Navigation/Hooks";
import { NavigationPanel, NavigationPanelType } from "Navigation/NavigationPanel/NavigationPanel";

export function MainLayout() {
  const commandContext = useReactiveVar<ICommandContext>(CommandContext.getCommandContext);
  const isNavigationPanelEnabled = useIsNavigationPanelEnabled();

  const workspaceRelatedIds = useValue(
    "workspaceId",
    () => {
      let workspaceId = "";
      let workspaceBoardId = "";

      commandContext.session.forEach((sessionItem) => {
        if (Object.keys(sessionItem).includes("workspaceId")) {
          workspaceId = sessionItem.workspaceId;
        }

        if (Object.keys(sessionItem).includes("workspaceBoardId")) {
          workspaceBoardId = sessionItem.workspaceBoardId;
        }
      });

      let selectedShapeId: string | undefined = undefined;
      if (
        Object.keys(commandContext.selection).includes("shapeIds") &&
        Array.isArray(commandContext.selection.shapeIds) &&
        commandContext.selection.shapeIds.length > 0
      ) {
        selectedShapeId = commandContext.selection.shapeIds[0];
      }

      let selectedBlockId: string | undefined = undefined;
      if (
        Object.keys(commandContext.selection).includes("blockIds") &&
        Array.isArray(commandContext.selection.blockIds) &&
        commandContext.selection.blockIds.length > 0
      ) {
        selectedBlockId = commandContext.selection.blockIds[0];
      }

      return { workspaceBoardId, workspaceId, selectedBlockId, selectedShapeId };
    },
    [commandContext],
  );

  const showLeftSidebar = isNavigationPanelEnabled && workspaceRelatedIds.workspaceBoardId;
  const showRightSidebar = workspaceRelatedIds.workspaceBoardId;

  return (
    <Box sx={{ display: "flex", position: "fixed", left: 0, right: 0, bottom: 0, top: 0 }}>
      <NavigationRail />
      {showLeftSidebar && (
        <AppLeftSidebar>
          <NavigationPanel
            workspaceId={workspaceRelatedIds.workspaceId}
            workspaceBoardId={workspaceRelatedIds.workspaceBoardId}
            selectedShapeId={workspaceRelatedIds.selectedShapeId}
            selectedBlockId={workspaceRelatedIds.selectedBlockId}
            navigationType={NavigationPanelType.Workspace}
          />
        </AppLeftSidebar>
      )}
      <Box
        component="main"
        sx={{
          marginLeft: showLeftSidebar ? "auto" : "50px",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Outlet />
      </Box>
      {showRightSidebar && (
        <AppRightSidebar workspaceId={workspaceRelatedIds.workspaceId} selectedShapeId={workspaceRelatedIds.selectedShapeId} />
      )}
    </Box>
  );
}
