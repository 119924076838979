import { StyledComponent } from "@emotion/styled";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { Box, SvgIconProps, Tab, TabProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export interface IRightSidebarTabProps extends TabProps {
  /**
   * Whether the tab is fixed and cannot be unpinned
   */
  isFixed: boolean;
  /**
   * Whether the tab is pinned
   */
  isPinned: boolean;
  /**
   * Whether the tab can be pinned
   */
  allowPinning: boolean;
  /**
   * Function to toggle the tab pinning
   */
  toggleTabPinning: () => void;
}

export const RightSidebarTab: StyledComponent<IRightSidebarTabProps> = styled((props: IRightSidebarTabProps) => {
  const { isFixed, isPinned, allowPinning, label, toggleTabPinning, ...rest } = props;

  if (isFixed || !allowPinning) {
    return <Tab disableRipple label={label} {...rest} />;
  }

  const pinIconProps: SvgIconProps = {
    fontSize: "small",
    onClick: toggleTabPinning,
    sx: {
      ml: 1,
      cursor: "pointer",
      "&:hover": {
        opacity: 0.8,
      },
    },
  };
  const pinIcon = isPinned ? (
    // Show filled icon if tab is pinned
    <PushPinIcon {...pinIconProps} />
  ) : (
    // Show outlined icon if tab is not pinned
    <PushPinOutlinedIcon {...pinIconProps} />
  );

  return (
    <Tab
      disableRipple
      label={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              maxWidth: "140px",
              whiteSpace: "nowrap",
              display: "block",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {label}
          </span>
          {pinIcon}
        </Box>
      }
      {...rest}
    />
  );
})(({ theme }) => ({
  "&.MuiTab-root": {
    border: "1px solid #e9e9e9",
    borderBottomWidth: 0,
    "&:not(:first-of-type)": {
      marginLeft: -1,
    },
    background: "#f7f7f7",
    opacity: 1,
  },
  "&.Mui-selected": {
    borderBottomWidth: 0,
    background: "#ffffff",
    "& $wrapper": {
      opacity: 1,
    },
  },
}));
