import { FileSourceType } from "@bigpi/cookbook";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FieldSelection } from "Components/EditFileDetailsDialog/FieldSelection";
import { FieldsDataGrid } from "./FieldsDataGrid";
import { getFieldsConfig } from "./FieldsConfig";
import { useFileDetails } from "./Hooks/useFileDetails";

export interface IEditFileDetailsDialogProps {
  fileId: string;
  fileSourceType: FileSourceType;
  onClose: () => void;
  onSave: (details: Record<string, any>) => void;
  open: boolean;
}

export function EditFileDetailsDialog(props: IEditFileDetailsDialogProps) {
  const { fileId, fileSourceType, onClose, onSave, open } = props;
  const [details, setDetails] = useState<Record<string, any>>({});
  const { name, documentType, itemDate, ...otherFields } = details;
  const { t } = useTranslation();

  const fileDetails = useFileDetails(fileId, fileSourceType);
  const fieldsConfig = getFieldsConfig(t);

  const onChangeDetails = useCallback((fieldKey: string, value: any) => {
    setDetails((details) => {
      return {
        ...details,
        [fieldKey]: value,
      };
    });
  }, []);

  const onSaveDetails = useCallback(() => {
    onSave(details);
    onClose();
  }, [details, onSave, onClose]);

  const onChangeAdditionalFields = useCallback(
    (additionalFields: typeof otherFields) => {
      setDetails((details) => {
        return {
          name,
          documentType,
          itemDate,
          ...additionalFields,
        };
      });
    },
    [name, documentType, itemDate],
  );

  useEffect(() => {
    setDetails(fileDetails || {});
  }, [fileDetails]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle variant="h5">{t("Components.EditFileDetailsDialog.Title")}</DialogTitle>
      <DialogContent>
        <Box>
          {fieldsConfig.map((field) => (
            <Stack key={field.key} spacing={2} direction="row" alignItems={"center"} sx={{ m: 1, ml: 0 }}>
              <InputLabel sx={{ mr: 1, width: 50 }}>{t(`Components.EditFileDetailsDialog.FieldLabels.${field.name}`)}</InputLabel>
              <FieldSelection field={field} value={details[field.key]} onChange={onChangeDetails} />
            </Stack>
          ))}
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            {t("Components.EditFileDetailsDialog.AdditionalMetadataHeader")}
          </Typography>
          <FieldsDataGrid fields={otherFields} onChange={onChangeAdditionalFields} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <Button onClick={onSaveDetails} color="primary">
            {t("Global.Action.Save")}
          </Button>
          <Button onClick={onClose} color="secondary">
            {t("Global.Action.Close")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
