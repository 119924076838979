import { PropsWithChildren, ReactNode, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { useIsDocumentCompanionEnabled } from "BoardComponents/HtmlDocumentShape/useIsDocumentCompanionEnabled";
import { APP_RIGHT_SIDEBAR_DOCUMENT_TAB_CONTENT_ID, APP_RIGHT_SIDEBAR_DOCUMENT_TAB_TITLE_ID } from "./AppRightSidebar";

export interface IAppRightSidebarShapePaneProps extends PropsWithChildren {
  shapeId: string;
  title: string | ReactNode;
}

export function AppRightSidebarShapePane(props: IAppRightSidebarShapePaneProps) {
  const { children, title, shapeId } = props;

  const [appRightSidebarTabContentContainer, setAppRightSidebarTabContentContainer] = useState<HTMLElement | null>(null);
  const [appRightSidebarTabTitleContainer, setAppRightSidebarTabTitleContainer] = useState<HTMLElement | null>(null);

  const isDocumentCompanionEnabled = useIsDocumentCompanionEnabled();

  useEffect(() => {
    // Observe the document for changes
    const contentContainerObserver = new MutationObserver(() => {
      const element = document.getElementById(APP_RIGHT_SIDEBAR_DOCUMENT_TAB_CONTENT_ID(shapeId));
      if (element) {
        // Set the app right sidebar tab container as soon as it's available
        setAppRightSidebarTabContentContainer(element);
      }
    });
    const titleContainerObserver = new MutationObserver(() => {
      const element = document.getElementById(APP_RIGHT_SIDEBAR_DOCUMENT_TAB_TITLE_ID(shapeId));
      if (element) {
        // Set the app right sidebar tab container as soon as it's available
        setAppRightSidebarTabTitleContainer(element);
      }
    });

    contentContainerObserver.observe(document.body, { childList: true, subtree: true });
    titleContainerObserver.observe(document.body, { childList: true, subtree: true });

    // Cleanup observer on component unmount
    return () => {
      contentContainerObserver.disconnect();
      titleContainerObserver.disconnect();
    };
  }, [shapeId]);

  // See: https://github.com/facebook/react/issues/19637
  const stopEventPropagation = useCallback((event: React.UIEvent) => {
    event.stopPropagation();
  }, []);

  if (!isDocumentCompanionEnabled) {
    return null;
  }

  return (
    <>
      {appRightSidebarTabContentContainer &&
        createPortal(
          <div
            onMouseDown={stopEventPropagation}
            onMouseUp={stopEventPropagation}
            onPointerDown={stopEventPropagation}
            onPointerUp={stopEventPropagation}
            onKeyDown={stopEventPropagation}
            onKeyUp={stopEventPropagation}
            onKeyPress={stopEventPropagation}
            onClick={stopEventPropagation}
            onContextMenu={stopEventPropagation}
            onDoubleClick={stopEventPropagation}
            onDrag={stopEventPropagation}
            onDragEnd={stopEventPropagation}
            onDragEnter={stopEventPropagation}
            onDragExit={stopEventPropagation}
            onDragLeave={stopEventPropagation}
            onDragOver={stopEventPropagation}
            onDragStart={stopEventPropagation}
            onDrop={stopEventPropagation}
            onScroll={stopEventPropagation}
            onTouchStart={stopEventPropagation}
            onTouchEnd={stopEventPropagation}
            onTouchMove={stopEventPropagation}
            onTouchCancel={stopEventPropagation}
            onWheel={stopEventPropagation}
          >
            {children}
          </div>,
          appRightSidebarTabContentContainer,
          `app-right-sidebar-tab-content-${shapeId}`,
        )}

      {appRightSidebarTabTitleContainer &&
        createPortal(
          <div
            onMouseDown={stopEventPropagation}
            onMouseUp={stopEventPropagation}
            onPointerDown={stopEventPropagation}
            onPointerUp={stopEventPropagation}
            onKeyDown={stopEventPropagation}
            onKeyUp={stopEventPropagation}
            onKeyPress={stopEventPropagation}
            onClick={stopEventPropagation}
            onContextMenu={stopEventPropagation}
            onDoubleClick={stopEventPropagation}
            onDrag={stopEventPropagation}
            onDragEnd={stopEventPropagation}
            onDragEnter={stopEventPropagation}
            onDragExit={stopEventPropagation}
            onDragLeave={stopEventPropagation}
            onDragOver={stopEventPropagation}
            onDragStart={stopEventPropagation}
            onDrop={stopEventPropagation}
            onScroll={stopEventPropagation}
            onTouchStart={stopEventPropagation}
            onTouchEnd={stopEventPropagation}
            onTouchMove={stopEventPropagation}
            onTouchCancel={stopEventPropagation}
            onWheel={stopEventPropagation}
          >
            {title}
          </div>,
          appRightSidebarTabTitleContainer,
          `app-right-sidebar-tab-title-${shapeId}`,
        )}
    </>
  );
}
