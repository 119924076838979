import { createShapePropsMigrationIds, createShapePropsMigrationSequence } from "@tldraw/tlschema";

export const BarChartShapeVersions = createShapePropsMigrationIds("barChart", {
  UpdateFieldProp: 1,
});

export const barChartShapeMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: BarChartShapeVersions.UpdateFieldProp,
      up(props) {
        const { field, ...rest } = props;
        const formattedField = (field || "").replace("BarChart", "");
        Object.assign(props, {
          ...rest,
          field: formattedField,
        });
      },
      down(props) {
        const { field, ...rest } = props;
        Object.assign(props, {
          ...rest,
          field: `${field || ""}BarChart`,
        });
      },
    },
  ],
});
