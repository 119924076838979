/**
 * Get file to base64
 *
 * @param file The file to convert to base64.
 * @returns The base64 string.
 */
export const getFileToBase64 = async (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

/**
 * Gives the base file name without the metadata extension.
 *
 * @param fileName File name to check if it is a metadata file.
 *
 * @returns Base file name without the metadata extension.
 */
export function getBaseFileName(fileName: string) {
  const fileNameParts = fileName.split(".metadata.");
  return fileNameParts[0];
}

/**
 * Extract the name and extension of a file.
 *
 * @param fileName File name to extract the name and extension from.
 *
 * @returns The name and extension of the file.
 */
export function extractFileNameAndExtension(fileName: string) {
  const fileNameParts = fileName.split(".");
  if (fileNameParts.length === 1) {
    return { name: fileName, extension: "" };
  }
  const extension = fileNameParts.pop();
  const name = fileNameParts.join(".");
  return { name, extension };
}

/**
 * Check the given file name is a metadata file.
 *
 * @param fileName File name to check if it is a metadata file.
 *
 * @returns Boolean indicating if the file is a metadata file.
 */
export function isMetadataFile(fileName: string) {
  // Define the patterns for CSV and JSON files
  const csvPattern = /\.metadata\.csv$/;
  const jsonPattern = /\.metadata\.json$/;

  if (csvPattern.test(fileName)) {
    return true;
  }

  if (jsonPattern.test(fileName)) {
    return true;
  }

  return false;
}
