import { createShapePropsMigrationIds, createShapePropsMigrationSequence } from "@tldraw/tlschema";

import { ITopicDiscussionInNewsArticleAnalysisShape } from "./ITopicDiscussionInNewsArticleAnalysisShape.js";
import { ITopicDiscussionInNewsArticleAnalysisFacets } from "./ITopicDiscussionInNewsArticleAnalysisFacets.js";

export const TopicDiscussionInNewsArticleAnalysisShapeVersions = createShapePropsMigrationIds(
  "topicDiscussionInNewsArticleAnalysis",
  {
    Initial: 0,
    AddBackgroundColorField: 1,
    AddPreferencesField: 2,
    RemoveDataGridPreferencesColumnVisibility: 3,
    AddPreferencesFacetSortModel: 4,
    AddStandardisedFacetsAndBounds: 5,
  },
);

export const topicDiscussionInNewsArticleAnalysisMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: TopicDiscussionInNewsArticleAnalysisShapeVersions.AddBackgroundColorField,
      up(props) {
        props.enableBackground = false;
      },
      down(props) {
        delete props.enableBackground;
      },
    },
    {
      id: TopicDiscussionInNewsArticleAnalysisShapeVersions.AddPreferencesField,
      up(props) {
        props.preferences = {};
      },
      down(props) {
        delete props.preferences;
      },
    },
    {
      id: TopicDiscussionInNewsArticleAnalysisShapeVersions.RemoveDataGridPreferencesColumnVisibility,
      up(props) {
        if (props.preferences?.dataGrid) {
          delete props.preferences.dataGrid.columnVisibility;
        }
      },
      down(props) {
        if (props.preferences?.dataGrid) {
          props.preferences.dataGrid.columnVisibility = {};
        }
      },
    },
    {
      id: TopicDiscussionInNewsArticleAnalysisShapeVersions.AddPreferencesFacetSortModel,
      up(props) {
        if (!props.preferences) {
          props.preferences = {};
        }
        if (!props.preferences.analysis) {
          props.preferences.analysis = {};
        }
        props.preferences.analysis.facetSort = [];
      },
      down(props) {
        if (props.preferences?.analysis) {
          delete props.preferences.analysis.facetSort;
        }
      },
    },
    {
      id: TopicDiscussionInNewsArticleAnalysisShapeVersions.AddStandardisedFacetsAndBounds,
      up(props) {
        const { facets, analysisType, preferences } = props;

        let selectedFacetValues: ITopicDiscussionInNewsArticleAnalysisFacets = {};
        let selection: ITopicDiscussionInNewsArticleAnalysisShape["props"]["selection"] = {};
        let boundsFacetValues: ITopicDiscussionInNewsArticleAnalysisFacets = {};
        let preferencesProps = { ...(preferences || {}) };
        let selectedBoundsFields: Array<string> = [];

        // Transforms facets to latest
        if (facets) {
          if (facets.selectedDate) {
            selectedFacetValues.date = facets.selectedDate;
          }

          if (facets.selectedTopics) {
            selectedFacetValues.topic = facets.selectedTopics;
          }

          if (facets.expandedGroups) {
            selection.expandedGroups = facets.expandedGroups;
          }

          if (facets.selectedTimeScale) {
            selection.selectedTimeScale = facets.selectedTimeScale;
          }

          if (facets.axisSelection) {
            selection.axisSelection = facets.axisSelection;
          }
        }

        if (facets && facets.includeItems !== undefined) {
          preferencesProps.analysis = {
            ...(preferencesProps.analysis || {}),
            showTopics: facets.includeItems,
          };
        }

        if (analysisType) {
          boundsFacetValues.theme = [analysisType];
          selectedBoundsFields = ["theme"];
        }

        props.selectedFacetValues = selectedFacetValues;
        props.boundsFacetValues = boundsFacetValues;
        props.selection = selection;
        props.preferences = preferencesProps;
        props.selectedBoundsFields = selectedBoundsFields;
        props.toolbar = {
          availableFields: ["date", "topic"],
        };

        if (props.analysisType) {
          delete props.analysisType;
        }

        if (props.facets) {
          delete props.facets;
        }
      },
      down(props) {
        const { preferences, boundsFacetValues, selectedFacetValues, selection } = props;

        let facets: Record<string, any> = {};
        let analysisType;

        if (selectedFacetValues) {
          if (selectedFacetValues.date) {
            facets.selectedDate = selectedFacetValues.date;
          }

          if (selectedFacetValues.topic) {
            facets.selectedTopics = selectedFacetValues.topic;
          }
        }

        if (selection) {
          if (selection.expandedGroups) {
            facets.expandedGroups = selection.expandedGroups;
          }

          if (selection.selectedTimeScale) {
            facets.selectedTimeScale = selection.selectedTimeScale;
          }

          if (selection.axisSelection) {
            facets.axisSelection = selection.axisSelection;
          }
        }

        if (preferences?.analysis?.showTopics !== undefined) {
          facets.includeItems = preferences.analysis.showTopics;
          delete preferences.analysis.showTopics;
        }

        if (preferences && preferences.analysis && preferences.analysis.startColor !== undefined) {
          delete preferences.analysis.startColor;
        }

        if (preferences && preferences.analysis && preferences.analysis.showDataToDisplayInToolbar !== undefined) {
          delete preferences.analysis.showDataToDisplayInToolbar;
        }

        if (boundsFacetValues?.theme) {
          analysisType = boundsFacetValues.theme[0];
        }

        props.facets = facets;
        props.analysisType = analysisType;

        if (props.selectedBoundsFields) {
          delete props.selectedBoundsFields;
        }

        if (boundsFacetValues) {
          delete props.boundsFacetValues;
        }

        if (selectedFacetValues) {
          delete props.selectedFacetValues;
        }

        if (selection) {
          delete props.selection;
        }

        if (props.toolbar) {
          delete props.toolbar;
        }
      },
    },
  ],
});
