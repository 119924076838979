import { Atom, atom } from "tldraw";
import { useMemo } from "react";

import { IAnalysisShapeData } from "./IAnalysisShapeData";
import { IDataGridShapeExternalData } from "./IDataGridShapeExternalData";
import { IHtmlDocumentShapeExternalData } from "./IHtmlDocumentShapeExternalData";
import { INativeLikeShapeExternalData } from "./INativeLikeShapeExternalData";

/**
 * Combined type of all possible data structures stored in the datastore.
 */
export type BoardDatastoreExternalDataType =
  | IAnalysisShapeData<any, any, any, any>
  | IDataGridShapeExternalData
  | IHtmlDocumentShapeExternalData
  | INativeLikeShapeExternalData;

/**
 * External data store for shapes to share data, keyed by shapeId.
 *
 * This data store is used to share data and references between shapes outside of the
 * persisted shape props.
 */
export class BoardDatastore {
  // *********************************************
  // Private fields
  // *********************************************/
  /**
   * An Atom of Atoms containing the data state for each shape, keyed by shapeId.
   */
  private readonly _state: Atom<Record<string, Atom<BoardDatastoreExternalDataType>>>;

  // *********************************************
  // Constructors
  // *********************************************/
  public constructor() {
    this._state = atom("board.datastore", {});
  }

  // *********************************************
  // Public static methods
  // *********************************************/
  public static useNewBoardDatastore = () => {
    const datastore = useMemo(() => new BoardDatastore(), []);
    // You can add any effects and other lifecycle logic in here
    return datastore;
  };

  // *********************************************
  // Public properties
  // *********************************************/
  public get state() {
    return this._state;
  }

  // *********************************************
  // Public methods
  // *********************************************/
  public hasShapeData(shapeId: string) {
    return !!this.state.get()[shapeId];
  }

  public removeShapeData(shapeId: string) {
    // Get the current map of state data without any bindings
    const shapeDataMap = { ...this.state.__unsafe__getWithoutCapture() };

    if (shapeDataMap[shapeId]) {
      // Remove the shape data atom from the map
      delete shapeDataMap[shapeId];

      // Update the root shape data map
      this.state.set(shapeDataMap);
    }
  }

  public setShapeData(shapeId: string, stateData: BoardDatastoreExternalDataType) {
    // Get the current map of state data without any bindings
    const shapeDataMap = { ...this.state.__unsafe__getWithoutCapture() };

    // Get the atom for the data
    const shapeDataAtom = this.state.get()[shapeId];

    if (!shapeDataAtom) {
      // Create a new atom for the shape data
      shapeDataMap[shapeId] = atom(`board.datastore.${shapeId}`, stateData);
    } else {
      // Get the data atoms current value
      const shapeData = shapeDataAtom.__unsafe__getWithoutCapture();

      // Update the data atom with the new data
      shapeDataAtom.set({ ...shapeData, ...stateData });
    }

    // Update the root shape data map
    this.state.set(shapeDataMap);
  }
}
