import { createShapePropsMigrationIds, createShapePropsMigrationSequence } from "@tldraw/tlschema";

export const HistogramChartShapeVersions = createShapePropsMigrationIds("histogramChart", {
  UpdateFieldProp: 1,
});

export const histogramChartShapeMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: HistogramChartShapeVersions.UpdateFieldProp,
      up: (props) => {
        const { field, ...rest } = props;
        const formattedField = (field || "").replace("BarChart", "");
        Object.assign(props, { ...rest, field: formattedField });
      },
      down: (props) => {
        const { field, ...rest } = props;
        Object.assign(props, { ...rest, field: `${field || ""}BarChart` });
      },
    },
  ],
});
