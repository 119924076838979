import { getAssetUrlsByImport } from "@tldraw/assets/imports";
import { AssetUrls } from "@tldraw/assets/types";
import {
  Editor,
  TLAnyShapeUtilConstructor,
  Tldraw,
  TLStateNodeConstructor,
  TLStoreWithStatus,
  TLUiAssetUrlOverrides,
  TLUiMainMenuProps,
  TLUiOverrides,
} from "tldraw";
import { ComponentType, memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AddToBoardToolIcon from "Assets/Icons/add-filled.svg";
import DocumentToolIcon from "Assets/Icons/tool-document.svg";
import InteractToolIcon from "Assets/Icons/tool-interact.svg";
import { BoardProviderContext } from "BoardComponents/BoardProviderContext/BoardProviderContext";
import { BoardProvider } from "BoardComponents/BoardProviderContext/BoardProvider";
import { BigPiShapeIndicators } from "BoardComponents/Canvas/BigPiShapeIndicators";
import { Companion } from "BoardComponents/Companion/Companion";
import { CompanionManager } from "BoardComponents/Companion/CompanionManager";
import { CompanionManagerContext } from "BoardComponents/Companion/CompanionManagerContext";
import { TldrawSelectionForeground } from "BoardComponents/Tools";
import { AlertBar } from "Components/AlertBar/AlertBar";
import { FatalErrorDialog } from "Components/FatalErrorBoundary/FatalErrorDialog";
import { ContextMenu } from "./ContextMenu";
import { CustomStylePanel } from "./CustomStylePanel";
import { HelpMenu } from "./HelpMenu";
import { KeyboardShortcutsDialog } from "./KeyboardShortcutsDialog";
import { MainMenu } from "./MainMenu";
import { CustomPageMenu } from "./CustomPageMenu";
import { QuickActions } from "./QuickActions";
import { Toolbar } from "./Toolbar";
import { IYjsStoreWithStatus } from "./useYjsStore";
import { Config } from "Config";
import { MinimapThumbnail } from "./Minimap/MinimapThumbnail";

// *********************************************
// Interfaces
// *********************************************/
export interface CollaborativeBoardEditorProps {
  activateThumbnailFrame: () => void;
  customShapeUtils?: TLAnyShapeUtilConstructor[];
  customTools?: TLStateNodeConstructor[];
  onMinimapRender: (dataUrl: string) => void;
  onMount?: (editor: Editor) => void;
  openAclDialog: () => void;
  openRenameDialog: () => void;
  publishToDocuments: () => void;
  syncedStore: TLStoreWithStatus;
  workspaceId: string;
}

// *********************************************
// Component
// *********************************************/
export const CollaborativeBoardEditor = memo((props: CollaborativeBoardEditorProps) => {
  const {
    activateThumbnailFrame,
    customShapeUtils,
    customTools,
    onMinimapRender,
    onMount,
    openAclDialog,
    openRenameDialog,
    publishToDocuments,
    syncedStore,
    workspaceId,
  } = props;
  const boardProvider = BoardProvider.useNewBoardProvider();
  const companionManager = CompanionManager.useNewCompanionManager();
  const [editor, setEditor] = useState<Editor>();
  const { t } = useTranslation();

  const onMountLocal = useCallback((editor: Editor) => {
    setEditor(editor);
    onMount?.(editor);

    return () => {
      setEditor(undefined);
    };
  }, []);

  const uiOverrides: TLUiOverrides = useMemo(() => {
    return {
      actions(editor, schema, helpers) {
        // Disable various keyboard shortcuts
        schema["toggle-dark-mode"].kbd = undefined;

        // Add action for board search
        schema["find-in-board"] = {
          id: "find-in-board",
          label: "action.findInBoard",
          kbd: "$f",
          readonlyOk: true,
          onSelect: () => {
            // Trigger the board search dialog using KeyboardEvent
            // apps/patron/src/BoardComponents/BoardSearchManager/BoardSearchManager.tsx -> useEffect -> onKeyDown
            window.document.dispatchEvent(new KeyboardEvent("keydown", { key: "f", code: "KeyF", keyCode: 70, metaKey: true }));
          },
        };

        return schema;
      },
      tools(editor, tools) {
        tools.htmlDocument = {
          id: "htmlDocument",
          icon: "tool-document",
          label: "tool.document",
          kbd: "n",
          readonlyOk: false,
          onSelect: () => {
            editor.setCurrentTool("htmlDocument");
          },
        };

        tools.interact = {
          id: "interact",
          icon: "tool-interact",
          label: "tool.interact",
          kbd: "i",
          readonlyOk: false,
          onSelect: () => {
            editor.setCurrentTool("interact");
          },
        };

        return tools;
      },
      translations: {
        en: {
          "action.captureThumbnail": t("Global.Menu.CaptureThumbnail"),
          "action.findInBoard": t("Global.Menu.FindInBoard"),
          "action.publishToDocuments": t("Global.Action.PublishToDocuments"),
          "action.rename": t("Global.Menu.Rename"),
          "action.share": t("Global.Menu.Share"),
          "tool.addToBoard": t("Tools.AddToBoard"),
          "tool.text": t("Tools.TextLabel"),
          "tool.document": t("Tools.Document"),
          "tool.interact": t("Tools.Interact"),
        },
      },
    };
  }, [t]);

  const assetUrls: AssetUrls & TLUiAssetUrlOverrides = useMemo(() => {
    const result: AssetUrls & TLUiAssetUrlOverrides = getAssetUrlsByImport();

    // Add our custom icons
    result.icons["tool-document"] = DocumentToolIcon;
    result.icons["tool-interact"] = InteractToolIcon;
    result.icons["tool-add-to-board"] = AddToBoardToolIcon;

    return result;
  }, []);

  const CustomMainMenu: ComponentType<TLUiMainMenuProps> = useMemo(() => {
    return (props) => (
      <MainMenu
        workspaceId={workspaceId}
        activateThumbnailFrame={activateThumbnailFrame}
        openAclDialog={openAclDialog}
        openRenameDialog={openRenameDialog}
        publishToDocuments={publishToDocuments}
      />
    );
  }, [workspaceId, activateThumbnailFrame, openAclDialog, openRenameDialog, publishToDocuments]);

  const CustomErrorFallback = useCallback((props: { editor?: Editor; error: any }) => {
    const { editor, error } = props;
    const errorToShow = "error" in error ? error.error : error;
    return <FatalErrorDialog error={errorToShow} />;
  }, []);

  const isConnected =
    syncedStore.status === "loading" || (syncedStore.status === "synced-remote" && syncedStore.connectionStatus === "online");

  boardProvider.yDocument = (syncedStore as IYjsStoreWithStatus).yDocument || null;
  boardProvider.provider = (syncedStore as IYjsStoreWithStatus).provider || null;

  // Do NOT remove this or otherwise the editor/store may not call listeners correctly and thus not update the server
  if (!syncedStore.store) {
    return null;
  }

  return (
    <BoardProviderContext.Provider value={boardProvider}>
      <CompanionManagerContext.Provider value={companionManager}>
        <div className="tldraw__editor">
          <Tldraw
            licenseKey={Config.tldrawLicense}
            onMount={onMountLocal}
            components={{
              // disable app-level error boundaries:
              ErrorFallback: CustomErrorFallback,
              ShapeIndicators: BigPiShapeIndicators,
              KeyboardShortcutsDialog,
              SelectionForeground: TldrawSelectionForeground,
              Toolbar,
              HelpMenu,
              ContextMenu,
              MainMenu: CustomMainMenu,
              StylePanel: CustomStylePanel,
              QuickActions,
              // Disable the actions menu - This is the menu that contains "align", "distribute", etc.
              ActionsMenu: null,
              PageMenu: CustomPageMenu,
            }}
            shapeUtils={customShapeUtils}
            tools={customTools}
            store={syncedStore}
            overrides={uiOverrides}
            assetUrls={assetUrls}
            autoFocus
          >
            <MinimapThumbnail onRender={onMinimapRender} />
          </Tldraw>
          <Companion />
        </div>

        {!isConnected && (
          <AlertBar isOnline={false} isModal={true} message={t("Components.WorkspaceBoard.Disconnected")} minWidth={160} />
        )}
      </CompanionManagerContext.Provider>
    </BoardProviderContext.Provider>
  );
});
