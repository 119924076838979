// Generated by ts-to-zod
import { z } from "zod";

import { geoShapeGeoStyleZodSchema } from "./GeoShapeGeoStyle.zod.js";
import { tLHorizontalAlignStyleSchema } from "./TLHorizontalAlignStyle.zod.js";
import { tLVerticalAlignStyleSchema } from "./TLVerticalAlignStyle.zod.js";
import { tLColorStyleSchema } from "./TLColorStyle.zod.js";
import { tLFillStyleSchema } from "./TLFillStyle.zod.js";
import { tLDashStyleSchema } from "./TLDashStyle.zod.js";
import { tLSizeStyleSchema } from "./TLSizeStyle.zod.js";
import { tLFontStyleSchema } from "./TLFontStyle.zod.js";

export const geoShapePropsZodSchema = z.object({
  geo: geoShapeGeoStyleZodSchema.describe("The actual geometric shape style of the shape."),
  labelColor: tLColorStyleSchema.describe("The color of the optional text for the shape."),
  color: tLColorStyleSchema.describe("The color for the shape."),
  fill: tLFillStyleSchema.describe("The fill style for the shape."),
  dash: tLDashStyleSchema.describe("The dash style for the shape."),
  scale: z.number().describe("The scale of the shape on the canvas. A number between 0 and 1 where 1 is 100% scale."),
  size: tLSizeStyleSchema.describe("The size of the shape."),
  font: tLFontStyleSchema.describe("The font style for the shape."),
  align: tLHorizontalAlignStyleSchema.describe("The horizontal alignment of the shape."),
  verticalAlign: tLVerticalAlignStyleSchema.describe("The vertical alignment of the shape."),
  w: z.number().describe("The width of the shape."),
  h: z.number().describe("The height of the shape."),
  text: z.string().describe("Optional text to display in or around the shape."),
});
