import { TLShape, react } from "tldraw";
import { useEffect, useRef } from "react";

import { useCollaborativeBoardEditor } from "TldrawBoard/useCollaborativeBoardEditor";

/**
 * Keeps track of the changes in the rendering shapes and invokes the callback with the culled and restored shapes
 * when there is a change in the rendering shapes.
 * https://discord.com/channels/859816885297741824/859816885801713728/1218230942607478835
 *
 * This method was pulled from an older example at:
 * https://github.com/tldraw/tldraw/blob/b601e701b2b1d446af6b6f02f68200485c7230f4/apps/examples/src/examples/rendering-shape-changes/useRenderingShapesChange.ts
 */
export function useRenderingShapesChange(cb: (info: { culled: TLShape[]; restored: TLShape[] }) => void) {
  const editor = useCollaborativeBoardEditor();
  const rPrevShapes = useRef({
    renderingShapes: editor?.getRenderingShapes(),
    culledShapes: editor?.getCulledShapes(),
  });

  useEffect(() => {
    return react("when rendering shapes change", () => {
      const after = {
        culledShapes: editor?.getCulledShapes(),
        renderingShapes: editor?.getRenderingShapes(),
      };
      const before = rPrevShapes.current;

      const culled: TLShape[] = [];
      const restored: TLShape[] = [];

      const beforeToVisit = new Set(before.renderingShapes);

      if (after.renderingShapes && after.culledShapes && before.renderingShapes && before.culledShapes) {
        for (const afterInfo of after.renderingShapes) {
          const beforeInfo = before.renderingShapes.find((s) => s.id === afterInfo.id);
          if (!beforeInfo) {
            continue;
          } else {
            const isAfterCulled = after.culledShapes.has(afterInfo.id);
            const isBeforeCulled = before.culledShapes.has(beforeInfo.id);
            if (isAfterCulled && !isBeforeCulled) {
              culled.push(afterInfo.shape);
            } else if (!isAfterCulled && isBeforeCulled) {
              restored.push(afterInfo.shape);
            }
            beforeToVisit.delete(beforeInfo);
          }
        }
      }

      rPrevShapes.current = after;

      cb({
        culled,
        restored,
      });
    });
  }, [cb, editor]);
}
