import { BoardSearchShapeFields, TEXT_ELEMENT_QUERY_SELECTOR_SHAPE_ID_PLACEHOLDER } from "@bigpi/cookbook";
import { ILockedTextShape } from "@bigpi/tl-schema";
import { TLArrowShape, TLGeoShape, TLShape, TLTextShape, atom } from "tldraw";
import { useCallback, useEffect } from "react";

import { useBoardDatastore } from "BoardComponents/BoardDatastore";
import { ISearchableShape } from "BoardComponents/BoardDatastore/ISearchableShape";
import { useShapeLifecycleEventEmitter } from "BoardComponents/ShapeLifecycleManager/useShapeLifecycleEventEmitter";
import { useRenderingShapesChange } from "BoardComponents/useRenderingShapesChange";
import { useCollaborativeBoardEditor } from "TldrawBoard/useCollaborativeBoardEditor";
import { getBoardSearchMatchesFromTextContent } from "Utils/BoardSearchUtils";

const NATIVE_SHAPE_HIGHLIGHT_YELLOW_CLASS_NAME = "tl-text-highlight-yellow";
const NATIVE_SHAPE_HIGHLIGHT_ORANGE_CLASS_NAME = "tl-text-highlight-orange";
const NATIVE_LIKE_SHAPES = ["text", "lockedText", "arrow", "geo"] as const;

export const useBoardSearchForSearchableNativeShapes = () => {
  const editor = useCollaborativeBoardEditor();
  const datastore = useBoardDatastore();
  const shapeLifecycleEventEmitter = useShapeLifecycleEventEmitter();

  const getShapeTextElement = useCallback((shape: TLShape) => {
    const textElementQuerySelectorForSearchMatchAnimation = BoardSearchShapeFields.find(
      (s) => s.type === shape.type,
    )?.textElementQuerySelectorForSearchMatchAnimation;
    if (textElementQuerySelectorForSearchMatchAnimation) {
      const escapedShapeId = shape.id.replace(/:/g, "\\:");
      const shapeTextElement = document.querySelector(
        textElementQuerySelectorForSearchMatchAnimation.replace(TEXT_ELEMENT_QUERY_SELECTOR_SHAPE_ID_PLACEHOLDER, escapedShapeId),
      );
      return shapeTextElement;
    }

    return null;
  }, []);

  // When the shape is restored and if the shape is one of the native-like shapes i.e. text,
  // lockedText, arrow and geo then add the ISearchableShape methods for the shape in the
  // BoardDatastore and invoke the afterShapeReady event using ShapeLifecycleEventEmitter
  const addSearchableShapeMethodsForNativeLikeShapes = useCallback(
    (info: { culled: Array<TLShape>; restored: Array<TLShape> }) => {
      const restoredNativeLikeShapes = info.restored.filter((shape) => NATIVE_LIKE_SHAPES.includes(shape.type as any)) as Array<
        TLTextShape | TLArrowShape | TLGeoShape | ILockedTextShape
      >;

      // If the shape data is not present in the datastore, set the full data in the datastore
      restoredNativeLikeShapes.forEach((shape) => {
        const shapeData = datastore.state.get()[shape.id]?.get() as ISearchableShape | undefined;
        if (shapeData) {
          return;
        }

        datastore.setShapeData(shape.id, {
          highlightSearchResults: atom(`board.datastore.${shape.id}.highlightSearchResults`, () => 0),
          highlightActiveSearchResultByIndex: atom(`board.datastore.${shape.id}.highlightActiveSearchResultByIndex`, () => false),
          clearSearchHighlights: atom(`board.datastore.${shape.id}.clearSearchHighlights`, () => {}),
          type: shape.type as (typeof NATIVE_LIKE_SHAPES)[number],
        });
      });

      // Add the ISearchableShape methods for the restored native-like shapes
      restoredNativeLikeShapes.forEach((shape) => {
        const shapeData = datastore.state.get()[shape.id]?.get() as ISearchableShape | undefined;

        shapeData?.highlightSearchResults?.set((searchText: string) => {
          // If there is no text content in the shape, return 0 i.e. no search matches
          const textContent = shape.props.text;
          if (!textContent) {
            return 0;
          }

          // Highlight the search matches in yellow color in the shape text element
          const matches = getBoardSearchMatchesFromTextContent(textContent, searchText);
          if (matches.length > 0) {
            const shapeTextElement = getShapeTextElement(shape);
            if (shapeTextElement) {
              shapeTextElement.classList.add(NATIVE_SHAPE_HIGHLIGHT_YELLOW_CLASS_NAME);
            }
          }

          return matches.length;
        });

        shapeData?.highlightActiveSearchResultByIndex?.set((searchTerm: string, _index: number) => {
          // If there is no text content in the shape, return false i.e. no search matches
          const textContent = shape.props.text;
          if (!textContent) {
            return false;
          }

          // Highlight the search matches in orange color in the shape text element
          const matches = getBoardSearchMatchesFromTextContent(textContent, searchTerm);
          if (matches.length > 0) {
            const shapeTextElement = getShapeTextElement(shape);
            if (shapeTextElement) {
              // Remove existing highlight class names
              shapeTextElement.classList.remove(NATIVE_SHAPE_HIGHLIGHT_YELLOW_CLASS_NAME);
              shapeTextElement.classList.remove(NATIVE_SHAPE_HIGHLIGHT_ORANGE_CLASS_NAME);

              // Add new highlight class name based on the index
              const isIndexOutOfBounds = _index < 0 || _index >= matches.length;
              const newClassName = isIndexOutOfBounds
                ? NATIVE_SHAPE_HIGHLIGHT_YELLOW_CLASS_NAME
                : NATIVE_SHAPE_HIGHLIGHT_ORANGE_CLASS_NAME;
              shapeTextElement.classList.add(newClassName);
            }
          }

          return true;
        });

        shapeData?.clearSearchHighlights?.set(() => {
          // Remove the highlight class names from the shape text element
          const shapeTextElement = getShapeTextElement(shape);
          if (shapeTextElement) {
            shapeTextElement.classList.remove(NATIVE_SHAPE_HIGHLIGHT_YELLOW_CLASS_NAME);
            shapeTextElement.classList.remove(NATIVE_SHAPE_HIGHLIGHT_ORANGE_CLASS_NAME);
          }

          return;
        });

        // Emit "shapeReady" event when the editor is ready and externally callable functions are available
        shapeLifecycleEventEmitter.raiseBeforeShapeReady({ shapeId: shape.id });
        shapeLifecycleEventEmitter.raiseShapeReady({ shapeId: shape.id });
        shapeLifecycleEventEmitter.raiseAfterShapeReady({ shapeId: shape.id });
      });
    },
    [datastore, shapeLifecycleEventEmitter],
  );

  // Listen for rendering shape changes
  useRenderingShapesChange(addSearchableShapeMethodsForNativeLikeShapes);

  // Add the ISearchableShape methods for the native-like shapes when the editor is ready.
  // This is to handle cases where the rendering shapes are already present when the
  // component is mounted.
  useEffect(() => {
    const renderingShapes = editor?.getRenderingShapes() || [];

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        addSearchableShapeMethodsForNativeLikeShapes({
          culled: [],
          restored: renderingShapes.map((info) => info.shape),
        });
      });
    });
  }, [editor, addSearchableShapeMethodsForNativeLikeShapes]);
};
