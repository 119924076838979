import { expandUsersIfEveryone } from "@bigpi/cookbook";
import { useValue } from "tldraw";

import {
  useUsersQuery,
  SortOrder,
  useWorkspaceAccessControlListByObjectQuery,
  ChatMessageChannelType,
} from "GraphQL/Generated/Apollo";

/**
 * Gets an array of users who have access to the chat channel.
 *
 * @param channelId Channel ID of the chat channel.
 * @param channelType The chat channel type.
 *
 * @returns A list of users who have access to the chat channel.
 */
export function useChatUsers(channelId: string, channelType: ChatMessageChannelType) {
  const { data: workspaceUsers } = useWorkspaceAccessControlListByObjectQuery({
    variables: {
      workspaceId: channelId,
    },
    fetchPolicy: "no-cache",
  });

  const { data: userData } = useUsersQuery({
    variables: {
      orderBy: {
        name: SortOrder.Asc,
      },
    },
  });

  const chatUsers = useValue(
    "chatUsers",
    () => {
      if (channelType === ChatMessageChannelType.Workspace) {
        const workspaceUserData = workspaceUsers?.workspaceAccessControlListByObject
          .map((acl) => acl.user)
          .filter((user): user is Exclude<typeof user, null | undefined> => user !== undefined && user !== null);
        if (workspaceUserData && userData?.users) {
          return expandUsersIfEveryone<(typeof workspaceUserData)[0]>(workspaceUserData, userData?.users);
        }
        return workspaceUserData || [];
      }
      return [];
    },
    [workspaceUsers, channelType, userData],
  );

  return chatUsers;
}
