import { CONFIG_KEY_ORGANIZATION_PREFERENCES } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import { useValue } from "tldraw";

import { useGetConfigDataQuery } from "GraphQL/Generated/Apollo";

/**
 * Hook to represent the app level isChatEnabled config.
 */
export function useIsChatEnabled() {
  const { data: appConfigDataResult } = useGetConfigDataQuery({
    variables: {
      key: CONFIG_KEY_ORGANIZATION_PREFERENCES,
      organizationId: useAuthUser()?.tenantId,
    },
  });
  const isChatEnabled = useValue(
    "isChatEnabled",
    () => {
      const configData = JSON.parse(appConfigDataResult?.Config?.data || "{}");
      if (configData.isChatEnabled === false) {
        // Disable chat if explicitly set to false
        return false;
      }

      // Enable chat by default
      return true;
    },
    [appConfigDataResult],
  );
  return isChatEnabled;
}
