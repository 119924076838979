import { RecordProps } from "@tldraw/tlschema";
import { T } from "@tldraw/validate";

import { dataGridPreferencesValidatable } from "../DataGrid/index.js";
import { IAnalystQuestionAnalysisShape } from "./IAnalystQuestionAnalysisShape.js";
import { analysisPreferencesFacetSortValidatable } from "../../Common.js";
import { IAnalystQuestionAnalysisFieldsEnum } from "./IAnalystQuestionAnalysisFacets.js";

// *********************************************
// Private constants
// *********************************************/
/**
 * The default width of the shape.
 */
const DEFAULT_WIDTH = 1200;

/**
 * The default height of the shape.
 */
const DEFAULT_HEIGHT = 2000;

// *********************************************
// Public constants
// *********************************************/
/**
 * Gets the default props for the shape.
 *
 * @returns The default props.
 */
export const getAnalystQuestionAnalysisShapeDefaultProps = (): IAnalystQuestionAnalysisShape["props"] => {
  return {
    selectedFacetValues: {},
    w: DEFAULT_WIDTH,
    h: DEFAULT_HEIGHT,
    enableBackground: true,
    preferences: {},
  };
};

/**
 * The shape props schema definition.
 */
export const analystQuestionAnalysisShapeProps: RecordProps<IAnalystQuestionAnalysisShape> = {
  selectedFacetValues: T.object({
    eventDate: T.object({
      from: T.string.optional(),
      to: T.string.optional(),
    }).optional(),
    eventDateShortcut: T.string.optional(),
    firm: T.arrayOf(T.string).optional(),
    firmType: T.arrayOf(T.string).optional(),
    segment: T.arrayOf(T.string).optional(),
    topics: T.arrayOf(T.string).optional(),
  }),
  w: T.any,
  h: T.any,
  enableBackground: T.boolean.optional(),
  preferences: T.object({
    analysis: T.object({
      facetSort: analysisPreferencesFacetSortValidatable,
      showDataToDisplayInToolbar: T.boolean.optional(),
    }).optional(),
    dataGrid: dataGridPreferencesValidatable,
  }),
  toolbar: T.object({
    availableFields: T.arrayOf(
      T.setEnum(
        new Set([
          IAnalystQuestionAnalysisFieldsEnum.EventDate,
          IAnalystQuestionAnalysisFieldsEnum.EventDateShortcut,
          IAnalystQuestionAnalysisFieldsEnum.Firm,
          IAnalystQuestionAnalysisFieldsEnum.FirmType,
          IAnalystQuestionAnalysisFieldsEnum.Segment,
          IAnalystQuestionAnalysisFieldsEnum.Topics,
        ]),
      ),
    ).optional(),
  }).optional(),
  selection: T.object({
    selectedTimeScale: T.string.optional(),
  }).optional(),
};
