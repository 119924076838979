import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, CircularProgress, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { APP_LEFT_SIDEBAR_ICON_WIDTH } from "Components/AppLeftSidebar/AppLeftSidebar";
import {
  useOnWorkspaceBoardNavigationDetailsCreateSubscription,
  useOnWorkspaceBoardNavigationDetailsDeleteSubscription,
  useOnWorkspaceBoardNavigationDetailsUpdateSubscription,
  useWorkspaceNavigationDetailsQuery,
  WorkspaceNavigationDetails as WorkspaceNavigationDetailsGql,
} from "GraphQL/Generated/Apollo";
import { LeftSidebarTitle } from "Hooks/useAppLeftSidebarComponents";
import { SHAPE_BLOCK_ID_SEARCH_PARAM_NAME, SHAPE_ID_SEARCH_PARAM_NAME } from "Pages/WorkspaceBoard/Constants";
import { linkToWorkspaceBoard } from "RoutePaths";
import { WorkspaceBoardShapeNavigationDetails } from "./WorkspaceBoardShapeNavigationDetails";
import { WorkspaceNavigationDetails } from "./WorkspaceNavigationDetails";

/**
 * Navigation panel type
 */
export enum NavigationPanelType {
  /**
   * Workspace
   */
  Workspace = "Workspace",
}

export type NavigationShapeType = "htmlDocument" | "filePreview";

const SHAPE_SELECTION_CHANGE_DEBOUNCE_TIME = 500;

export interface INavigationPanel {
  navigationType: NavigationPanelType;
  workspaceBoardId: string;
  workspaceId: string;
  selectedShapeId?: string;
  selectedBlockId?: string;
}

export function NavigationPanel(props: INavigationPanel) {
  const { navigationType, workspaceBoardId, workspaceId, selectedShapeId, selectedBlockId } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [tocShapeId, setTocShapeId] = useState<string | null>(null);

  const onShapeSelectionChange = useCallback(() => {
    if (tocShapeId && selectedShapeId && selectedShapeId !== tocShapeId) {
      setTocShapeId(null);
    }
  }, [tocShapeId, selectedShapeId]);

  const debouncedOnShapeSelectionChange = useDebouncedCallback(onShapeSelectionChange, SHAPE_SELECTION_CHANGE_DEBOUNCE_TIME);

  useEffect(() => {
    if (selectedShapeId) {
      debouncedOnShapeSelectionChange();
    }
  }, [selectedShapeId]);

  const { data, loading } = useWorkspaceNavigationDetailsQuery({
    variables: { workspaceId },
    skip: navigationType !== NavigationPanelType.Workspace,
  });

  // Create subscription
  useOnWorkspaceBoardNavigationDetailsCreateSubscription({
    variables: { workspaceId },
    skip: navigationType !== NavigationPanelType.Workspace,
    onData: (options) => {
      const workspaceBoardNavigationDetails = options.data.data?.onWorkspaceBoardNavigationDetailsCreate;
      if (workspaceBoardNavigationDetails) {
        const cache = options.client.cache;
        const id = cache.identify({
          __typename: "WorkspaceNavigationDetails",
          id: workspaceId,
        });
        if (id) {
          cache.modify<WorkspaceNavigationDetailsGql>({
            id,
            fields: {
              boards(existingBoardRefs = [], { toReference, readField }) {
                return [...existingBoardRefs, toReference(workspaceBoardNavigationDetails, true)];
              },
            },
          });
        }
      }
    },
  });

  // Delete subscription
  useOnWorkspaceBoardNavigationDetailsDeleteSubscription({
    variables: { workspaceId },
    skip: navigationType !== NavigationPanelType.Workspace,
    onData: (options) => {
      const workspaceBoardNavigationDetails = options.data.data?.onWorkspaceBoardNavigationDetailsDelete;
      if (workspaceBoardNavigationDetails) {
        const cache = options.client.cache;
        const id = cache.identify({
          __typename: "WorkspaceNavigationDetails",
          id: workspaceId,
        });
        if (id) {
          cache.modify<WorkspaceNavigationDetailsGql>({
            id,
            fields: {
              boards(existingBoardRefs = [], { readField }) {
                return existingBoardRefs.filter((boardRef) => workspaceBoardNavigationDetails.id !== readField("id", boardRef));
              },
            },
          });
        }
      }
    },
  });

  // Update subscription
  useOnWorkspaceBoardNavigationDetailsUpdateSubscription({
    variables: { workspaceId },
    skip: navigationType !== NavigationPanelType.Workspace,
    onData: (options) => {
      const workspaceBoardNavigationDetails = options.data.data?.onWorkspaceBoardNavigationDetailsUpdate;
      if (workspaceBoardNavigationDetails) {
        const cache = options.client.cache;
        const id = cache.identify({
          __typename: "WorkspaceNavigationDetails",
          id: workspaceId,
        });
        if (id) {
          cache.modify<WorkspaceNavigationDetailsGql>({
            id,
            fields: {
              boards(existingBoardRefs = [], { readField, toReference }) {
                const existingBoardRef = existingBoardRefs.find(
                  (boardRef) => workspaceBoardNavigationDetails.id === readField("id", boardRef),
                );
                if (existingBoardRef) {
                  return existingBoardRefs.map((boardRef) => {
                    if (workspaceBoardNavigationDetails.id === readField("id", boardRef)) {
                      return {
                        ...boardRef,
                        ...workspaceBoardNavigationDetails,
                      };
                    }
                    return boardRef;
                  });
                } else {
                  return [...existingBoardRefs, toReference(workspaceBoardNavigationDetails, true)];
                }
              },
            },
          });
        }
      }
    },
  });

  const onBoardNavigation = (boardId: string) => {
    // Do nothing if the board is already open
    if (boardId === workspaceBoardId) {
      return;
    }

    // Navigate to the board
    navigate(linkToWorkspaceBoard(workspaceId, boardId), { replace: true });
  };

  const onShapeNavigation = (boardId: string, shapeId: string) => {
    // Navigate to the board and then to the shape
    const workspaceBoardLink = linkToWorkspaceBoard(workspaceId, boardId);
    navigate(`${workspaceBoardLink}#${SHAPE_ID_SEARCH_PARAM_NAME}=${encodeURIComponent(shapeId)}`, {
      replace: true,
    });
  };

  const onShapeBlockNavigation = (boardId: string, shapeId: string, blockId: string) => {
    const workspaceBoardLink = linkToWorkspaceBoard(workspaceId, boardId);
    navigate(
      `${workspaceBoardLink}#${SHAPE_ID_SEARCH_PARAM_NAME}=${encodeURIComponent(
        shapeId,
      )}&${SHAPE_BLOCK_ID_SEARCH_PARAM_NAME}=${encodeURIComponent(blockId)}`,
      { replace: true },
    );
  };

  const openShapeTocNavigation = useCallback((boardId: string, shapeId: string) => {
    onShapeNavigation(boardId, shapeId);
    setTocShapeId(shapeId);
  }, []);

  const goBackToWorkspaceNavigation = useCallback(() => {
    setTocShapeId(null);
  }, []);

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column", width: "100%", minHeight: 0 }} className="navigation-panel">
      {data?.workspaceNavigationDetails && (
        <LeftSidebarTitle>
          <Typography
            noWrap={true}
            component="div"
            variant="subtitle2"
            sx={{ maxWidth: `calc(100% - ${APP_LEFT_SIDEBAR_ICON_WIDTH * 2}px)`, display: "flex", alignItems: "center" }}
          >
            <Stack direction="row" justifyContent="space-between" width="100%" alignItems={"center"}>
              {tocShapeId && (
                <Stack flexDirection="row" justifyContent="space-around" alignItems="center">
                  <Tooltip title={t(`Global.Action.Back`)}>
                    <IconButton size="small" onClick={goBackToWorkspaceNavigation} disableRipple>
                      <ArrowBackIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
              <Typography variant="body1" sx={{ ml: 1 }} fontWeight={500}>
                {t(`Components.NavigationPanel.Heading`, { name: data.workspaceNavigationDetails.name })}
              </Typography>
            </Stack>
          </Typography>
        </LeftSidebarTitle>
      )}

      <Box sx={{ display: "flex", flexDirection: "column", height: "100%", position: "relative" }}>
        <Box sx={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, overflowY: "auto" }}>
          {tocShapeId ? (
            <WorkspaceBoardShapeNavigationDetails
              workspaceBoardId={workspaceBoardId}
              shapeId={tocShapeId}
              onShapeBlockNavigation={onShapeBlockNavigation}
              selectedBlockId={selectedBlockId}
            />
          ) : loading ? (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          ) : (
            <WorkspaceNavigationDetails
              workspaceNavigationDetails={data?.workspaceNavigationDetails}
              onBoardNavigation={onBoardNavigation}
              onShapeNavigation={onShapeNavigation}
              openShapeTocNavigation={openShapeTocNavigation}
              workspaceBoardId={workspaceBoardId}
              selectedShapeId={selectedShapeId}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
