import {
  CreatedAtDescSortOption,
  FILE_UPLOAD_MAX_FILE_SIZE,
  ISortOption,
  ItemGridSize,
  ItemViewType,
  ONE_MB,
} from "@bigpi/cookbook";
import { hasRolePermission, Permissions } from "@bigpi/permission";
import { useAuthUser } from "@frontegg/react";
import { Box, Button, Typography } from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import { useValue } from "tldraw";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFileBundleViewQuery } from "GraphQL/Generated/Apollo";
import { LibraryGrid } from "./LibraryGrid";
import { LibraryList } from "./LibraryList";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
interface ILibraryViewProps {
  gridSize: ItemGridSize;
  onGridSizeChange: (gridSize: ItemGridSize) => void;
  onSelectionChange: (type: string, id: string) => void;
  pageSize: number;
  searchValue?: string;
  selectedFileIds: Array<string>;
  selectedSortOption?: ISortOption;
  viewType: ItemViewType;
}

const DEFAULT_OFFSET = 0;

// *********************************************
// Component
// *********************************************/
export function LibraryView(props: ILibraryViewProps) {
  const { t } = useTranslation();
  const user = useAuthUser();
  const {
    gridSize,
    onSelectionChange,
    onGridSizeChange,
    pageSize,
    searchValue,
    selectedSortOption = CreatedAtDescSortOption,
    selectedFileIds,
    viewType,
  } = props;

  // State
  const [orderBy, setOrderBy] = useState<Record<string, string>>({ [selectedSortOption.field]: selectedSortOption.sortOrder });

  const {
    data: fileBundleViews,
    loading,
    fetchMore: fetchMoreFiles,
    error,
  } = useFileBundleViewQuery({
    variables: {
      limit: pageSize,
      offset: DEFAULT_OFFSET,
      orderBy,
      filters: {
        searchTerm: searchValue,
      },
    },
  });

  // Hooks
  const rows = useValue(
    "fileBundleViewRows",
    () => {
      return fileBundleViews?.fileBundleView || [];
    },
    [fileBundleViews],
  );

  const count = useValue(
    "fileBundleViewCount",
    () => {
      return fileBundleViews?.fileBundleViewAggregate.count || 0;
    },
    [fileBundleViews],
  );

  useEffect(() => {
    setOrderBy({ [selectedSortOption.field]: selectedSortOption.sortOrder });
  }, [selectedSortOption]);

  if (!searchValue && !loading && rows.length === 0 && hasRolePermission(user.permissions, Permissions.PatronLibraryWrite)) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          minHeight: "300px",
        }}
      >
        <UploadFile sx={{ fontSize: "xx-large", mb: 2, color: "#0b3d62" }} />
        {t("Components.LibraryUploadDialog.DragAndDrop")}
        <Typography variant="body1" color="GrayText">
          {t("Components.LibraryUploadDialog.FileTypeAndSize", {
            maxInMBs: FILE_UPLOAD_MAX_FILE_SIZE / ONE_MB,
          })}
        </Typography>
      </Box>
    );
  } else if (rows.length === 0 && !loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          minHeight: "300px",
        }}
      >
        <Typography variant="body1" color="GrayText">
          {t("Pages.Library.List.NoItemsFound")}
        </Typography>
      </Box>
    );
  }

  if (!fileBundleViews) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Typography variant="caption">
        {t("Global.Aggregate.Some", { count, selectedCount: rows.length, totalCount: count })}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {viewType === ItemViewType.List ? (
          <LibraryList
            rows={fileBundleViews.fileBundleView}
            loading={loading}
            error={error}
            selectedFileIds={selectedFileIds}
            onSelectionChange={onSelectionChange}
          />
        ) : (
          <LibraryGrid
            gridSize={gridSize}
            rows={fileBundleViews.fileBundleView}
            loading={loading}
            error={error}
            selectedFileIds={selectedFileIds}
            onSelectionChange={onSelectionChange}
            onGridSizeChange={onGridSizeChange}
          />
        )}
      </Box>

      {count > rows.length && (
        <Button
          onClick={() => {
            fetchMoreFiles({
              variables: {
                offset: rows.length,
                orderBy,
                filters: {
                  searchTerm: searchValue,
                },
              },
            });
          }}
        >
          {t("Global.Action.ShowMore")}
        </Button>
      )}
    </Box>
  );
}
